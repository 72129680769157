import { createContext, useContext, useEffect, useRef, useState } from 'react';

const userContext = createContext();

export const useUser = () => {
  const context = useContext(userContext);

  if (!context) {
    console.error(
      'userUser is undefined: probabaly forgot to wrap the component in <UserContext>',
    );
    return { user: null, userData: null };
  }
  return context;
};

export function UserContext({ children }) {
  const [user, setUser] = useState(undefined);
  const [userData, setUserData] = useState(undefined);
  const [scheduledVisits, setScheduledVisits] = useState(undefined);
  const unsuscribeRef = useRef();

  useEffect(() => {
    (async () => {
      const { getUser } = await import('@/lib/auth');

      const [promise, unsuscribe] = getUser(({ user, userData }) => {
        console.log('user', user);
        setUser(user);
        setUserData(userData);
      });

      unsuscribeRef.current = unsuscribe;
    })();

    return () => {
      console.log('unsubscribing', unsuscribeRef.current);
      unsuscribeRef.current && unsuscribeRef.current();
    };
  }, []);

  return (
    <userContext.Provider
      value={{
        user,
        userData,
        setUser,
        setUserData,
        scheduledVisits,
        setScheduledVisits,
      }}
    >
      {children}
    </userContext.Provider>
  );
}
