import React from 'react';

import styles from '@/styles/modules/price-info.module.css';

import { IN_LOCALE_CODE } from '@/components/filters/price-range-filter';
import Skeleton from '@/components/skeleton';
import { getPriceLabel } from '@/data/filters';
import propertyDetailData from '@/data/preporty-detail';

const PriceInfo = ({ price, availability, type }) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.priceBox}>
          <p className={styles.amount}>
            ₹{' '}
            {type === 'rent' ?
              price.toLocaleString(IN_LOCALE_CODE)
            : getPriceLabel(price)}
            {type === 'rent' && <span className={styles.period}> / Month</span>}
          </p>

          <p className={styles.note}>(Negotiable)</p>
        </div>
        <div className={styles.availabilityBox}>
          <p className={styles.availabeLabel}>Available</p>
          <p className={styles.availability}>{availability}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.text}>
          <ImportantIcon />
          See other charges
        </p>
      </div>
    </div>
  );
};

const ImportantIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#7A7A7A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="M8 14.666A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.333ZM8 5.333V8M8 10.667h.008" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const MorePriceInfo = ({
  type,
  price,
  security,
  maintenanceCost,
  maintainceCostPeriod,
}) => {
  // Convert weekly maintenance to monthly if needed
  let monthlyMaintenanceCost = 0;

  if (maintenanceCost) {
    maintainceCostPeriod === 'weekly' ?
      maintenanceCost * 4 // Assuming 4 weeks per month
    : maintenanceCost;
  }

  // Calculate first month charges
  const firstMonthCharges =
    parseInt(price) + parseInt(security) + monthlyMaintenanceCost;

  // Calculate every month after charges
  const everyMonthAfter = parseInt(price) + monthlyMaintenanceCost;

  return (
    <div className={styles.moreInfoContainer}>
      <div className={styles.row}>
        <span className={styles.label}>
          {type === 'rent' ? 'Monthly Rent' : 'Price'}
        </span>
        <span className={styles.amount}>
          ₹ {price.toLocaleString(IN_LOCALE_CODE)}
        </span>
      </div>
      {maintenanceCost && maintainceCostPeriod && (
        <div className={styles.row}>
          <span className={styles.label}>
            {maintainceCostPeriod === 'monthly' ? 'Monthly' : 'Weekly'}{' '}
            Maintenance Cost
          </span>
          <span className={styles.amount}>
            ₹ {maintenanceCost.toLocaleString(IN_LOCALE_CODE)}
          </span>
        </div>
      )}
      <div className={styles.row}>
        <span className={styles.label}>
          Security Deposit
          <br />
          (one time)
        </span>
        <span className={styles.amount}>
          ₹ {security.toLocaleString(IN_LOCALE_CODE)}
        </span>
      </div>

      <div className={styles.divider}></div>
      <div className={`${styles.row} ${styles.highlight}`}>
        <span className={styles.label}>First Month Charges</span>
        <span className={styles.amount}>
          ₹ {firstMonthCharges.toLocaleString(IN_LOCALE_CODE)}
        </span>
      </div>
      <div className={`${styles.row} ${styles.highlight}`}>
        <span className={styles.label}>
          Every Month after the
          <br />
          1st month
        </span>
        <span className={styles.amount}>
          ₹ {everyMonthAfter.toLocaleString(IN_LOCALE_CODE)}
        </span>
      </div>
    </div>
  );
};

export const PriceInfoSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div
          style={{
            width: '100%',
          }}
          className={styles.priceBox}
        >
          <Skeleton.Title />
          <Skeleton.Paragraph lineCount={1} />
        </div>
        <div
          style={{
            width: '100%',
          }}
          className={styles.priceBox}
        >
          <Skeleton.Title />

          <Skeleton.Paragraph lineCount={1} />
        </div>
      </div>
    </div>
  );
};

export default PriceInfo;
