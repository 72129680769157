import { initializeApp } from 'firebase/app';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCEDOqx29idNL_SzEgvoyhXGf-UPafau8Y',
  authDomain: 'dylan-estate-development.firebaseapp.com',
  projectId: 'dylan-estate-development',
  storageBucket: 'dylan-estate-development.appspot.com',
  messagingSenderId: '993992004915',
  appId: '1:993992004915:web:af8434d5f335a998a29a46',
  measurementId: 'G-8DPYL2K55S',
};

const app = initializeApp(firebaseConfig);

initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

export default app;
