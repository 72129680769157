import { createContext, useContext, useState } from 'react';

import styles from '@/styles/modules/toast.module.css';

import * as Toast from '@radix-ui/react-toast';
import classNames from 'classnames';
import { BiError } from 'react-icons/bi';

const tostContext = createContext();

export const useTost = () => useContext(tostContext);

export function ToastProvider({ children }) {
  const [toast, setToast] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  return (
    <tostContext.Provider value={{ toast, setToast }}>
      {children}
      <Toast.Provider>
        <Toast.Root
          className={styles.toastRoot}
          open={toast.open}
          onOpenChange={(value) => setToast({ ...toast, open: value })}
        >
          <div>
            <Toast.Title
              className={classNames(styles.toastTitle, styles[toast.type])}
            >
              {toast.type === 'error' && <BiError />}
              {toast.type === 'error' && 'Error'}
            </Toast.Title>
            <Toast.Description asChild>
              <p>{toast.message}</p>
            </Toast.Description>
          </div>
          {/* <Toast.Action
            className={styles.toastAction}
            asChild
            altText="Goto schedule to undo"
          >
            <button className="Button small green">Undo</button>
          </Toast.Action> */}

          <Toast.Close aria-label="Close">
            <span aria-hidden>×</span>
          </Toast.Close>
        </Toast.Root>
        <Toast.Viewport className={styles.toastViewport} />
      </Toast.Provider>
    </tostContext.Provider>
  );
}
