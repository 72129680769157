import styles from '@/styles/modules/home/about-us.module.css';

import AboutUsImage from '@/assets/images/home/about-us.png';
import Container from '@/components/container';
import { Heading } from '@/components/home/featured-properties';
import { Service } from '@/components/home/service';

const content = `We’re not your typical real estate agency. We’re your neighbors, confidants, and partners in finding the perfect home.

With over 25 years of  legacy and dedicated service, Dylan Estate has emerged as an industry expert in helping individuals and families find their perfect homes in miraroad.

Our mission is to provide unparalleled solutions for all your real estate needs. We offer flexible, prompt, and personalized services for property buying, selling, renting, and leasing—both in commercial and residential segments.`;

function AboutUs() {
  return (
    <section className={styles.aboutUs}>
      <Container className={styles.container}>
        <div className={styles.imageContainer}>
          {/* <Service4Image loading="lazy" /> */}
          <img src={AboutUsImage} alt="About Us" loading="lazy" />
        </div>
        <Service.Container>
          <Service.Content className={styles.content}>
            <Heading>About Us</Heading>
            <pre>{content}</pre>
            <a className={styles.seeMore}>See More</a>
          </Service.Content>
        </Service.Container>
      </Container>
    </section>
  );
}

export default AboutUs;
