import { Symbols } from '@/data/symbols';

const propertyPricingData = {
  rent: {
    [Symbols.Label]: 'Rent',
    [Symbols.Name]: 'rent',
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'This field is required',
    },
  },

  security: {
    [Symbols.Label]: 'Security',
    [Symbols.Name]: 'security',
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'This field is required',
    },
  },

  maintance: {
    included: {
      [Symbols.Label]: (propertyForRent) =>
        propertyForRent ? 'Included in rent' : 'Included in price',
      [Symbols.Name]: 'included',
    },
    extraMaintance: {
      [Symbols.Label]: 'Extra Maintance',
      [Symbols.Name]: 'extraMaintance',
    },
    [Symbols.Label]: 'Maintance',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
    [Symbols.Name]: 'maintance',
  },

  mantainceCost: {
    price: {
      [Symbols.Validation]: {
        valueAsNumber: true,
      },
      [Symbols.Label]: 'Maintance Cost',
      [Symbols.Name]: 'mantainceCost.price',
      [Symbols.Validation]: {
        required: 'Maintance price is required',
      },
    },
    period: {
      monthly: 'Monthly',
      weekly: 'Weekly',
      [Symbols.Label]: 'Maintance Time Period',
      [Symbols.Name]: 'mantainceCost.period',
      [Symbols.Validation]: {
        required: 'Maitance Period is required',
      },
    },
    [Symbols.Label]: 'Maintance Cost',
  },

  pricingDescription: {
    [Symbols.Label]: 'Pricing Description',
    [Symbols.Name]: 'pricingDescription',
  },
};

export const PROPERTY_PRICING_NAME = 'property_pricing';

export default propertyPricingData;
