import EnquiryBackgroundSrcsetAvif from '@/assets/images/home/enquiry-background.png?w=300;500;700;900;1200&format=avif&as=srcset';
import EnquiryBackgroundSercsetWebp from '@/assets/images/home/enquiry-background.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as EnquiryBackgroundHeight,
  src as EnquiryBackgroundPlaceholder,
  width as EnquiryBackgroundWidth,
} from '@/assets/images/home/enquiry-background.png?w=300&as=metadata';
import Image from '@/components/Image';

export function EnquiryBackgroundImage({ ...rest }) {
  return (
    <Image
      avif={EnquiryBackgroundSrcsetAvif}
      webp={EnquiryBackgroundSercsetWebp}
      placeholder={EnquiryBackgroundPlaceholder}
      width={EnquiryBackgroundWidth}
      height={EnquiryBackgroundHeight}
      {...rest}
    />
  );
}
