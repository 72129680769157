import { useEffect, useState } from 'react';

import styles from '@/styles/modules/nav.module.css';

import * as Accordion from '@radix-ui/react-accordion';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import { Link, useLocation, useNavigation } from 'react-router-dom';

import loginIcon from '@/assets/icons/account-icon.svg';
import languageIcon from '@/assets/icons/language-icon.svg';
import {
  AccordionContent,
  AccordionTrigger,
} from '@/components/filters/property-type-filter';
import { Icon, icons, navIcons } from '@/components/icons';
import Logo, { NavBarTypes } from '@/components/logo';
import { NotificationPanel } from '@/components/notification';
import { RequireSignInButton } from '@/components/preview/preproty-card';
import { PATHS } from '@/constants/paths';
import { useUser } from '@/contexts/auth-context';
import { useProfile } from '@/contexts/profile-context';
import { propertyForFilter } from '@/data/filters';
import { getName } from '@/data/utils';
import { getParamsString } from '@/utils/url';

const types = {
  link: 'link',
  dropdown: 'dropdown',
  tel: 'tel',
  email: 'email',
  whatsapp: 'whatsapp',
  jsx: 'jsx',
  seperator: 'seperator',
  verticalSeparator: 'verticalSeparator',
  subHeading: 'subHeading',
};

const hrefs = {
  propertiesForRent:
    PATHS.SEARCH +
    getParamsString({
      [getName(propertyForFilter)]: 'rent',
    }),
  propertiesForSale:
    PATHS.SEARCH +
    getParamsString({
      [getName(propertyForFilter)]: 'sale',
    }),
};

const propertiesLinks = {
  name: 'PROPERTIES',
  type: types.dropdown,
  children: [
    { name: 'All Properties', href: PATHS.PROPERTIES },
    {
      name: 'Properties for Rent',
      href: hrefs.propertiesForRent,
    },
    {
      name: 'Properties for Sale',
      href: hrefs.propertiesForSale,
    },
    {
      name: 'Shortlisted Properties',
      href: PATHS.ACTIVITY.SHORTLISTED,
    },
    {
      name: 'Saved Searches',
      href: PATHS.ACTIVITY.SEARCHES,
    },
    {
      name: 'List your property (free)',
      href: PATHS.PROPERTY_LISTING,
    },
  ],
};

const homeLink = {
  name: 'HOME',
  type: types.link,
  href: PATHS.HOME,
};

const wishlistLink = {
  name: 'WISHLIST',
  type: types.link,
  href: PATHS.ACTIVITY.SHORTLISTED,
};

const daashBoardlink = {
  name: 'MY DASHBOARD',
  type: types.link,
  href: PATHS.DASHBOARD,
};

const contactUsLink = {
  name: 'CONTACT US ',
  type: types.link,
  href: PATHS.CONTACT_US,
};

const listYourPropertyLink = {
  name: 'LIST YOUR PROPERTY',
  type: types.link,
  href: PATHS.PROPERTY_LISTING,
};

const Links = [
  propertiesLinks,
  wishlistLink,
  daashBoardlink,
  contactUsLink,
  listYourPropertyLink,
];

const NavBarVariants = {
  Ghost: 'ghost',
};

const scheduleVisitLink = {
  type: types.link,
  name: 'Scheduled Visits',
  href: PATHS.ACTIVITY.VISITS,
};

const inquiriesLink = {
  type: types.link,
  name: 'Inqueries Sent',
  href: PATHS.ACTIVITY.INQUIRIES,
};

const shortlistedPropertiesLink = {
  type: types.link,
  name: 'Shortlisted Properties',
  href: PATHS.ACTIVITY.SHORTLISTED,
};

const savedSearchesLink = {
  type: types.link,
  name: 'Saved Searches',
  href: PATHS.ACTIVITY.SEARCHES,
};

const notInterestedPropertiesLink = {
  type: types.link,
  name: 'Not Interested Properties',
  href: PATHS.ACTIVITY.NOT_INTERESTED,
};

const listedPropertiesLink = {
  type: types.link,
  name: 'Listed Properties',
  href: PATHS.LISTINGS,
};

const logInLink = {
  type: types.jsx,
  jsx: <RequireSignInButton>Login / Sign-up</RequireSignInButton>,
};

const UserNavLink = [
  {
    type: types.seperator,
  },
  {
    type: types.subHeading,
    value: 'My Activity',
  },
  scheduleVisitLink,
  inquiriesLink,
  shortlistedPropertiesLink,
  savedSearchesLink,
  notInterestedPropertiesLink,
];

const mobileLinks = [
  homeLink,
  savedSearchesLink,
  shortlistedPropertiesLink,
  propertiesLinks,
  listYourPropertyLink,
  {
    type: types.seperator,
  },
  {
    name: 'MY ACTIVITY',
    type: types.dropdown,
    children: [scheduleVisitLink, inquiriesLink, listedPropertiesLink],
  },
  {
    type: types.seperator,
  },
  contactUsLink,
];

/**
 *  NavBar component
 * @param {{
 * type : "other",
 * variant: "ghost",
 * position: "relative" | "fixed"
 * }} props
 * @returns {JSX.Element}
 */
const NavBar = ({ variant, position, type, style = {} }) => {
  const { state } = useNavigation();

  const [open, setOpen] = useState(false);

  const { user, userData } = useUser();

  const { profile } = useProfile();

  const Phone_number = profile?.phoneNumber?.replace(' ', '');
  const Whats_app_number = profile?.whatsAppNumber?.replace(' ', '');
  const Email = profile?.email;

  const OtherLinks = [
    {
      name: 'MY DASHBOARD',
      type: types.link,
      href: PATHS.DASHBOARD,
    },
    {
      name: 'CONTACT US',
      type: types.dropdown,
      children: [
        {
          name: 'Quick Inquiry',
          href: PATHS.CONTACT_US,
          icon: navIcons.chat,
        },
        {
          type: types.tel,
          value: Phone_number,
          name: Phone_number,
          icon: navIcons.phone,
        },
        {
          type: types.email,
          name: Email,
          value: Email,
          icon: navIcons.email,
        },
        {
          type: types.whatsapp,
          name: 'Whatsapp',
          value: Whats_app_number,
          icon: navIcons.whatsapp,
        },
      ],
    },
    {
      name: 'MORE',
      type: types.dropdown,
      children: [
        {
          type: types.verticalSeparator,
          children: [
            [
              {
                name: 'Wishlist',
                href: PATHS.ACTIVITY.SHORTLISTED,
              },
              {
                name: 'List your property',
                href: PATHS.PROPERTY_LISTING,
              },
              {
                name: 'Saved Searches',
                href: PATHS.ACTIVITY.SEARCHES,
              },
            ],
            propertiesLinks.children,
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (state === 'loading' && open) {
      setOpen(false);
    }
  }, [state, open]);

  return (
    <nav
      style={{
        position: !position ? 'fixed' : position,
        ...style,
      }}
      className={classNames(styles.nav, styles[variant])}
    >
      <div className={styles.navBar}>
        <div className={styles.logo}>
          <a href={PATHS.HOME} className={styles.logo}>
            <Logo
              type={
                variant === NavBarVariants.Ghost ?
                  NavBarTypes.ForDarkBackground
                : NavBarTypes.ForLightBackground
              }
            />

            {/* <img src={logo} alt="logo" /> */}
          </a>
        </div>

        <NotificationButton />

        <div className={styles.handBurgger}>
          <Hamburger
            color={variant === NavBarVariants.Ghost && !open ? '#fff' : '#000'}
            size={18}
            toggled={open}
            toggle={setOpen}
          />
        </div>
        <div
          onClick={() => setOpen(false)}
          className={classNames(styles.navOverlay, { [styles.open]: open })}
        ></div>
        <div className={classNames(styles.wrapper, { [styles.open]: open })}>
          <Mobile>
            <div className={styles.navLinks}>
              {mobileLinks.map((link) => (
                <NavLink
                  key={link.name}
                  type={link.type}
                  name={link.name}
                  href={link.href}
                  icon={link.icon}
                  links={link.children}
                />
              ))}
              {(!user || !userData) && (
                <>
                  <NavLink type={types.seperator} />

                  <NavLink
                    type={types.link}
                    name="Login / Sign-up"
                    href={PATHS.LOGIN}
                    icon={navIcons.login}
                  />
                </>
              )}
            </div>
          </Mobile>
          <Desktop>
            <div className={styles.navLinks}>
              {(type === 'other' ? OtherLinks : Links).map((link) => (
                <NavLink
                  key={link.name}
                  type={link.type}
                  name={link.name}
                  href={link.href}
                  icon={link.icon}
                  links={link.children}
                />
              ))}
            </div>
          </Desktop>

          <Desktop>
            <div className={styles.icons}>
              <NavLink
                type={types.dropdown}
                trigger={<NotificationButton />}
                links={[
                  {
                    type: types.jsx,
                    jsx: <NotificationPanel />,
                  },
                ]}
              />
              <button className={styles.languageIcon}>
                <img src={languageIcon} alt="language icon" />
              </button>
              <NavLink
                type={types.dropdown}
                trigger={
                  <button className={styles.userIcon}>
                    <img src={loginIcon} alt="user icon" />
                  </button>
                }
                links={[
                  !user ? logInLink : (
                    {
                      type: types.link,
                      name: 'My Dashboard',
                      href: PATHS.DASHBOARD,
                    }
                  ),
                  userData &&
                    userData?.roles?.admin && {
                      type: types.link,
                      name: 'Admin Dashboard',
                      href: PATHS.ADMIN.PROFILE,
                    },
                  ...UserNavLink,
                ].filter(Boolean)}
              />
            </div>
          </Desktop>
        </div>
      </div>
    </nav>
  );
};

function NotificationButton() {
  return (
    <button className={styles.notificationButton}>
      <Icon src={icons.notification} />
    </button>
  );
}

function NavDropDown({ trigger, children }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Mobile>
        <Accordion.Root
          className={styles.accordionRoot}
          type="single"
          collapsible={true}
          defaultValue={['1']}
        >
          <Accordion.Item value="one" className={styles.accordionItem}>
            <AccordionTrigger className={styles.accordionTrigger}>
              {trigger}
            </AccordionTrigger>
            <AccordionContent className={styles.accordionContent}>
              <div className={styles.content}>{children}</div>
            </AccordionContent>
          </Accordion.Item>
        </Accordion.Root>
      </Mobile>
      <Desktop>
        <DropdownMenu.Root modal={false} open={open} onOpenChange={setOpen}>
          <DropdownMenu.Trigger asChild>
            <a>{trigger}</a>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className={styles.dropdownMenuContent}
              align={'start'}
              sideOffset={5}
              collisionPadding={20}
            >
              {children}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </Desktop>
    </>
  );
}

function NavLink({ type, name, href, icon, links, trigger }) {
  const location = useLocation();

  if (type === types.link) {
    return (
      <Link className={location.pathname === href && styles.active} to={href}>
        {icon && <Icon src={icon} />} {name}
      </Link>
    );
  }

  function render(child) {
    return (
      child.type === types.verticalSeparator ?
        child.children.map((child, index, arr) => (
          <>
            <div className={styles.dropdownMenuItem} key={index}>
              {renderLinks(child)}
            </div>
            {index < arr.length - 1 && <div className={styles.seperator}></div>}
          </>
        ))
      : child.type === types.seperator ?
        <div className={styles.seperator}></div>
      : child.type === types.subHeading ?
        <h2 className={styles.subHeading}>{child.value}</h2>
      : child.type === types.jsx ? child.jsx
      : child.type === types.tel ?
        <a href={`tel:${child.value}`}>
          <LinkContent child={child} />
        </a>
      : child.type === types.email ?
        <a href={`mailto:${child.value}`}>
          <LinkContent child={child} />
        </a>
      : child.type === types.whatsapp ?
        <a href={`https://wa.me/${child.value}`}>
          <LinkContent child={child} />
        </a>
      : <Link to={child.href}>
          <LinkContent child={child} />
        </Link>
    );
  }

  function renderLinks(links) {
    return (
      links &&
      links.map((child) => (
        <div
          className={classNames(styles.dropdownMenuItem, styles[child.type])}
          key={child.name}
        >
          {render(child)}
        </div>
      ))
    );
  }

  return type === types.dropdown ?
      <NavDropDown trigger={name || trigger}>{renderLinks(links)}</NavDropDown>
    : render({
        type,
        name,
        href,
        icon,
        links,
      });
}

function LinkContent({ child }) {
  return (
    <>
      {child.icon && (
        <div className={styles.iconContainer}>
          <Icon src={child.icon} />
        </div>
      )}
      {child.name}
    </>
  );
}

function Mobile({ children, ...props }) {
  return (
    <div className={styles.mobile} {...props}>
      {children}
    </div>
  );
}

function Desktop({ children, ...props }) {
  return (
    <div className={styles.desktop} {...props}>
      {children}
    </div>
  );
}

export default NavBar;
