// create a small placeholder and import its metadata
import Service1SrcsetAvif from '@/assets/images/home/service-1.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service1SercsetWebp from '@/assets/images/home/service-1.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service1Height,
  src as Service1Placeholder,
  width as Service1Width,
} from '@/assets/images/home/service-1.png?w=300&as=metadata';
import Service2SrcsetAvif from '@/assets/images/home/service-2.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service2SercsetWebp from '@/assets/images/home/service-2.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service2Height,
  src as Service2Placeholder,
  width as Service2Width,
} from '@/assets/images/home/service-2.png?w=300&as=metadata';
import Service3SrcsetAvif from '@/assets/images/home/service-3.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service3SercsetWebp from '@/assets/images/home/service-3.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service3Height,
  src as Service3Placeholder,
  width as Service3Width,
} from '@/assets/images/home/service-3.png?w=300&as=metadata';
import Service4SrcsetAvif from '@/assets/images/home/service-4.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service4SercsetWebp from '@/assets/images/home/service-4.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service4Height,
  src as Service4Placeholder,
  width as Service4Width,
} from '@/assets/images/home/service-4.png?w=300&as=metadata';
import Service5SrcsetAvif from '@/assets/images/home/service-5.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service5SercsetWebp from '@/assets/images/home/service-5.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service5Height,
  src as Service5Placeholder,
  width as Service5Width,
} from '@/assets/images/home/service-5.png?w=300&as=metadata';
import Service6SrcsetAvif from '@/assets/images/home/service-6.png?w=300;500;700;900;1200&format=avif&as=srcset';
import Service6SercsetWebp from '@/assets/images/home/service-6.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as Service6Height,
  src as Service6Placeholder,
  width as Service6Width,
} from '@/assets/images/home/service-6.png?w=300&as=metadata';
import Image from '@/components/Image';

export function Service1Image({ ...rest }) {
  return (
    <Image
      avif={Service1SrcsetAvif}
      webp={Service1SercsetWebp}
      placeholder={Service1Placeholder}
      width={Service1Width}
      height={Service1Height}
      {...rest}
    />
  );
}

export function Service2Image({ ...rest }) {
  return (
    <Image
      avif={Service2SrcsetAvif}
      webp={Service2SercsetWebp}
      placeholder={Service2Placeholder}
      width={Service2Width}
      height={Service2Height}
      {...rest}
    />
  );
}

export function Service3Image({ ...rest }) {
  return (
    <Image
      avif={Service3SrcsetAvif}
      webp={Service3SercsetWebp}
      placeholder={Service3Placeholder}
      width={Service3Width}
      height={Service3Height}
      {...rest}
    />
  );
}

export function Service4Image({ ...rest }) {
  return (
    <Image
      avif={Service4SrcsetAvif}
      webp={Service4SercsetWebp}
      placeholder={Service4Placeholder}
      width={Service4Width}
      height={Service4Height}
      {...rest}
    />
  );
}

export function Service5Image({ ...rest }) {
  return (
    <Image
      avif={Service5SrcsetAvif}
      webp={Service5SercsetWebp}
      placeholder={Service5Placeholder}
      width={Service5Width}
      height={Service5Height}
      {...rest}
    />
  );
}

export function Service6Image({ ...rest }) {
  return (
    <Image
      avif={Service6SrcsetAvif}
      webp={Service6SercsetWebp}
      placeholder={Service6Placeholder}
      width={Service6Width}
      height={Service6Height}
      {...rest}
    />
  );
}
