import React from 'react';

import styles from '@/styles/modules/filters/property-type-filter.module.css';

import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';

import { useFormContext } from '@/components/form/provider';
import RadioInput, { radioSizes } from '@/components/form/radio';
import { filterIcons, Icon, icons } from '@/components/icons';
import { propertyTypeFiter } from '@/data/filters';
import { Symbols } from '@/data/symbols';
import { getFilterValues, getName, getSymbolValue } from '@/data/utils';

const propertyTypes = Object.keys(propertyTypeFiter);

export default function PropertyTypeFilter() {
  const { watch } = useFormContext();

  const value = watch(getSymbolValue(propertyTypeFiter, Symbols.Name));

  const index = propertyTypes.findIndex(
    (type) => propertyTypeFiter[type][value],
  );

  return (
    <div className={styles.container}>
      <Accordion.Root
        className={styles.accordionRoot}
        type="multiple"
        defaultValue={[propertyTypes[Math.max(0, index)]]}
      >
        {propertyTypes.map((type) => (
          <Accordion.Item
            className={styles.accordionItem}
            value={type}
            key={type}
          >
            <AccordionTrigger>
              {getSymbolValue(propertyTypeFiter[type], Symbols.Label)}
            </AccordionTrigger>
            <AccordionContent>
              <RadioInput
                className={styles.radioInput}
                spacing={false}
                type="button"
                size={radioSizes.small}
                name={getSymbolValue(propertyTypeFiter, Symbols.Name)}
                validation={getSymbolValue(
                  propertyTypeFiter,
                  Symbols.Validation,
                )}
                options={getFilterValues(propertyTypeFiter[type])}
              >
                {(option) => {
                  if (
                    option.id !==
                      getName(propertyTypeFiter.residential.flatAndApartment) &&
                    option.id !==
                      getName(propertyTypeFiter.residential.HouseAndVilla)
                  )
                    return null;

                  return (
                    <div className={styles.iconContainer}>
                      {(
                        option.id ===
                        getName(propertyTypeFiter.residential.flatAndApartment)
                      ) ?
                        (
                          value ===
                          getName(
                            propertyTypeFiter.residential.flatAndApartment,
                          )
                        ) ?
                          <Icon src={filterIcons.buildingSelected} />
                        : <Icon
                            className={styles.mixBlend}
                            src={filterIcons.building}
                          />

                      : (
                        value ===
                        getName(propertyTypeFiter.residential.HouseAndVilla)
                      ) ?
                        <Icon src={filterIcons.houseSelected} />
                      : <Icon
                          className={styles.mixBlend}
                          src={filterIcons.house}
                        />
                      }
                    </div>
                  );
                }}
              </RadioInput>
            </AccordionContent>
          </Accordion.Item>
        ))}

        {/* <Accordion.Item className={styles.accordionItem} value="item-2">
          <AccordionTrigger>Commercial</AccordionTrigger>
          <AccordionContent>
            <RadioInput
              type="button"
              size={radioSizes.small}
              name="propertyType"
              validation={{ required: 'This field is required' }}
              options={data.propertyTypeValues.commercial}
            />
          </AccordionContent>
        </Accordion.Item> */}

        {/* <Accordion.Item className={styles.accordionItem} value="item-3">
          <AccordionTrigger>Other</AccordionTrigger>
          <AccordionContent>
            <RadioInput
              type="button"
              size={radioSizes.small}
              name="propertyType"
              validation={{ required: 'This field is required' }}
              // options={data.propertyTypeValues.landPlot}
              options={['Agricultural Land']}
            />
          </AccordionContent>
        </Accordion.Item> */}
      </Accordion.Root>
    </div>
  );
}

export const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className={styles.accordionHeader}>
      <Accordion.Trigger
        className={classNames(styles.accordionTrigger, className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        {/* <ChevronDownIcon className="AccordionChevron" aria-hidden /> */}
        <Icon className={styles.accordionChevron} src={icons.dropdown1} />
      </Accordion.Trigger>
    </Accordion.Header>
  ),
);

export const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames(styles.accordionContent, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Content>
  ),
);
