import arrowLeftCardIcon from '@/assets/icons/arrow_left_card.svg';
import arrowLeftSmallIcon from '@/assets/icons/arrow-left-sm.svg';
import arrowPointingRightIcon from '@/assets/icons/arrow-pointing-right.svg';
import arrowRightSmallIcon from '@/assets/icons/arrow-right-sm.svg';
import availabilityIcon from '@/assets/icons/availability.svg';
import balconyIcon from '@/assets/icons/balcony.svg';
import bathroomIcon from '@/assets/icons/bathroom.svg';
import bedroomIcon from '@/assets/icons/bedroom.svg';
import boldDropdownSelectIcon from '@/assets/icons/bold_dropdown_select.svg';
import bookMarkIcon from '@/assets/icons/bookmark.svg';
import builtUpAreaIcon from '@/assets/icons/builtup_area.svg';
import calendarIcon from '@/assets/icons/calendar.svg';
import car from '@/assets/icons/car.svg';
import cctv from '@/assets/icons/cctv.svg';
import clockIcon from '@/assets/icons/clock.svg';
import closeWhiteIcon from '@/assets/icons/close-white.svg';
import closeIcon from '@/assets/icons/close.svg';
import deleteSmallIcon from '@/assets/icons/delete-small.svg';
import deleteIcon from '@/assets/icons/delete.svg';
import dropwDonwCloseIcon from '@/assets/icons/dropdown-close.svg';
import editIcon from '@/assets/icons/edit.svg';
import electricityStatusIcon from '@/assets/icons/electricity_status.svg';
import emailLargeIcon from '@/assets/icons/email-large.svg';
import facingIcon from '@/assets/icons/facing.svg';
import airConditioningIcon from '@/assets/icons/features/air_conditioning.svg';
import ceilingFanIcon from '@/assets/icons/features/ceiling_fan.svg';
import clubHouseIcon from '@/assets/icons/features/club_house.svg';
import communityHallIcon from '@/assets/icons/features/community_hall.svg';
import fireSafetyIcon from '@/assets/icons/features/fire_safety.svg';
import gymIcon from '@/assets/icons/features/gym.svg';
import interconIcon from '@/assets/icons/features/intercon.svg';
import maintenceStaffIcon from '@/assets/icons/features/maintence_staff.svg';
import microwaveIcon from '@/assets/icons/features/microwave.svg';
import parkIcon from '@/assets/icons/features/park.svg';
import playAreaIcon from '@/assets/icons/features/play_area.svg';
import powerBackupIcon from '@/assets/icons/features/power_backup.svg';
import refrigeratorIcon from '@/assets/icons/features/refrigerator.svg';
import reverseParkingIcon from '@/assets/icons/features/reverse_parking.svg';
import securityGuardIcon from '@/assets/icons/features/security_guard.svg';
import shoppingCenterIcon from '@/assets/icons/features/shopping_center.svg';
import sportIcon from '@/assets/icons/features/sport.svg';
import swimingPoolIcon from '@/assets/icons/features/swiming_pool.svg';
import washingMachineIcon from '@/assets/icons/features/washing_machine.svg';
import RegularWaterSupplyIcon from '@/assets/icons/features/water_supply.svg';
import filterIcon from '@/assets/icons/filter.svg';
import buildingSelected from '@/assets/icons/filters/building-selected.png';
import buidlingGIF from '@/assets/icons/filters/building.gif';
import houseSelectedIcon from '@/assets/icons/filters/house-selected.png';
import houseGIF from '@/assets/icons/filters/house.gif';
import flooringIcon from '@/assets/icons/flooring.svg';
import furnishingIcon from '@/assets/icons/furnishing.svg';
import gatedSecurityIcon from '@/assets/icons/gated_security.svg';
import lift from '@/assets/icons/lift.svg';
import locationGreenIcon from '@/assets/icons/location-green.svg';
import locationMediumIcon from '@/assets/icons/location-large.svg';
import locationSmallIcon from '@/assets/icons/location-small.svg';
import chatIcon from '@/assets/icons/nav/chat.svg';
import navEmailIcon from '@/assets/icons/nav/email.svg';
import navPhoneIcon from '@/assets/icons/nav/phone.svg';
import whatsappIcon from '@/assets/icons/nav/whats_app_small.svg';
import nonVegIcon from '@/assets/icons/non_veg.svg';
import notificationIcon from '@/assets/icons/notification.svg';
import petIcon from '@/assets/icons/pet.svg';
import phoneLargeIcon from '@/assets/icons/phone-large.svg';
import propertyAgeIon from '@/assets/icons/property_age.svg';
import propertyOnFloorIcon from '@/assets/icons/property_on_floor.svg';
import propertyTypeIcon from '@/assets/icons/property_type.svg';
import searchIcon from '@/assets/icons/search.svg';
import addIcon from '@/assets/icons/secondary/add.svg';
import leftIcon from '@/assets/icons/secondary/arrow-left.png';
import arrowRightIcon from '@/assets/icons/secondary/arrow-right.png';
import bellIcon from '@/assets/icons/secondary/bell.svg';
import busIcon from '@/assets/icons/secondary/bus-icon.svg';
import cameraIcon from '@/assets/icons/secondary/camera.svg';
import carIcon from '@/assets/icons/secondary/car.svg';
import cardDetailsIcon from '@/assets/icons/secondary/card-details.svg';
import cctvIcon from '@/assets/icons/secondary/cctv.svg';
import cleanerIcon from '@/assets/icons/secondary/cleaner.png';
import cookingIcon from '@/assets/icons/secondary/cooking.svg';
import crossIcon from '@/assets/icons/secondary/cross.png';
import cyclingIcon from '@/assets/icons/secondary/cycling.svg';
import dateSelectorIcon from '@/assets/icons/secondary/date-selector@2x.png';
import dogFootprintIcon from '@/assets/icons/secondary/dog-footprint.svg';
import doneIcon from '@/assets/icons/secondary/done.png';
import dropdownArrowIcon from '@/assets/icons/secondary/dropdown-arrow.svg';
import dropdown1Icon from '@/assets/icons/secondary/dropdown1.svg';
import dumbellIcon from '@/assets/icons/secondary/dumbell.svg';
import emailIcon from '@/assets/icons/secondary/email.svg';
import favoriteBackgroundIcon from '@/assets/icons/secondary/favorite-background.svg';
import fireIcon from '@/assets/icons/secondary/fire.svg';
import garrageIcon from '@/assets/icons/secondary/garrage.svg';
import greenTickIcon from '@/assets/icons/secondary/green-tick.png';
import gridIcon from '@/assets/icons/secondary/grid.svg';
import handBurgerIcon from '@/assets/icons/secondary/handburger.svg';
import heartIcon from '@/assets/icons/secondary/heart.svg';
import homeIcon from '@/assets/icons/secondary/home.svg';
import inputIcon from '@/assets/icons/secondary/input-icon.svg';
import languageIcon from '@/assets/icons/secondary/language.svg';
import leftArrowIcon from '@/assets/icons/secondary/left-arrow.svg';
import leftLogoDividerIcon from '@/assets/icons/secondary/left-logo-divider.svg';
import liftIcon from '@/assets/icons/secondary/lift.png';
import line8Icon from '@/assets/icons/secondary/line-8.svg';
import locationIcon from '@/assets/icons/secondary/location (2).svg';
import locationHomeSmallIcon from '@/assets/icons/secondary/location-home-small.svg';
import locationHomeIcon from '@/assets/icons/secondary/location-home.svg';
import locationLargeIcon from '@/assets/icons/secondary/location-large.svg';
import metroIcon from '@/assets/icons/secondary/metro.svg';
import notebookTickIcon from '@/assets/icons/secondary/notebook-tick.svg';
import phoneIcon from '@/assets/icons/secondary/phone (2).svg';
import planeIcon from '@/assets/icons/secondary/plane.svg';
import plugsTwoIcon from '@/assets/icons/secondary/plugs-two.svg';
import powerIcon from '@/assets/icons/secondary/power.svg';
import rejectIcon from '@/assets/icons/secondary/reject-icon.svg';
import reviewerImageIcon from '@/assets/icons/secondary/reviewer-image.svg';
import ruppeeIcon from '@/assets/icons/secondary/ruppee-icon.svg';
import securityLargeIcon from '@/assets/icons/secondary/security-large.png';
import securityMediumIcon from '@/assets/icons/secondary/security-medium.png';
import securitySmallIcon from '@/assets/icons/secondary/security-small.png';
import speakerIcon from '@/assets/icons/secondary/speaker.svg';
import starIcon from '@/assets/icons/secondary/star.svg';
import treeIcon from '@/assets/icons/secondary/tree.svg';
import twoWaterdropsIcon from '@/assets/icons/secondary/two-waterdrops.svg';
import upArrowIcon from '@/assets/icons/secondary/up-arrow.svg';
import uploadIcon from '@/assets/icons/secondary/upload.svg';
import userIcon from '@/assets/icons/secondary/user.png';
import userSecondaryIcon from '@/assets/icons/secondary/user.svg';
import vector3Icon from '@/assets/icons/secondary/vector-3.svg';
import waterPlaceholderIcon from '@/assets/icons/secondary/water-placeholder.svg';
import waterwaveIcon from '@/assets/icons/secondary/waterwave.svg';
import wavesIcon from '@/assets/icons/secondary/waves.svg';
import security from '@/assets/icons/security.svg';
import selectDropdownIcon from '@/assets/icons/select-dropdown.svg';
import googleIcon from '@/assets/icons/social_media/google.svg';
import tenantIcon from '@/assets/icons/tenant.svg';
import totalFloorIcon from '@/assets/icons/total_floor.svg';
import waterSupplyIcon from '@/assets/icons/water_supply.svg';

export const navIcons = {
  phone: navPhoneIcon,
  chat: chatIcon,
  email: navEmailIcon,
  whatsapp: whatsappIcon,
};

export const filterIcons = {
  house: houseGIF,
  houseSelected: houseSelectedIcon,
  building: buidlingGIF,
  buildingSelected: buildingSelected,
};

export const overviewIcons = {
  balcony: balconyIcon,
  bedroom: bedroomIcon,
  bathroom: bathroomIcon,
  flooring: flooringIcon,
  pet: petIcon,
  propertyAge: propertyAgeIon,
  propertyOnFloor: propertyOnFloorIcon,
  propertyType: propertyTypeIcon,
  totalFloor: totalFloorIcon,
  nonVeg: nonVegIcon,
  availability: availabilityIcon,
  furnishing: furnishingIcon,
  builtUpArea: builtUpAreaIcon,
  electricityStatus: electricityStatusIcon,
  facing: facingIcon,
  gatedSecurity: gatedSecurityIcon,
  tenant: tenantIcon,
  waterSupply: waterSupplyIcon,
};

export const amenitiesIcons = {
  lift: lift,
  cctv: cctv,
  security: security,
  gym: gymIcon,
  communityHall: communityHallIcon,
  shoppingCenter: shoppingCenterIcon,
  clubHouse: clubHouseIcon,
  swimingPool: swimingPoolIcon,
  intercon: interconIcon,
  fireSafety: fireSafetyIcon,
  airConditioning: airConditioningIcon,
  ceilingFan: ceilingFanIcon,
  refrigerator: refrigeratorIcon,
  washingMachine: washingMachineIcon,
  microwave: microwaveIcon,
  oven: microwaveIcon,
  powerBackup: powerBackupIcon,
  playArea: playAreaIcon,
  sport: sportIcon,
  maintenceStaff: maintenceStaffIcon,
  park: parkIcon,
  reverseParking: reverseParkingIcon,
  waterSupply: RegularWaterSupplyIcon,
};

export const icons = {
  selectDropdown: selectDropdownIcon,
  left: leftIcon,
  arrowRight: arrowRightIcon,
  bell: bellIcon,
  bus: busIcon,
  camera: cameraIcon,
  car: carIcon,
  cardDetails: cardDetailsIcon,
  cctv: cctvIcon,
  cleaner: cleanerIcon,
  cooking: cookingIcon,
  cross: crossIcon,
  cycling: cyclingIcon,
  dateSelector: dateSelectorIcon,
  dogFootprint: dogFootprintIcon,
  done: doneIcon,
  dropdownArrow: dropdownArrowIcon,
  dropdown1: dropdown1Icon,
  dumbell: dumbellIcon,
  email: emailIcon,
  favoriteBackground: favoriteBackgroundIcon,
  fire: fireIcon,
  garrage: garrageIcon,
  heart: heartIcon,
  home: homeIcon,
  input: inputIcon,
  language: languageIcon,
  leftArrow: leftArrowIcon,
  leftLogoDivider: leftLogoDividerIcon,
  lift: liftIcon,
  line8: line8Icon,
  location: locationIcon,
  locationHomeSmall: locationHomeSmallIcon,
  locationHome: locationHomeIcon,
  locationLarge: locationLargeIcon,
  metro: metroIcon,
  notebookTick: notebookTickIcon,
  phone: phoneIcon,
  plane: planeIcon,
  plugsTwo: plugsTwoIcon,
  power: powerIcon,
  reject: rejectIcon,
  reviewerImage: reviewerImageIcon,
  ruppee: ruppeeIcon,
  securityLarge: securityLargeIcon,
  securityMedium: securityMediumIcon,
  securitySmall: securitySmallIcon,
  speaker: speakerIcon,
  star: starIcon,
  tree: treeIcon,
  twoWaterdrops: twoWaterdropsIcon,
  upArrow: upArrowIcon,
  upload: uploadIcon,
  user: userIcon,
  vector3: vector3Icon,
  waterPlaceholder: waterPlaceholderIcon,
  waterwave: waterwaveIcon,
  waves: wavesIcon,
  add: addIcon,
  grid: gridIcon,
  handburger: handBurgerIcon,
  greenTick: greenTickIcon,
  search: searchIcon,
  filter: filterIcon,
  locationSmall: locationSmallIcon,
  userSecondary: userSecondaryIcon,
  arrowLeftSmall: arrowLeftSmallIcon,
  arrowRightSmall: arrowRightSmallIcon,
  phoneLarge: phoneLargeIcon,
  emailLarge: emailLargeIcon,
  locationMedium: locationMediumIcon,
  closeWhite: closeWhiteIcon,
  arrowLeftcard: arrowLeftCardIcon,
  close: closeIcon,
  dropdownClose: dropwDonwCloseIcon,
  notification: notificationIcon,
  clock: clockIcon,
  calendar: calendarIcon,
  delete: deleteIcon,
  edit: editIcon,
  deleteSmall: deleteSmallIcon,
  bookmark: bookMarkIcon,
  arrowPointingRight: arrowPointingRightIcon,
  boldDropdoownSelect: boldDropdownSelectIcon,
  locationGreen: locationGreenIcon,
};

export const SocialMeidaIcons = {
  google: googleIcon,
};

export const Icon = ({ src, ...rest }) => {
  return <img loading="lazy" data-type="icon" src={src} {...rest} />;
};

// export const logoIcon = logo;
