import React, { useState } from 'react';

import styles from '@/styles/modules/filters/filter.module.css';
import searchBarStyles from '@/styles/modules/home/search-bar.module.css';

import * as Dialog from '@radix-ui/react-dialog';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';

import AdditionalFeatures from '@/components/filters/additional-features';
import PriceRangeFilter from '@/components/filters/price-range-filter';
import PropertyTypeFilter from '@/components/filters/property-type-filter';
import { ScrollBar } from '@/components/form/select';
import { Icon, icons } from '@/components/icons';
import { getSymbolValue } from '@/data/utils';

export const FilterTypes = {
  propertyType: 'Property Type',
  priceRange: 'Price Range',
  additionalFeatures: 'Additional Features',
};

function Filter({
  raw,
  type,
  dialog,
  label,
  children,
  FooterSecondaryButton,
  FooterCloseButton,
  ...rest
}) {
  if (!type)
    return (
      <PropertyTrigger label={label} {...rest}>
        {children}
      </PropertyTrigger>
    );

  if (raw) {
    return (
      <div>
        <PropertyTrigger label={label} {...rest}>
          {children}
          <div className={styles.rawContent}>
            <RawContent type={type} />
          </div>
        </PropertyTrigger>
      </div>
    );
  }

  const [open, setOpen] = useState(false);

  if (dialog) {
    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger type="button" asChild>
          <PropertyTrigger label={label} {...rest}>
            {children}
          </PropertyTrigger>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={styles.dialogOverlay} />

          <Dialog.Content
            style={{
              '--height': '600px',
            }}
            className={styles.dialogContent}
          >
            <DialogHeader title={type} />
            <Content type={type} />
            <DialogFooter>
              {FooterSecondaryButton && (
                <FooterSecondaryButton className={styles.secondary} />
              )}

              {FooterCloseButton && (
                <FooterCloseButton
                  className={styles.primary}
                  setOpen={setOpen}
                />
              )}
            </DialogFooter>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <Popover.Root modal={false}>
      <Popover.Trigger asChild>
        <PropertyTrigger label={label} {...rest}>
          {children}
        </PropertyTrigger>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          style={{
            '--height': '40vh',
          }}
          avoidCollisions={false}
          hideWhenDetached={true}
          className={styles.popOverContent}
        >
          <PopoverHeader title={type} />
          <Content type={type} />

          <PopoverFooter />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const RawContent = ({ type }) => {
  return (
    <>
      {type === FilterTypes.propertyType && <PropertyTypeFilter />}
      {type === FilterTypes.priceRange && <PriceRangeFilter />}
      {type === FilterTypes.additionalFeatures && <AdditionalFeatures />}
    </>
  );
};

const Content = ({ type }) => {
  return (
    <ScrollBar>
      <RawContent type={type} />
    </ScrollBar>
  );
};
const PropertyTrigger = React.forwardRef(function (
  { label, children, ...rest },
  ref,
) {
  return (
    <div ref={ref} className={searchBarStyles.filter} {...rest}>
      <label>{label}</label>
      <div className={searchBarStyles.inputs}>{children}</div>
    </div>
  );
});

function DialogHeader({ title }) {
  return (
    <div className={classNames(styles.popoverHeader, styles.dialogHeader)}>
      <Dialog.Title asChild>
        <h4>{title}</h4>
      </Dialog.Title>
      <Dialog.Close asChild>
        <button className={styles.close}>
          <Icon src={icons.dropdownClose} />
        </button>
      </Dialog.Close>
    </div>
  );
}

function PopoverHeader({ title }) {
  return (
    <div className={styles.popoverHeader}>
      <h4>{title}</h4>
      <Popover.Close asChild>
        <button className={styles.close}>
          <Icon src={icons.dropdownClose} />
        </button>
      </Popover.Close>
    </div>
  );
}

function DialogFooter({ children }) {
  return (
    <div className={classNames(styles.dialogFooter)}>
      {children ?
        children
      : <Dialog.Close className={styles.primary}>Done</Dialog.Close>}
    </div>
  );
}

function PopoverFooter() {
  return (
    <div className={styles.popoverFooter}>
      <Popover.Close>Done</Popover.Close>
    </div>
  );
}

export default Filter;
