import { forwardRef } from 'react';

import styles from '@/styles/modules/form-input.module.css';

import classNames from 'classnames';

import { useAccessor } from '@/components/accessor';
import { useFormContext } from '@/components/form/provider';
import { Symbols } from '@/data/symbols';
import { getSymbolValue } from '@/data/utils';

const FormInput = forwardRef(
  (
    {
      rounded,
      solidLabel,
      className,
      decoration = { start: null, end: null },
      name,
      type,
      validation,
      ...rest
    },
    ref,
  ) => {
    const accessor = useAccessor();
    const { register } = useFormContext();

    if (accessor) {
      name = name || getSymbolValue(accessor, Symbols.Name);
      validation = validation || getSymbolValue(accessor, Symbols.Validation);
    }

    const { ref: registerRef, ...registerRest } = register(
      name,
      validation ?? {},
    );

    return (
      <>
        {(
          type &&
          (type === 'radio' ||
            type === 'checkbox' ||
            type === 'button' ||
            type === 'tel' ||
            type === 'file')
        ) ?
          <input
            className={classNames(styles.text, className)}
            id={name}
            name={name}
            type={type}
            {...rest}
            {...registerRest}
            ref={(e) => {
              if (ref) ref(e);
              if (registerRef) registerRef(e);
            }}
          />
        : type === 'textarea' ?
          <textarea
            className={classNames(styles.text, className)}
            id={name}
            name={name}
            {...rest}
            {...registerRest}
            ref={(e) => {
              if (ref) ref(e);
              if (registerRef) registerRef(e);
            }}
          />
        : <div
            className={classNames(
              styles.text,
              className,
              styles[rounded && 'rounded'],
              styles[solidLabel && 'solidLabel'],
            )}
          >
            {decoration.start && (
              <label htmlFor={name}>{decoration.start}</label>
            )}
            <input
              id={name}
              name={name}
              type={type ?? 'text'}
              {...registerRest}
              {...rest}
              ref={(e) => {
                if (ref) ref(e);
                if (registerRef) registerRef(e);
              }}
            />
            {decoration.end && <label htmlFor={name}>{decoration.end}</label>}
          </div>
        }
      </>
    );
  },
);

export default FormInput;
