import React from 'react';

import styles from '@/styles/modules/overview-item.module.css';

import Skeleton from '@/components/skeleton';

const OverviewItem = ({ icon, label, value, valueLabel }) => {
  return (
    <div className={styles.item}>
      <span className={styles.icon}>
        {icon && <img src={icon} alt={label} />}
      </span>
      <div>
        <span className={styles.value}>
          {valueLabel ? parseValueLabel(value, valueLabel) : value.toString()}
        </span>
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
};

export function parseValueLabel(value, valueLabel) {
  if (typeof valueLabel === 'function') return valueLabel(value);

  return valueLabel;
}
export const OverviewItemSkeleton = () => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Skeleton.Paragraph lineCount={1} />
      <Skeleton.Span lineCount={1} />
    </div>
  );
};
export default OverviewItem;
