import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signInWithPopup,
} from 'firebase/auth';

import { getUserData } from '@/lib/db';
import app from '@/lib/firebase';

export { RecaptchaVerifier } from 'firebase/auth';

export const auth = getAuth(app);

auth.useDeviceLanguage();

export const isUserLoggedIn = async () => {
  const user = await new Promise((res) => {
    const unsuscribe = onAuthStateChanged(auth, (user) => {
      unsuscribe();
      res(user);
    });
  });
  console.log(user);
  return !!user;
};

export const getUser = (cb) => {
  let unsubscribe = null;
  const promise = new Promise(async (res) => {
    unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        cb({ user: null, userData: {} });
        return res({ user: null, userData: {} });
      }

      const userData = await getUserData(user.uid);

      cb({ user, userData: userData.data() });
      return res({ user, userData: userData.data() });
    });
  });

  return [promise, unsubscribe];
};

export const signInWithGooglePopUP = async () => {
  const provider = new GoogleAuthProvider();
  const user = await signInWithPopup(auth, provider);
  console.log(user);
};

export const firebaseSignInWithPhoneNumber = async (phoneNumber) => {
  const appVerifier = window.recaptchaVerifier;

  if (!appVerifier) {
    throw new Error('RecaptchaVerifier is not loaded');
  }

  return new Promise((resolve, reject) => {
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log('SMS sent', confirmationResult);
        // ...
        resolve(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...

        window.recaptchaVerifier.render().then(function (widgetId) {
          console.log('Resetting recaptcha');
          grecaptcha.reset(widgetId);
        });
        console.error(error);
        reject(error);
        throw error;
      });
  });
};

export async function firebaseConfirmPhoneSignIn(confirmationCode) {
  const confirmationResult = window.confirmationResult;

  if (!confirmationResult) throw new Error('Confirmation result is not loaded');

  return confirmationResult.confirm(confirmationCode);
}
