import { Suspense } from 'react';

import formStyles from '@/styles/modules/form-input.module.css';
import styles from '@/styles/modules/pages/properties.module.css';

import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import {
  Await,
  defer,
  Link,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';

import { Error } from '@/App';
import linvingRoom from '@/assets/images/living-room.png';
import notingFound from '@/assets/images/noting_found.png';
import { ConfitteProvider } from '@/components/confitte';
import SearchBar from '@/components/home/search-bar';
import { Icon, icons } from '@/components/icons';
import NavBar from '@/components/nav';
import Pagination from '@/components/Pagination';
import PropertyCard, {
  PropertyCardSkeleton,
  PropertyCardTypes,
} from '@/components/preview/preproty-card';
import { PATHS } from '@/constants/paths';
import {
  DEFAULT_SORT,
  getPriceLabel,
  locationFilter,
  propertyForFilter,
  sortFilter,
} from '@/data/filters';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
  propertyType,
} from '@/data/preporty-detail';
import propertyFeaturesData, {
  PROPERTY_FEATURES_NAME,
} from '@/data/property-features';
import { PROPERTY_LOCATION_NAME } from '@/data/property-location';
import { PROPERTY_PRICING_NAME } from '@/data/property-pricing';
import { getFilterValues, getName, getValues } from '@/data/utils';
import { parseLocation, parseTitle } from '@/pages/properties';
import { getPropertyPrice } from '@/pages/property-preview';

export const loader = async ({ request, params }) => {
  const url = new URL(request.url);

  const searchParams = url.searchParams;

  const { getFilteredProperties } = await import('@/lib/db');

  const result = getFilteredProperties(searchParams);

  // const properties = new Promise((res, rej) => {
  //   setTimeout(async () => {
  //     res(await getFilteredProperties(searchParams));
  //   }, 10000);
  // });
  // don't await just return the promise
  return defer({
    result,
  });
};

export async function onPageChangeHandler(
  result,
  value,
  searchParams,
  setSearchParams,
) {
  const res = await result;
  const lastDoc = res.data[res.data.length - 1];
  const firstDoc = res.data[0];

  const firstTimestamp =
    firstDoc.data.timestamp.toMillis ?
      firstDoc.data.timestamp.toMillis()
    : firstDoc.data.timestamp;
  const firstPrice = firstDoc.data[PROPERTY_PRICING_NAME].rent;

  const lastTimestamp =
    lastDoc.data.timestamp.toMillis ?
      lastDoc.data.timestamp.toMillis()
    : lastDoc.data.timestamp;
  const lastPrice = lastDoc.data[PROPERTY_PRICING_NAME].rent;

  const currentPage = res.page;
  const direction = value > currentPage ? 'next' : 'prev';

  console.log(res.page, value);

  console.log('direction', direction);

  const sort = searchParams.get(getName(sortFilter));

  if (direction === 'next') {
    if (!lastTimestamp) return;

    if (sort === 'price_asc' || sort === 'price_desc') {
      console.warn('Setting both timestamp and price');
      searchParams.set('startAfter', [lastTimestamp, lastPrice].join(','));
    } else {
      searchParams.set('startAfter', lastTimestamp);
    }

    searchParams.set('page', value);
    searchParams.delete('endBefore');
  } else {
    if (!firstTimestamp) return;

    if (sort === 'price_asc' || sort === 'price_desc') {
      searchParams.set('endBefore', [firstTimestamp, firstPrice].join(','));
    } else {
      searchParams.set('endBefore', firstTimestamp);
    }
    searchParams.set('page', value);
    searchParams.delete('startAfter');
  }

  setSearchParams(searchParams.toString());
}

export function Component() {
  const { result } = useLoaderData();
  const { state, location } = useNavigation();
  const loading = state === 'loading' && location?.pathname === PATHS.SEARCH;

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage =
    searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  function onChangeHandler(value) {
    onPageChangeHandler(result, value, searchParams, setSearchParams);
  }

  return (
    <>
      <NavBar position="relative" type="other" />
      <div className={styles.container}>
        <header>
          <div>
            <SearchBar useSecondarySearchBar={true} />
          </div>
        </header>

        {loading ?
          <main>
            <PropertyCardSkeleton />
          </main>
        : <Suspense
            fallback={
              <main>
                <PropertyCardSkeleton />
              </main>
            }
          >
            <Await
              errorElement={<Error />}
              resolve={result}
              children={(result) => (
                <>
                  <SearchResultHeader result={result} />
                  <main>
                    {!result.data.length ?
                      <NothingFound />
                    : <RenderProperties
                        type={PropertyCardTypes.display}
                        properties={result.data}
                      />
                    }
                  </main>
                  {result.data && result.data.length > 0 && (
                    <Pagination
                      disableLeftArrow={currentPage === 1}
                      disableRightArrow={result.isLastPage}
                      onlyArrows={result.type === 'firestore'}
                      currentPage={result.page}
                      totalPages={result.totalPages}
                      onPageChange={onChangeHandler}
                    />
                  )}
                </>
              )}
            />
          </Suspense>
        }
      </div>
    </>
  );
}

function SearchResultHeader({ result }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const count = result.count;

  const propertyTypeValue = searchParams.get(getName(propertyType));

  const propertyFor = searchParams.get(getName(propertyForFilter));

  const locations = searchParams.get(getName(locationFilter));

  console.log('propertyTypeValue');
  const values = [
    {
      seperator: '',
      value:
        propertyType[propertyTypeValue] ||
        (count > 1 ? 'Properties' : 'Property'),
    },
    {
      seperator: 'for',
      value: propertyDetailData.propertyFor[propertyFor],
    },
    {
      seperator: 'in',
      value:
        locations &&
        decodeURIComponent(locations).split(',').filter(Boolean).join(', '),
    },
  ];

  return (
    <div className={styles.searchResultHeader}>
      <h2>
        Showing {count}{' '}
        {values
          .filter(({ value }) => value)
          .map(
            ({ value, seperator }) =>
              `${seperator ? seperator + ' ' : ''}${value}`,
          )
          .join(' ')}
      </h2>
      <SortBy />
    </div>
  );
}

export function onSortValueChangeHandler(value, searchParams, setSearchParams) {
  console.log('value', value);

  searchParams.delete('page');
  searchParams.delete('startAfter');
  searchParams.delete('endBefore');

  searchParams.set('sort', value);
  setSearchParams(searchParams.toString());
}

export function SortBy() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sort = searchParams.get(getName(sortFilter));
  console.log('sort', sort);

  const sortFilters = getFilterValues(sortFilter);

  return (
    <div className={styles.sortBy}>
      <span>Sort by:</span>
      <Select.Root
        onValueChange={(value) =>
          onSortValueChangeHandler(value, searchParams, setSearchParams)
        }
        defaultValue={sort ?? DEFAULT_SORT}
      >
        <Select.Trigger className={classNames(formStyles.secondaryTrigger)}>
          <Select.Value placeholder="Sort" />

          <Icon src={icons.boldDropdoownSelect} />
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            align="start"
            side="bottom"
            position="popper"
            className={styles.sortSelectContent}
          >
            <Select.Viewport>
              {sortFilters.map((filter) => (
                <Select.Item value={filter.value} className={formStyles.item}>
                  <Select.ItemText>{filter.label}</Select.ItemText>
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}

export function NothingFound() {
  return (
    <div className={styles.nothingFound}>
      <div className={styles.imgContainer}>
        <img
          height={150}
          width={150}
          src={notingFound}
          loading="lazy"
          alt="nothing found"
        />
      </div>
      <div>
        <p>
          We’re sorry! We can’t seem to find any properties that match your
          filter criteria.
        </p>
        <Link to={PATHS.PROPERTIES}>Reset all Filters</Link>
      </div>
    </div>
  );
}

/**
 *
 * @param {{
 *  type : "featured" | "display",
 *  properties: any[]
 * }} props
 * @returns
 */
export function RenderProperties({ type = 'featured', properties }) {
  return (
    // <>
    <ConfitteProvider>
      {properties.map(({ data, id }) => (
        <PropertyCard
          type={type}
          features={[
            {
              dataObject: propertyDetailData,
              data: data[PROPERTY_DETAIL_NAME],
            },
            {
              dataObject: propertyFeaturesData,
              data: data[PROPERTY_FEATURES_NAME],
            },
          ]}
          // price={getPriceLabel(data[PROPERTY_PRICING_NAME].rent)}
          price={getPropertyPrice(
            data,
            getPriceLabel(data[PROPERTY_PRICING_NAME].rent),
          )}
          image={linvingRoom}
          images={data.property_images}
          location={parseLocation(data[PROPERTY_LOCATION_NAME])}
          title={parseTitle(data)}
          for={
            'For ' +
            propertyDetailData.propertyFor[
              data[PROPERTY_DETAIL_NAME].propertyFor
            ]
          }
          id={id}
          key={id}
        />
      ))}
    </ConfitteProvider>
    // </>
  );
}

export function FeaturedPropertyCard({ data, id }) {
  return (
    <PropertyCard
      type={'featured'}
      features={[
        {
          dataObject: propertyDetailData,
          data: data[PROPERTY_DETAIL_NAME],
        },
        {
          dataObject: propertyFeaturesData,
          data: data[PROPERTY_FEATURES_NAME],
        },
      ]}
      price={getPriceLabel(data[PROPERTY_PRICING_NAME].rent)}
      image={linvingRoom}
      images={data.property_images}
      location={parseLocation(data[PROPERTY_LOCATION_NAME])}
      title={parseTitle(data)}
      for={
        'For ' +
        propertyDetailData.propertyFor[data[PROPERTY_DETAIL_NAME].propertyFor]
      }
      id={id}
      key={id}
    />
  );
}
