import ClientReviewSrcsetAvif from '@/assets/images/home/client-review.png?w=300;500;700;900;1200&format=avif&as=srcset';
import ClientReviewSercsetWebp from '@/assets/images/home/client-review.png?w=300;500;700;900;1200&format=webp&as=srcset';
import {
  height as ClientReviewHeight,
  src as ClientReviewPlaceholder,
  width as ClientReviewWidth,
} from '@/assets/images/home/client-review.png?w=300&as=metadata';
import Image from '@/components/Image';

export function ClientReviewImage({ ...rest }) {
  return (
    <Image
      avif={ClientReviewSrcsetAvif}
      webp={ClientReviewSercsetWebp}
      placeholder={ClientReviewPlaceholder}
      width={ClientReviewWidth}
      height={ClientReviewHeight}
      {...rest}
    />
  );
}
