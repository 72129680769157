import React, { useEffect, useState } from 'react';

import styles from '@/styles/modules/similar-properties.module.css';

import { ConfitteProvider } from '@/components/confitte';
import Container from '@/components/container';
import ContainerBox from '@/components/container-box';
import PropertyCard from '@/components/preview/preproty-card';
import { PROPERTY_DETAIL_NAME } from '@/data/preporty-detail';
import { PROPERTY_LOCATION_NAME } from '@/data/property-location';
import { getSimilarProperties } from '@/lib/db';
import { RenderProperties } from '@/pages/search';

const SimilarProperties = ({ property, location }) => {
  const [properties, setProperties] = useState(null);

  useEffect(() => {
    const data = property;
    const id = data.id;
    // get lcoation , property type and property for
    const location = data[PROPERTY_LOCATION_NAME];
    const propertyType = data[PROPERTY_DETAIL_NAME].propertyType;
    const propertyFor = data[PROPERTY_DETAIL_NAME].propertyFor;

    const searchAbleLocationTerms = [
      location.societyName,
      location.landmark,
      location.sublocality,
      location.locality,
      location.city,
    ].filter(Boolean);

    const similarProperties = getSimilarProperties(
      id,
      searchAbleLocationTerms,
      propertyType,
      propertyFor,
    );

    similarProperties.then((res) => {
      console.warn('similarProperties', res);
      setProperties(res);
    });
  }, []);

  if (!properties || properties.length === 0) return null;

  return (
    <div className={styles.container}>
      <Container className={styles.widthContainer}>
        <ContainerBox type="none" title={`Similar Properties in ${location}`}>
          <div className={styles.propertiesGrid}>
            {properties && (
              <RenderProperties type={null} properties={properties} />
            )}
          </div>
        </ContainerBox>
      </Container>
    </div>
  );
};

export default SimilarProperties;
