import style from '@/styles/modules/spinner.module.css';

import classNames from 'classnames';

const SpinnerTypes = {
  overlay: 'overlay',
};

export default function Spinner({ type = SpinnerTypes.overlay }) {
  return (
    <div className={classNames(style.container, style[type])}>
      <div className={style.spinner}></div>
    </div>
  );
}
