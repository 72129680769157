import { useEffect, useState } from 'react';

import MapLibreGlDirections, {
  MapLibreGlDirectionsRoutingEvent,
} from '@maplibre/maplibre-gl-directions';
import {
  LngLatBounds,
  Map as MapLibreMap,
  Marker,
  NavigationControl,
  Popup,
} from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';

import { createContext, useCallback, useContext, useRef } from 'react';

import { icons } from '@/components/icons';
import { formatDistance, formatDuration } from '@/components/Neighbourhood';

export const olaMapContext = createContext();

export const useOlaMap = () => useContext(olaMapContext);

export const MapProvider = ({ children }) => {
  const [mapReady, setMapReady] = useState(false);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);

  const [location, setLocation] = useState(null);
  const [routeResult, setRouteResult] = useState(null);

  const destinationMarker = useRef(null);
  const setDestinationMarker = (marker) => {
    if (!destinationMarker.current) destinationMarker.current = marker;
  };

  const setDestinationPoint = useCallback(
    (options) => {
      const destinationLocation = { lat: options.lat, lng: options.lng };
      const sourceLocation = location;
      console.log('clicking');
      if (!directions) return;

      if (!destinationMarker.current) {
        const marker = new Marker({
          element: createCustomMarker(icons.locationGreen),
        });

        marker
          .setLngLat([destinationLocation.lng, destinationLocation.lat])
          .addTo(map);
        setDestinationMarker(marker);
      } else {
        destinationMarker.current.remove();
        destinationMarker.current
          .setLngLat([destinationLocation.lng, destinationLocation.lat])
          .addTo(map);
      }

      directions.clear();

      directions.setWaypoints([
        [destinationLocation.lng, destinationLocation.lat],
        [sourceLocation.lng, sourceLocation.lat],
      ]);

      fitMapToCoordinates(map, [destinationLocation, sourceLocation]);

      const result = options.popUpData;
      console.log('options and result ', options, result);
      directions.once('fetchroutesend', (e) => {
        addPopUp(
          map,
          directions,
          destinationLocation,
          result || e.data.routes[0],
        );
      });
    },

    [map, location],
  );

  const values = {
    map,
    mapReady,
    setMapReady,
    setMap,
    setDestinationPoint,
    directions,
    setDirections,
    setLocation,
    location,
    routeResult,
    setRouteResult,
  };

  return (
    <olaMapContext.Provider value={values}>{children}</olaMapContext.Provider>
  );
};

export function OlaMap({ apikey, location, zoom, children }) {
  const {
    map,
    setDestinationPoint,
    setDirections,
    mapReady,
    setMapReady,
    directions,
    setMap,
    setLocation,
    routeResult,
    setRouteResult,
  } = useOlaMap();

  useEffect(() => {
    if (!mapReady) return;

    setLocation(location);

    const mapInstance = new MapLibreMap({
      container: 'central-map',
      center: [location.lng, location.lat],
      zoom,
      style:
        'https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json',
      transformRequest: (url, resourceType) => {
        // Replace the wrong URL with the correct one
        url = url.replace('app.olamaps.io', 'api.olamaps.io');

        // Add the API key to the URL based on existing parameters
        if (url.includes('?')) {
          url = url + '&api_key=' + apikey;
        } else {
          url = url + '?api_key=' + apikey;
        }
        return { url, resourceType };
      },
    });

    const nav = new NavigationControl({
      visualizePitch: false,
      showCompass: true,
    });

    mapInstance.addControl(nav, 'top-left');

    mapInstance.on('load', () => {
      setMap(mapInstance);

      // Create an instance of the default class
      const directions = new MapLibreGlDirections(mapInstance, {
        layers,
      });

      setDirections(directions);
    });
  }, [mapReady]);

  useEffect(() => {
    if (!directions || !map) return;

    map.on('click', (e) => {
      setDestinationPoint(e.lngLat);
    });
  }, [directions, map]);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        ref={() => setMapReady(true)}
        id="central-map"
      >
        {children}
      </div>
    </>
  );
}

export function CustomMarker({ src, location }) {
  const { map } = useOlaMap();

  useEffect(() => {
    if (!map) return;

    const marker = new Marker({ element: createCustomMarker(src) });

    marker.setLngLat([location.lng, location.lat]).addTo(map);
  }, [map]);

  return null;
}

function createCustomMarker(icon) {
  const el = document.createElement('div');
  el.className = 'marker';
  el.style.backgroundImage = `url(${icon})`;
  el.style.width = '32px';
  el.style.height = '42px';
  el.style.backgroundSize = '100%';
  el.style.backgroundRepeat = 'no-repeat';
  return el;
}

export const layers = [
  {
    id: 'maplibre-gl-directions-snapline',
    type: 'line',
    source: 'maplibre-gl-directions',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-dasharray': [2, 2],
      'line-color': '#000000',
      'line-opacity': 0.65,
      'line-width': 2,
    },
    filter: ['==', ['get', 'type'], 'SNAPLINE'],
  },

  {
    id: 'maplibre-gl-directions-routeline',
    type: 'line',
    source: 'maplibre-gl-directions',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#44A0FC',
      'line-width': 4,
    },
    filter: ['==', ['get', 'route'], 'SELECTED'],
  },
];

// ------- UTILS -------

// Function to fit map to show all coordinates
export function fitMapToCoordinates(map, coordinates) {
  if (!map || !coordinates || coordinates.length === 0) return;

  const bounds = new LngLatBounds();
  coordinates.forEach((coord) => {
    bounds.extend([coord.lng, coord.lat]);
  });

  map.fitBounds(bounds, {
    padding: 50, // Add some padding around the bounds
    maxZoom: 15, // Limit how far the map will zoom in
  });
}

// Function to add click event with popup

export async function addPopUp(map, directions, { lat, lng } = {}, result) {
  console.log(map, directions);

  if (!map || !directions) return;

  // Remove existing popup if any
  const existingPopup = document.querySelector('.maplibregl-popup');
  if (existingPopup) existingPopup.remove();

  console.log(directions);

  if (!result) return;
  let { distance, duration, displayName } = result;
  // Get route information
  distance = formatDistance(distance); // Convert to km
  duration = formatDuration(duration); // Convert to minutes

  // Update popup content
  const popupContent = `
          <div>
            <p>Location: ${displayName?.text ?? ''}</p>
            <p>Distance: ${distance}</p>
            <p>Estimated Time: ${duration}</p>
          </div>
        `;

  new Popup({
    offset: 25,
  })
    .setLngLat([lng, lat])
    .setHTML(popupContent)
    .addTo(map);
}
