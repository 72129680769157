const Image = ({
  avif,
  webp,
  placeholder,
  width,
  height,
  className,
  ...rest
}) => {
  return (
    <picture className={className}>
      <source type="image/avif" srcSet={avif} />
      <source type="image/webp" srcSet={webp} />
      <img
        src={placeholder}
        alt={placeholder}
        width={width}
        height={height}
        {...rest}
      />
    </picture>
  );
};

export default Image;
