import styles from '@/styles/modules/home/client-reviews.module.css';

import clientReviewImage from '@/assets/images/home/client-review.png';
import { Heading } from '@/components/home/featured-properties';

import '@brainhubeu/react-carousel/lib/style.css';

import Slider from 'react-slick';

import Container from '@/components/container';
import { Icon, icons } from '@/components/icons';
import { ClientReviewImage } from '@/components/images/client-review';

const reviews = [
  {
    name: 'Aishwarya Malik',
    date: 'Tenant | 18 months',
    review:
      "This real estate agent has helped us in purchasing an ideal 3BHK flat in the posh area and at the most reasonable price. We have contacted many agents before but only this one agent made a good offer. I'm really thankful to this agent for helping us in getting the best deal.",
    image: 'path_to_image.jpg',
  },
  {
    name: 'Aishwarya Malik',
    date: 'Tenant | 18 months',
    review:
      "This real estate agent has helped us in purchasing an ideal 3BHK flat in the posh area and at the most reasonable price. We have contacted many agents before but only this one agent made a good offer. I'm really thankful to this agent for helping us in getting the best deal.",
    image: 'path_to_image.jpg',
  },
  {
    name: 'Aishwarya Malik',
    date: 'Tenant | 18 months',
    review:
      "This real estate agent has helped us in purchasing an ideal 3BHK flat in the posh area and at the most reasonable price. We have contacted many agents before but only this one agent made a good offer. I'm really thankful to this agent for helping us in getting the best deal.",
    image: 'path_to_image.jpg',
  },
  {
    name: 'Aishwarya Malik',
    date: 'Tenant | 18 months',
    review:
      "This real estate agent has helped us in purchasing an ideal 3BHK flat in the posh area and at the most reasonable price. We have contacted many agents before but only this one agent made a good offer. I'm really thankful to this agent for helping us in getting the best deal.",
    image: 'path_to_image.jpg',
  },
];

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  adaptiveHeight: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
  cssEase: 'ease-out',

  className: styles.carousel,
  dotsClass: styles.dot,
  // appendDots: (dots) => console.log(dots),
  // fade: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <button onClick={onClick} className={styles.button}>
      <Icon src={icons.arrowRightSmall} />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <button onClick={onClick} className={styles.button}>
      <Icon src={icons.arrowLeftSmall} />
    </button>
  );
}

const ClientReview = ({ name, date, review }) => (
  <div className={styles.reviewCard}>
    <div className={styles.reviewContent}>
      <div className={styles.heading}>
        <h3>{name}</h3>
        <p className={styles.date}>{date}</p>
      </div>
      <p className={styles.review}>{review}</p>
    </div>
  </div>
);

const ClientReviews = () => {
  return (
    <section>
      <Container className={styles.container}>
        <Heading>Client Reviews</Heading>
        <div className={styles.clientReviews}>
          <div className={styles.reviewContainer}>
            <div className={styles.carouselContainer}>
              <Slider {...settings}>
                {reviews.map((review, index) => (
                  <ClientReview key={index} {...review} />
                ))}
              </Slider>
            </div>
            <a href="#">See all reviews</a>
          </div>
          <div className={styles.imageContainer}>
            <ClientReviewImage loading="lazy" />
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ClientReviews;
