import { useEffect, useMemo, useRef, useState } from 'react';

import styles from '@/styles/modules/property-card.module.css';
import dialogStyles from '@/styles/modules/property-post-dialog.module.css';

import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import {
  Icon,
  icons,
  overviewIcons,
  SocialMeidaIcons,
} from '@/components/icons';
import Skeleton from '@/components/skeleton';
import Spinner from '@/components/spinner';
import { ToastProvider, useTost } from '@/components/toast';
import { useUser } from '@/contexts/auth-context';
import propertyDetailData from '@/data/preporty-detail';
import propertyFeaturesData from '@/data/property-features';
import { Symbols } from '@/data/symbols';
import { getName, getValues } from '@/data/utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { createContext, useContext } from 'react';

import authStyles from '@/styles/modules/auth.module.css';

import { AnimatePresence } from 'framer-motion';
import { Sheet } from 'react-modal-sheet';
import { useMediaQuery } from 'react-responsive';

import { ConfitteExplosion, useConfitte } from '@/components/confitte';
import FormGroup from '@/components/form/group';
import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import { parseValueLabel } from '@/components/preview/overview-item';
import { ShareButton } from '@/components/preview/property-heading';
import { useDebounce } from '@/hooks/useDebounce';
import { NoImage } from '@/pages/no-image';

export const PropertyCardTypes = {
  Featured: 'featured',
  display: 'display',
};

export function usePropertyActions(id) {
  const { user, userData, setUserData } = useUser();

  const [itemNotInterested, setItemNotInterested] = useState(null);

  const { setToast } = useTost();

  useEffect(() => {
    if (!userData?.notInterested) return;

    setItemNotInterested(userData?.notInterested.includes(id));
  }, [userData?.notInterested]);

  const notInterestedHandler = useDebounce(async (value) => {
    const {
      addPropertyToUserNotInterestedList,
      removePropertyFromUserNotInterestedList,
    } = await import('@/lib/actions');

    const userId = user.uid;

    const propertyId = id;
    console.log('notInterestedHandler', value);
    try {
      if (!value) {
        await removePropertyFromUserNotInterestedList(userId, propertyId);
        setUserData((prev) => ({
          ...prev,
          notInterested: prev.notInterested.filter((p) => p !== propertyId),
        }));
      } else {
        await addPropertyToUserNotInterestedList(userId, propertyId);
        // setUserData((prev) => ({
        //   ...prev,
        //   notInterested: [...(prev.notInterested || []), propertyId],
        // }));
      }
    } catch (e) {
      console.error(e);
      setToast({
        open: true,
        message: 'Unable to add to not interested list',
        type: 'error',
      });
    }
  }, 1000);

  function undoNotInterestedClickHandler() {
    const value = false;
    setItemNotInterested(value);

    notInterestedHandler(value);
  }

  function notIntrestedClickHandler() {
    const value = true;
    setItemNotInterested('show_undo');

    notInterestedHandler(value);
  }

  return {
    itemNotInterested,
    undoNotInterestedClickHandler,
    notIntrestedClickHandler,
  };
}

function SampleNextArrow(props) {
  const { style, onClick } = props;

  return (
    <button
      className={styles.arrowRight}
      style={{ ...style }}
      onClick={onClick}
    >
      <Icon
        style={{
          transform: 'rotate(180deg)',
        }}
        src={icons.arrowLeftcard}
      />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;

  return (
    <button className={styles.arrowLeft} style={{ ...style }} onClick={onClick}>
      <Icon src={icons.arrowLeftcard} />
    </button>
  );
}

const includeData = [
  propertyDetailData.bhkType,
  propertyDetailData.bathrooms,
  propertyDetailData.builtUpArea,
].map((item) => getName(item));

export function WishlistButton({ id, type, className }) {
  const { user, userData, setUserData } = useUser();

  const { setToast } = useTost();

  const [itemWishlist, setItemWishlist] = useState(null);

  const { setIsExploding } = useConfitte();

  useEffect(() => {
    if (!userData?.wishlist) return;

    setItemWishlist(userData?.wishlist.includes(id));
  }, [userData?.wishlist]);

  const whislistClickHandler = useDebounce(async (value) => {
    const { addPropertyToUserWishList, removePropertyFromUserWishList } =
      await import('@/lib/actions');
    // perform wishlist action

    const userId = user.uid;

    const propertyId = id;

    console.log('whislistClickHandler', value);

    try {
      if (!value) {
        await removePropertyFromUserWishList(userId, propertyId);
        setUserData((prev) => ({
          ...prev,
          wishlist: prev.wishlist.filter((p) => p !== propertyId),
        }));
      } else {
        await addPropertyToUserWishList(userId, propertyId);
        setUserData((prev) => ({
          ...prev,
          wishlist: [...(prev.wishlist || []), propertyId],
        }));
      }
    } catch (e) {
      console.error(e);
      setToast({
        open: true,
        message: 'Unable to add to wishlist',
        type: 'error',
      });
    }
  }, 3000);
  return (
    <RequireSignInButton
      type="button"
      onClick={() => {
        const value = !itemWishlist;
        setItemWishlist(value);

        setIsExploding(value ? id : false);

        whislistClickHandler(value);
      }}
      className={className}
    >
      <ConfitteExplosion id={id} />
      <WishListIcon type={type} active={itemWishlist} />
    </RequireSignInButton>
  );
}

const PropertyCard = ({
  id,
  type,
  images,
  title,
  location,
  price,
  area,
  ...rest
}) => {
  const data = rest.features;

  const items = useMemo(
    () =>
      data &&
      data
        .map((item) =>
          getValues(item.dataObject)
            .filter(
              (values) =>
                includeData.includes(values.id) &&
                Object.prototype.hasOwnProperty.call(item.data, values.value),
            )

            // sort based on the order of the includeDate
            .sort(
              (a, b) => includeData.indexOf(a.id) - includeData.indexOf(b.id),
            )
            .map((values, index) => ({
              ...values,
              value:
                item.dataObject[values.id][item.data[values.value]]?.[
                  Symbols.Label
                ] ??
                item.dataObject[values.id][item.data[values.value]] ??
                item.data[values.value],

              valueLabel:
                values.id === 'bathrooms' ?
                  item.dataObject[values.id][Symbols.OverviewLabel]
                : (item.dataObject[values.id][item.data[values.value]]?.[
                    Symbols.ValueLabel
                  ] ?? item.dataObject[values.id]?.[Symbols.ValueLabel]),
            })),
        )
        .flat(),
    [data],
  );

  const {
    itemNotInterested,
    undoNotInterestedClickHandler,
    notIntrestedClickHandler,
  } = usePropertyActions(id);

  const isMobile = useMediaQuery({
    maxWidth: 1100,
  });

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: isMobile ? 2 : 1,
    slidesToScroll: isMobile ? 2 : 1,
    dotsClass: styles.dot,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return itemNotInterested === 'show_undo' ?
      <div
        className={classNames(styles.card, styles[type], styles.undoContainer)}
      >
        <h4>Property Removed from your search</h4>
        <button
          onClick={() => undoNotInterestedClickHandler()}
          className={styles.notIntrested}
        >
          Undo
        </button>
      </div>
    : !itemNotInterested && (
        <div
          className={classNames(
            styles.card,
            styles[type],
            isMobile && styles.mobile,
          )}
        >
          <div className={styles.actions}>
            {type === PropertyCardTypes.display && (
              <RequireSignInButton
                type="button"
                onClick={() => notIntrestedClickHandler()}
                className={styles.notIntrested}
              >
                <Icon src={icons.close} />
                Not interested
              </RequireSignInButton>
            )}
            <WishlistButton
              className={styles.favoriteButton}
              id={id}
              type={type}
            >
              wishlist
            </WishlistButton>

            {type !== PropertyCardTypes.Featured && (
              <ShareButton id={id} className={styles.shareButton} />
            )}
          </div>
          <div className={styles.image}>
            {/* <LivingRoomImage loading="lazy" className={styles.image} /> */}
            {images && images.length > 0 ?
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={image.id} className={styles.imageContainer}>
                    <img
                      loading="lazy"
                      src={image.images[1].downloadURL}
                      alt="image"
                    />
                  </div>
                ))}
              </Slider>
            : <NoImage size={100} />}
          </div>
          <Link className={styles.contentContainer} to={`/property?id=${id}`}>
            {(type === PropertyCardTypes.Featured ||
              type === PropertyCardTypes.display) && (
              <div className={styles.featuerdHeader}>
                <div className={styles.price}>
                  <Icon src={icons.ruppee} />
                  <p>{price}</p>
                </div>
                {/* <button>{rest.for}</button> */}
                <button>View</button>
              </div>
            )}
            <div className={styles.content}>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.location}>
                <Icon src={icons.locationSmall} />
                <p>{location}</p>
              </div>
              {price &&
                type !== PropertyCardTypes.Featured &&
                type !== PropertyCardTypes.display && (
                  <div className={styles.price}>
                    <Icon src={icons.ruppee} />
                    <p>{price}</p>
                  </div>
                )}
              {!type && items && items.length > 0 && (
                <div className={styles.area}>
                  <Icon src={overviewIcons.builtUpArea} />
                  <p>
                    {items.find((item) => item.id === 'builtUpArea')?.value} sq.
                    ft.
                  </p>
                </div>
              )}
              {(type == 'featured' || type === 'display') &&
                items &&
                items.length > 0 && (
                  <div className={styles.overview}>
                    {items.map((item, index) => (
                      <div key={index}>
                        <Icon src={item.icon} />
                        <p>
                          {!item.valueLabel ?
                            item.value
                          : parseValueLabel(item.value, item.valueLabel)}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </Link>
        </div>
      );
};

export function PropertyCardSkeleton() {
  return Array.from({ length: 6 }).map((_, i) => (
    <div className={classNames(styles.card, styles.featured)} key={i}>
      <div className={styles.image}>
        <Skeleton.Image />
      </div>
      <div className={styles.content}>
        <Skeleton.Title />
        <Skeleton.Paragraph lineCount={3} />
      </div>
    </div>
  ));
}

const RequireSignInContext = createContext(null);

const useRequireSignIn = () => useContext(RequireSignInContext);

export const RequireSignInProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const { user } = useUser() ?? { user: null, userData: null };

  async function handleClick(onClick) {
    if (!user) {
      // user not looged in, open login modal
      // setLoading(false);
      setOpen(true);
    } else {
      onClick && onClick();
    }
  }

  return (
    <RequireSignInContext.Provider value={{ open, setOpen, handleClick, user }}>
      {children}
      <FormProvider>
        {!isMobile ?
          <Dialog.Root modal={true} open={open} oOpenChange={setOpen}>
            <Dialog.Portal>
              <Dialog.Overlay
                onClick={() => setOpen(false)}
                className={dialogStyles.dialogOverlay}
              />
              <ToastProvider>
                <Dialog.Content
                  className={classNames(
                    authStyles.container,
                    dialogStyles.dialogContent,
                  )}
                >
                  <SignUpForm setOpen={setOpen} />
                </Dialog.Content>
              </ToastProvider>
            </Dialog.Portal>
          </Dialog.Root>
        : <>
            {
              // todo : improve this, it creates multiple sheets. probably use SheetProvider something at the top level and use hooks to control the sheets
            }
            <Sheet
              key={'sheet'}
              id="sheet"
              tweenConfig={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
              detent={'content-height'}
              snapPoints={[700, 0]}
              isOpen={open}
              onClose={() => setOpen(false)}
            >
              <Sheet.Container>
                {/* <Sheet.Header /> */}
                <Sheet.Content
                  className={classNames(
                    authStyles.container,
                    authStyles.mobile,
                  )}
                >
                  <SignUpForm setOpen={setOpen} />
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop onTap={() => setOpen(false)} />
            </Sheet>
          </>
        }
      </FormProvider>
    </RequireSignInContext.Provider>
  );
};

export function RequireSignInButton({ onClick, children, ...rest }) {
  const { handleClick } = useRequireSignIn();
  return (
    <button onClick={() => handleClick(onClick)} {...rest}>
      {children}
    </button>
  );
}

function SignUpForm({ setOpen }) {
  const [loading, setLoading] = useState(false);
  const { watch, handleSubmit, errors } = useFormContext();
  const { setToast } = useTost();
  const [signUp, setSignUp] = useState(true);

  const PHONE_PREFIX = '+91';
  const phoneNumber = watch('phone');
  const confirmationCode = watch('confirmation');

  const [tabValue, setTabValue] = useState('sign-in');

  async function signInWithGoogleHandler() {
    const { signInWithGooglePopUP } = await import('@/lib/auth');

    try {
      setLoading(true);
      await signInWithGooglePopUP();
      setOpen(false);
    } catch (e) {
      setToast({
        open: true,
        message: parseAuthError(e),
        type: 'error',
      });
      console.error(e);
      setLoading(false);
    }
  }

  async function phoneSignInClickHandler() {
    const { firebaseSignInWithPhoneNumber } = await import('@/lib/auth');

    try {
      setLoading(true);

      console.log('logingIn', PHONE_PREFIX + phoneNumber);

      await firebaseSignInWithPhoneNumber(PHONE_PREFIX + phoneNumber);

      setTabValue('confirmation-code');
    } catch (e) {
      setToast({
        open: true,
        message: parseAuthError(e) ?? 'Something went wrong!',
        type: 'error',
      });

      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function confirmPhoneSignInHandler() {
    const { firebaseConfirmPhoneSignIn } = await import('@/lib/auth');

    try {
      setLoading(true);

      console.log('verfying code', confirmationCode);
      const user = await firebaseConfirmPhoneSignIn(confirmationCode);

      console.log(user);

      setLoading(false);
      setOpen(false);
    } catch (e) {
      setToast({
        open: true,
        message: parseAuthError(e) ?? 'Something went wrong!',
        type: 'error',
      });
      console.error(e);
      setLoading(false);
    }
    // do something
  }

  useEffect(() => setLoading(false), [setLoading]);

  return (
    <>
      <div className={authStyles.header}>
        <div className={authStyles.title}>{signUp ? 'Sign-Up' : 'Log-In'}</div>
        <button onClick={() => setOpen(false)}>
          <Icon src={icons.close} />
        </button>
      </div>
      <div
        style={{
          pointerEvents: loading ? 'none' : 'auto',
        }}
        className={authStyles.content}
      >
        {loading && <Spinner />}
        <Tabs.Root value={tabValue} onValueChange={setTabValue}>
          <Tabs.Content value="sign-in">
            <h2>{signUp ? 'Welcome to Dylan Estate' : 'Welcome Back'}</h2>
            <form
              onSubmit={handleSubmit(() => {
                phoneSignInClickHandler();
              })}
            >
              <div className={authStyles.inputWrapper}>
                <label className={authStyles.label} htmlFor="phone_number">
                  Enter Mobile number
                </label>

                <FormGroup error={errors.phone}>
                  <FormInput
                    className={authStyles.input}
                    solidLabel
                    decoration={{
                      start: PHONE_PREFIX,
                    }}
                    maxLength={10}
                    name="phone"
                    id="phone_number"
                    type="text"
                    validation={{
                      required: 'Phone number is required',
                      pattern: {
                        value: /^\d{10}$/,
                        message: 'Invalid phone number',
                      },
                    }}
                  />
                </FormGroup>
              </div>

              <VerifyButton
                type="submit"
                id="send_verfication_code"
                className={styles.button}
              >
                Continue
              </VerifyButton>
              <p>
                {signUp ?
                  'Already have an account ? '
                : 'Dont have an account ? '}
                <span
                  onClick={() => setSignUp(!signUp)}
                  className={authStyles.link}
                >
                  {signUp ? 'LOGIN' : ' SIGN UP'}
                </span>
              </p>
            </form>
            <span className={authStyles.orSeparator}>Or</span>
            <button
              className={authStyles.thirdPartyProviderButton}
              onClick={signInWithGoogleHandler}
            >
              <Icon src={SocialMeidaIcons.google} />
              <span>Continue with Google</span>
            </button>
          </Tabs.Content>

          <Tabs.Content value="confirmation-code">
            <h2>Welcome Back</h2>
            <form
              onSubmit={handleSubmit(() => {
                confirmPhoneSignInHandler();
              })}
            >
              <div className={authStyles.inputWrapper}>
                <label className={authStyles.label} htmlFor="confirmation_code">
                  Enter OTP sent to{' '}
                  {phoneNumber && formatPhoneNumber(PHONE_PREFIX, phoneNumber)}
                </label>

                <FormInput
                  className={classNames(
                    authStyles.input,
                    authStyles.confirmationInput,
                  )}
                  maxLength={6}
                  name="confirmation"
                  id="confirmation_code"
                  type="text"
                  placeholder="000000"
                />
              </div>
              <button type="submit">Verify</button>
            </form>
          </Tabs.Content>
        </Tabs.Root>
      </div>
      <div className={authStyles.footer}>
        By continuing you agree to our <a>Terms and conditions</a>
      </div>
    </>
  );
}

function VerifyButton({ children, ...rest }) {
  const { setToast } = useTost();
  const phonAuthButtonRef = useRef(null);

  useEffect(() => {
    const buttonId = phonAuthButtonRef.current?.id;
    if (!buttonId) return;

    console.log(buttonId);
    import('@/lib/auth').then(({ RecaptchaVerifier, auth }) => {
      if (window.recaptchaVerifier) return;
      window.recaptchaVerifier = new RecaptchaVerifier(auth, buttonId, {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log('recaptchaVerifier', response);
        },
        expiredCallback: () => {
          setToast({
            open: true,
            message: 'Verification code expired! Please try again.',
            type: 'error',
          });
        },
        errorCallback: (error) => {
          console.log('errorCallback', error);
        },
      });
    });

    return () => {
      window.recaptchaVerifier = null;
    };
  }, [phonAuthButtonRef]);

  return (
    <button ref={phonAuthButtonRef} {...rest}>
      {children}
    </button>
  );
}

function parseAuthError(e) {
  switch (e.code) {
    case 'auth/invalid-verification-code':
      return 'Invalid verification code. Please try again.';
    case 'auth/invalid-phone-number':
      return 'Invalid phone number. Please try again.';
    case 'auth/popup-closed-by-user':
      return 'You have closed the popup window.';
    case 'auth/web-storage-unsupported':
      return 'Web Storage is unsupported in this browser.';
    case 'auth/invalid-credential':
      return 'Invalid credential. Please try again.';
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different sign-in method.';
    case 'auth/network-request-failed':
      return 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.';
    case 'auth/too-many-requests':
      return 'The request has been blocked after too many requests to this endpoint. Try again later.';
    case 'auth/user-disabled':
      return 'The user account has been disabled by an administrator.';
  }

  return 'Something went wrong.';
}

/**
 *
 * @param {{type ?: 'featured', active : boolean}} props
 * @returns
 */
function WishListIcon({ type, active = false }) {
  return (
    <svg
      className={classNames(
        styles[type],
        styles.wishlistIcon,
        active && styles.active,
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 14C20.49 12.54 22 10.79 22 8.5C22 7.04131 21.4205 5.64236 20.3891 4.61091C19.3576 3.57946 17.9587 3 16.5 3C14.74 3 13.5 3.5 12 5C10.5 3.5 9.26 3 7.5 3C6.04131 3 4.64236 3.57946 3.61091 4.61091C2.57946 5.64236 2 7.04131 2 8.5C2 10.8 3.5 12.55 5 14L12 21L19 14Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function formatPhoneNumber(prefix, phoneNumber) {
  //output 999-999-9999
  return (
    prefix +
    ' ' +
    phoneNumber.replace(/\D/g, '').substr(0, 3) +
    '-' +
    phoneNumber.replace(/\D/g, '').substr(3, 3) +
    '-' +
    phoneNumber.replace(/\D/g, '').substr(6, 4)
  );
}

export default PropertyCard;
