import { Suspense } from 'react';

import styles from '@/styles/modules/pages/properties.module.css';

import {
  Await,
  defer,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';

import { Error } from '@/App';
import linvingRoom from '@/assets/images/living-room.png';
import { ConfitteProvider } from '@/components/confitte';
import SearchBar from '@/components/home/search-bar';
import NavBar from '@/components/nav';
import Pagination from '@/components/Pagination';
import PropertyCard, {
  PropertyCardSkeleton,
  PropertyCardTypes,
} from '@/components/preview/preproty-card';
import { getPriceLabel } from '@/data/filters';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
  propertyType,
} from '@/data/preporty-detail';
import propertyFeaturesData, {
  PROPERTY_FEATURES_NAME,
} from '@/data/property-features';
import { PROPERTY_LOCATION_NAME } from '@/data/property-location';
import { PROPERTY_PRICING_NAME } from '@/data/property-pricing';
import { Symbols } from '@/data/symbols';
import { getPropertyPrice } from '@/pages/property-preview';
import {
  NothingFound,
  onPageChangeHandler,
  RenderProperties,
  SortBy,
} from '@/pages/search';

export const loader = async ({ request, params }) => {
  const { getFilteredProperties } = await import('@/lib/db');
  const url = new URL(request.url);

  const searchParams = url.searchParams;

  const properties = await getFilteredProperties(searchParams);
  console.log(properties);
  // const properties = new Promise((res, rej) => {
  //   setTimeout(async () => {
  //     res(await getAllPropertiesWithLimit());
  //   }, 1000);
  // });

  // don't await just return the promise
  return defer({
    properties,
  });
};

export function Component() {
  const { properties } = useLoaderData();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage =
    searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  function onChangeHandler(value) {
    onPageChangeHandler(properties, value, searchParams, setSearchParams);
  }

  return (
    <>
      <NavBar position="relative" type="other" />
      <div className={styles.container}>
        <header>
          <div>
            <SearchBar tabsChildren={<SortBy />} />
          </div>
        </header>

        <Suspense
          fallback={
            <main>
              <PropertyCardSkeleton />
            </main>
          }
        >
          <Await errorElement={<Error />} resolve={properties}>
            {(result) => (
              <>
                <main>
                  {!result.data.length ?
                    <h1>No properties Listed Yet</h1>
                  : <RenderProperties
                      type={PropertyCardTypes.display}
                      properties={result.data}
                    />
                  }
                </main>
                {result.data && result.data.length > 0 && (
                  <Pagination
                    disableLeftArrow={currentPage === 1}
                    disableRightArrow={result.isLastPage}
                    onlyArrows={result.type === 'firestore'}
                    currentPage={result.page}
                    totalPages={result.totalPages}
                    onPageChange={onChangeHandler}
                  />
                )}
              </>
            )}
          </Await>
        </Suspense>
      </div>
    </>
  );
}
export function parseLocation(location) {
  const { sublocality, locality, city } = location;
  return [sublocality, locality, city].filter(Boolean).join(', ');
}

export function parseTitle(data) {
  const {
    [PROPERTY_DETAIL_NAME]: { bhkType, propertyFor, propertyType: pt },
  } = data;

  const labels = {
    bhk: propertyDetailData.bhkType[bhkType][Symbols.Label],
    pt: propertyType[pt],
    for: propertyDetailData.propertyFor[propertyFor],
  };
  return `${labels.bhk} ${labels.pt} for ${labels.for}`;
}
