import { createContext, useContext } from 'react';

const accessorContext = createContext();

export const useAccessor = () =>
  accessorContext ? useContext(accessorContext) : null;

export const AccessorProvider = ({ accessor, children }) => {
  if (!accessor) {
    throw new Error('Accessor is required. e.g. accessor={data.propertyType}');
  }

  return (
    <accessorContext.Provider value={accessor}>
      {children}
    </accessorContext.Provider>
  );
};
