import { amenitiesIcons, overviewIcons } from '@/components/icons';
import { Symbols } from '@/data/symbols';
import { primitiveWithSymbol } from '@/data/utils';

// import { stringWithSymbol } from '@/data/utils';

const propertyFeaturesData = {
  nonVeg: {
    allowed: primitiveWithSymbol(Boolean, true, {
      filterLabel: 'Allows Non Veg',
      label: 'Allowed',
    }),
    notAllowed: primitiveWithSymbol(Boolean, false, {
      label: 'Not Allowed',
    }),
    [Symbols.Name]: 'nonVeg',
    [Symbols.Label]: 'Non-Veg',
    [Symbols.Icon]: overviewIcons.nonVeg,
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  petsAllowed: {
    yes: primitiveWithSymbol(Boolean, true, {
      filterLabel: 'Pet Friendly',
      label: 'Yes',
    }),
    no: primitiveWithSymbol(Boolean, false, {
      label: 'No',
    }),
    // [Symbols.ValueLabel]: (value) => {
    //   return value.valueOf() ? 'Pets Allowed' : 'No Pets Allowed';
    // },
    [Symbols.Name]: 'petsAllowed',
    [Symbols.Label]: 'Pets Allowed',
    [Symbols.Icon]: overviewIcons.pet,
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  electricity: {
    rareOrNoPowerCut: 'Rare / No Power Cut',
    frequentPowerCut: 'Frequent Power Cut',
    [Symbols.Name]: 'electricity',
    [Symbols.Label]: 'Electricity',
    [Symbols.Icon]: overviewIcons.electricityStatus,
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  waterSupply: {
    muncipalCorporation: 'Muncipal Corporation (BMC)',
    borewell: 'Borewell',
    both: 'Both',
    [Symbols.Name]: 'waterSupply',
    [Symbols.Label]: 'Water Supply',
    [Symbols.Icon]: overviewIcons.waterSupply,
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  furnishing: {
    fullyFurnished: 'Fully Furnished',
    semiFurnished: 'Semi Furnished',
    unFurnished: 'Unfurnished',
    [Symbols.Name]: 'furnishing',
    [Symbols.Label]: 'Furnishing',
    [Symbols.Icon]: overviewIcons.furnishing,
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  additionalFeatures: {
    airConditioning: primitiveWithSymbol(String, 'Air Conditioning', {
      // name: 'additionalFeatures.airConditioning',
    }),
    ceilingFan: primitiveWithSymbol(String, 'Ceiling / Fan', {
      // name: 'additionalFeatures.ceilingFan',
    }),
    refrigerator: primitiveWithSymbol(String, 'Refrigerator', {
      // name: 'additionalFeatures.refrigerator',
    }),
    washingMachine: primitiveWithSymbol(String, 'Washing Machine', {
      // name: 'additionalFeatures.washingMachine',
    }),
    microwave: primitiveWithSymbol(String, 'Microwave', {
      // name: 'additionalFeatures.microwave',
    }),
    oven: primitiveWithSymbol(String, 'Oven', {
      // name: 'additionalFeatures.oven',
    }),
    [Symbols.Name]: 'additionalFeatures',
    [Symbols.Label]: 'Additional Features',
    [Symbols.Icon]: overviewIcons.additionalFeatures,
  },

  tiles: {
    normalWhiteTiles: 'Normal White Tiles',
    Marble: 'Marble',
    VirtifiedTiles: 'Virtified Tiles',
    wooden: 'Wooden',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
    [Symbols.Icon]: overviewIcons.flooring,
    [Symbols.Name]: 'tiles',
    [Symbols.Label]: 'Tiles',
  },

  safety: {
    securityPersonel: primitiveWithSymbol(
      String,
      '24/7 Security Personel (Gate Security)',
      {
        // name: 'safety.securityPersonel',
      },
    ),
    securitySystemsCctv: primitiveWithSymbol(
      String,
      'Security Systems - CCTV',
      {
        // name: 'safety.securitySystemsCctv',
      },
    ),
    [Symbols.Name]: 'safety',
    [Symbols.Label]: 'Safety',
  },

  amenities: {
    security: primitiveWithSymbol(String, '24/7 Hour Security', {
      icon: amenitiesIcons.security,
      // name: 'amenities.security',
    }),
    cctv: primitiveWithSymbol(String, 'CCTV Cameras', {
      icon: amenitiesIcons.cctv,
      // name: 'amenities.cctv',
    }),
    lift: primitiveWithSymbol(String, 'Lift', {
      icon: amenitiesIcons.lift,
      // name: 'amenities.lift',
    }),
    reservedParking: primitiveWithSymbol(String, 'Reserverd Parking', {
      icon: amenitiesIcons.reverseParking,
      // name: 'amenities.reservedParking',
    }),
    regularWaterSupply: primitiveWithSymbol(String, 'Regular Water Supply', {
      icon: amenitiesIcons.waterSupply,
      // name: 'amenities.regularWaterSupply',
    }),
    powerBackupPartial: primitiveWithSymbol(String, 'Power Backup - Partial', {
      icon: amenitiesIcons.powerBackup,
      // name: 'amenities.powerBackupPartial',
    }),
    powerBackupFull: primitiveWithSymbol(String, 'Power Backup - Full', {
      icon: amenitiesIcons.powerBackup,
      // name: 'amenities.powerBackupFull',
    }),
    maintenanceStaff: primitiveWithSymbol(String, 'Maintenance Staff', {
      icon: amenitiesIcons.maintenceStaff,
      // name: 'amenities.maintenanceStaff',
    }),
    gardenOrPark: primitiveWithSymbol(String, 'Garden / Park', {
      icon: amenitiesIcons.park,
      // name: 'amenities.gardenOrPark',
    }),
    kidsPlayArea: primitiveWithSymbol(String, 'Kids Play Area', {
      icon: amenitiesIcons.playArea,
      // name: 'amenities.kidsPlayArea',
    }),
    sport: primitiveWithSymbol(String, 'Sport', {
      icon: amenitiesIcons.sport,
      // name: 'amenities.sport',
    }),
    propertyGym: primitiveWithSymbol(String, 'Property Gym', {
      icon: amenitiesIcons.gym,
      // name: 'amenities.propertyGym',
    }),
    communityHall: primitiveWithSymbol(String, 'Community Hall', {
      icon: amenitiesIcons.communityHall,
      // name: 'amenities.communityHall',
    }),
    shoppingCenter: primitiveWithSymbol(String, 'Shopping Center', {
      icon: amenitiesIcons.shoppingCenter,
      // name: 'amenities.shoppingCenter',
    }),
    clubHouse: primitiveWithSymbol(String, 'Club House', {
      icon: amenitiesIcons.clubHouse,
      // name: 'amenities.clubHouse',
    }),
    swimingPool: primitiveWithSymbol(String, 'Swimming Pool', {
      icon: amenitiesIcons.swimingPool,
      // name: 'amenities.swimingPool',
    }),
    intercon: primitiveWithSymbol(String, 'Intercon', {
      icon: amenitiesIcons.intercon,
      // name: 'amenities.intercon',
    }),
    fireSafety: primitiveWithSymbol(String, 'Fire Safety', {
      icon: amenitiesIcons.fireSafety,
      // name: 'amenities.fireSafety',
    }),
    // MaintenanceStaff: primitiveWithSymbol(String, 'Maintenance Staff', {
    //   icon: amenitiesIcons.maintenceStaff,
    // }),
    // gardenOrPark: primitiveWithSymbol(String, 'Garden / Park', {
    //   icon: amenitiesIcons.park,
    // }),
    // kidsPlayArea: primitiveWithSymbol(String, 'Kids Play Area', {
    //   icon: amenitiesIcons.playArea,
    // }),
    // sport: primitiveWithSymbol(String, 'Sport', {
    //   icon: amenitiesIcons.sport,
    // }),
    // propertyGym: primitiveWithSymbol(String, 'Property Gym', {
    //   icon: amenitiesIcons.gym,
    // }),
    // communityHall: primitiveWithSymbol(String, 'Community Hall', {
    //   icon: amenitiesIcons.communityHall,
    // }),
    // shoppingCenter: primitiveWithSymbol(String, 'Shopping Center', {
    //   icon: amenitiesIcons.shoppingCenter,
    // }),
    // clubHouse: primitiveWithSymbol(String, 'Club House', {
    //   icon: amenitiesIcons.clubHouse,
    // }),
    // swimingPool: primitiveWithSymbol(String, 'Swiming Pool', {
    //   icon: amenitiesIcons.swimingPool,
    // }),
    // intercon: primitiveWithSymbol(String, 'Intercon', {
    //   icon: amenitiesIcons.intercon,
    // }),
    // fireSafety: primitiveWithSymbol(String, 'Fire Safety', {
    //   icon: amenitiesIcons.fireSafety,
    // }),

    [Symbols.Name]: 'amenities',
    [Symbols.Label]: 'Amenities',
  },
};

export const PROPERTY_FEATURES_NAME = 'property_features';

export default propertyFeaturesData;
