import styles from '@/styles/modules/about.module.css';

import Container from '@/components/container';
import ContainerBox from '@/components/container-box';
import Description from '@/components/preview/description';

const AboutLocation = ({ location, description }) => {
  return (
    <Container className={styles.container}>
      <ContainerBox type="border" title={'About ' + location}>
        <div>
          <Description text={description} />
        </div>
      </ContainerBox>
    </Container>
  );
};

export default AboutLocation;
