import { createContext, useContext } from 'react';

import { useForm } from 'react-hook-form';

const formProvider = createContext();

export const useFormContext = () => useContext(formProvider);

const FormProvider = ({ id, options, children }) => {
  const {
    formState,
    formState: { errors },
    ...methods
  } = useForm({ ...options, mode: 'onChange', shouldFocusError: true });

  return (
    <formProvider.Provider
      value={{
        errors,
        ...methods,
      }}
    >
      {children}
    </formProvider.Provider>
  );
};

export default FormProvider;
