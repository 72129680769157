import '@brainhubeu/react-carousel/lib/style.css';

import styles from '@/styles/modules/image-carasoul.module.css';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React from 'react';

import classNames from 'classnames';
// import Carousel from 'react-multi-carousel';

// import Carousel, {
//   arrowsPlugin,
//   autoplayPlugin,
// } from '@brainhubeu/react-carousel';
// import 'react-multi-carousel/lib/styles.css';

import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import { Icon, icons } from '@/components/icons';
import { usePropertyActions } from '@/components/preview/preproty-card';
import { Actions } from '@/components/preview/property-heading';
import Skeleton from '@/components/skeleton';
import { useTost } from '@/components/toast';
import { NoImage } from '@/pages/no-image';
import { Mobile } from '@/pages/property-preview';

const ImageCarousel = ({ images, id }) => {
  const { setToast } = useTost();
  const {
    itemNotInterested,
    undoNotInterestedClickHandler,
    notIntrestedClickHandler,
  } = usePropertyActions(id);

  const [searchParams] = useSearchParams();

  const propertyId = searchParams.get('id');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  };

  return (
    <div className={styles.container}>
      <Mobile className={styles.mobileActionsContainer}>
        <Actions slots={['heart']} id={id} className={styles.mobileActions} />
      </Mobile>
      <div className={styles.imageArea}>
        {images && images.length > 0 ?
          <Slider
            {...settings}
            arrows={images && images.length > 1}
            dots={false}
            className={styles.carouselContainer}
          >
            <div className={styles.imageContainer}>
              {images.map((image, index) => (
                <React.Fragment key={index}>
                  <picture>
                    <source
                      key={index}
                      srcSet={generateSrcSet(image)}
                      type={getImageMineType(image.name)}
                    />

                    <img
                      loading={index === 0 ? 'eager' : 'lazy'}
                      src={image.images[2].downloadURL}
                      alt="image"
                    />
                  </picture>
                  <picture className={styles.backdrop}>
                    <source
                      key={index}
                      srcSet={generateSrcSet(image)}
                      type={getImageMineType(image.name)}
                    />

                    <img
                      loading={'lazy'}
                      src={image.images[0].downloadURL}
                      alt="image"
                    />
                  </picture>
                </React.Fragment>
              ))}
            </div>
          </Slider>
        : <NoImage />}
        {/* <PhotoUploader /> */}
      </div>

      <div className={styles.propertyInfo}>
        <span>Property ID : {propertyId}</span>
        <button
          onClick={() => {
            try {
              itemNotInterested ?
                undoNotInterestedClickHandler()
              : notIntrestedClickHandler();
              if (!itemNotInterested)
                setToast({
                  open: true,
                  message: 'Property added to not interested list',
                  type: 'success',
                });
              else
                setToast({
                  open: true,
                  message: 'Property removed from not interested list',
                  type: 'success',
                });
            } catch (e) {
              console.error(e);

              setToast({
                open: true,
                message: e.message,
                type: 'error',
              });
            }
          }}
          className={classNames(styles.rejectButton, styles.mobile)}
        >
          {itemNotInterested ?
            <>
              Unreject this property <Icon src={icons.reject} />
            </>
          : <>
              Reject this property <Icon src={icons.reject} />
            </>
          }
        </button>
      </div>
    </div>
  );
};

const CustomLeftArrow = ({ style, onClick }) => {
  return (
    <button style={{ ...style }} onClick={onClick} className={styles.arrowLeft}>
      <Icon src={icons.leftArrow} />
    </button>
  );
};

const CustomRightArrow = ({ style, onClick }) => {
  return (
    <button
      style={{ ...style }}
      onClick={onClick}
      className={styles.arrowRight}
    >
      <Icon
        style={{
          transform: 'rotate(180deg)',
        }}
        src={icons.leftArrow}
      />
    </button>
  );
};

export function ImagesSkeletonLoading() {
  return (
    <div
      style={{
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
      }}
      className={styles.container}
    >
      <Skeleton.Image />
    </div>
  );
}

function generateSrcSet(image) {
  const images = image.images;
  const widths = [300, 600, 900];
  return images
    .map((image, index) => {
      return `${image.downloadURL} ${widths[index]}w`;
    })
    .join(', ');
}

function getImageMineType(fileName) {
  const ext = fileName.split('.')[1];
  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'webp':
      return 'image/webp';
    default:
      return 'image/jpeg';
  }
}

export default ImageCarousel;
