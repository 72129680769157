import React, { useState } from 'react';

import styles from '@/styles/modules/review-form.module.css';

import { AccessorProvider } from '@/components/accessor';
import { FormStack } from '@/components/form';
import FormGroup, { FormGroupConfig } from '@/components/form/group';
import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import RadioInput from '@/components/form/radio';
import { useModal } from '@/components/Modal';
import { useTost } from '@/components/toast';
import { useUser } from '@/contexts/auth-context';
import { propertyReviewData } from '@/data/property-review';
import { getName } from '@/data/utils';

const PropertyReviewForm = ({ ...props }) => {
  const { user } = useUser();

  return (
    <div className={styles.container}>
      <FormProvider
        options={{
          defaultValues: {
            [getName(propertyReviewData.name)]: user.name ?? '',
            [getName(propertyReviewData.email)]: user?.email ?? '',
            [getName(propertyReviewData.lived)]: null,
            [getName(propertyReviewData.stars)]: undefined,
          },
        }}
      >
        <FormGroupConfig gap={2}>
          <Form {...props} />
        </FormGroupConfig>
      </FormProvider>
    </div>
  );
};

function Form({ propertyDetails, onSubmit: onSubmitHandler }) {
  const { setOpen } = useModal();

  const { user } = useUser();

  const { setToast } = useTost();

  const { handleSubmit, watch, setValue } = useFormContext();

  const onSubmit = async (data) => {
    console.log(data);

    const { postReview } = await import('@/lib/actions');

    if (!data.stars) {
      setToast({
        open: true,
        type: 'error',
        message: 'Please rate the property',
      });

      return;
    }

    try {
      const res = await postReview({
        ...data,
        propertyId: propertyDetails.id,
        createdBy: user?.id ?? null,
      });

      setToast({
        open: true,
        type: 'success',
        message: 'Property Review Submitted Successfully',
      });

      onSubmitHandler(res);
      setOpen(false);
    } catch (e) {
      console.error(e);
      setToast({
        open: true,
        type: 'error',
        message: 'Property Review submission failed',
      });
    }
  };

  const rating = watch(getName(propertyReviewData.stars));

  const handleStarClick = (index) => {
    setValue(getName(propertyReviewData.stars), index + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AccessorProvider accessor={propertyReviewData.stars}>
        <FormGroup showLabel={false}>
          <div className={styles.stars}>
            {[...Array(5)].map((_, index) => (
              <Star
                key={index}
                className={`${styles.star} ${index < rating ? styles.filled : ''}`}
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
        </FormGroup>
      </AccessorProvider>
      <AccessorProvider accessor={propertyReviewData.lived}>
        <FormGroup showLabel={false}>
          <RadioInput />
        </FormGroup>
      </AccessorProvider>
      <AccessorProvider accessor={propertyReviewData.review}>
        <FormGroup showLabel={false}>
          <FormInput
            style={{ maxHeight: '200px' }}
            type="textarea"
            placeholder="Your comments / reviews"
          />
        </FormGroup>
      </AccessorProvider>
      <FormStack>
        <AccessorProvider accessor={propertyReviewData.name}>
          <FormGroup>
            <FormInput placeholder="Name" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={propertyReviewData.email}>
          <FormGroup>
            <FormInput placeholder="Email" />
          </FormGroup>
        </AccessorProvider>
      </FormStack>
      <p className={styles.disclaimer}>
        Your email address will not be published
      </p>
      <button type="submit" className={styles.submitButton}>
        Share Review
      </button>
    </form>
  );
}

const Star = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={29}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 1.666 4.12 8.347 9.213 1.346-6.667 6.494 1.573 9.173L15 22.693l-8.24 4.333 1.574-9.173-6.667-6.494 9.213-1.346L15 1.666Z"
    />
  </svg>
);

export default PropertyReviewForm;
