import styles from '@/styles/modules/footer.module.css';

import { Link } from 'react-router-dom';

import { Icon, icons } from '@/components/icons';
import Logo, { LogoSizes, NavBarTypes } from '@/components/logo';
import { PATHS } from '@/constants/paths';
import { useProfile } from '@/contexts/profile-context';

const Links = {
  col1: [
    { name: 'HOME', href: PATHS.HOME },
    { name: 'PROPERTIES', href: PATHS.PROPERTIES },
    { name: 'LIST YOUR PROPERTY', href: PATHS.PROPERTY_LISTING },
    { name: 'SAVED SEARCHES', href: PATHS.ACTIVITY.SEARCHES },
  ],
  col2: [
    { name: 'ABOUT MIRA ROAD', href: PATHS.ABOUT_US },
    { name: 'EMI CALCULATOR', href: PATHS.EMI_CALCULATOR },
    { name: 'TESTAMONIALS', href: PATHS.TESTAMONIALS },
    // { name: 'EXPLORE NEIGHBORHOOD', href: PATHS.EXPLORE_NEIGHBORHOOD },
  ],
  col3: [
    { name: 'ABOUT US', href: PATHS.ABOUT_US },
    { name: 'CONTACT US', href: PATHS.CONTACT_US },
    { name: 'FAQ', href: PATHS.FAQ },
  ],
};

const Footer = () => {
  const { formatedProfile } = useProfile();

  const { address, officeDays, officeHours } = formatedProfile();

  return (
    <footer className={styles.footer}>
      {/* <Container> */}
      <div className={styles.container}>
        <div className={styles.logoSection}>
          {/* <img src={logoIcon} alt="Dylan Estate Logo" /> */}
          <Logo size={LogoSizes.Large} type={NavBarTypes.ForDarkBackground} />
        </div>
        <p className={styles.mobile}> Your Neighborhood Experts </p>
        <nav className={styles.navigation}>
          <div className={styles.navColumn}>
            {Links.col1.map((link) => (
              <Link key={link.name} to={link.href}>
                {link.name}
              </Link>
            ))}
          </div>
          <div className={styles.navColumn}>
            {Links.col2.map((link) => (
              <Link key={link.name} to={link.href}>
                {link.name}
              </Link>
            ))}
          </div>
          <div className={styles.navColumn}>
            {Links.col3.map((link) => (
              <Link key={link.name} to={link.href}>
                {link.name}
              </Link>
            ))}
          </div>
        </nav>
        <div className={styles.contactInfo}>
          <div>
            <h3>CONTACT US</h3>
            <p>
              {address}
              {/* B/28, Shop No 1, Madhukar Co Op Housing Society, Sector 5, Shanti
              Nagar, Jain Mandir Road, Mira Road East, Thane - 401107 (Near Jain
              Mandir) */}
            </p>
          </div>
          <div>
            <h3>OFFICE HOURS</h3>
            <p>
              {officeDays}, {officeHours}
              {/* Mon - Fri: 9:00 AM - 5:00 PM */}
              {/* Sat - Sun: 9:00 AM - 9:00 PM */}
            </p>
          </div>
        </div>
      </div>
      {/* </Container> */}
      <div className={styles.bottomBar}>
        {/* <Container> */}

        <div>
          <p>Copyright © 2024 Dylan Estates. All rights reserved.</p>
          <div className={styles.links}>
            <a href="/privacy-policy">Privacy Policy</a>
            <span className={styles.divider}>|</span>
            <a href="/terms">Terms & Conditions</a>
          </div>
        </div>

        <div className={styles.language}>
          <a>
            <span className={styles.globeIcon}>
              <Icon src={icons.grid} />
            </span>
            <span>English (IN)</span>
          </a>
          <a>
            <span className={styles.currencySymbol}>
              <Icon src={icons.ruppee} />
            </span>
            <span>INR</span>
          </a>
        </div>

        {/* </Container> */}
      </div>
    </footer>
  );
};

export default Footer;
