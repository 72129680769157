import React, { useEffect, useState } from 'react';

import styles from '@/styles/modules/pages/dashboard.module.css';

import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import { IN_LOCALE_CODE } from '@/components/filters/price-range-filter';
import {
  AccordionContent,
  AccordionTrigger,
} from '@/components/filters/property-type-filter';
import { Icon, icons } from '@/components/icons';
import { formatDate, formatTime } from '@/components/misc/visit-scheduling';
import NavBar from '@/components/nav';
import Skeleton from '@/components/skeleton';
import { useTost } from '@/components/toast';
import { PATHS } from '@/constants/paths';
import { useUser } from '@/contexts/auth-context';
import { useProfile } from '@/contexts/profile-context';
import { formattedSearchString } from '@/data/filters';
import { ScheduleVisit } from '@/data/misc';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
} from '@/data/preporty-detail';
import propertyPricingData, {
  PROPERTY_PRICING_NAME,
} from '@/data/property-pricing';
import { getName } from '@/data/utils';
import { getUser } from '@/lib/auth';
import {
  getPropertyLocation,
  getPropertyPrice,
  getPropertyTitle,
  runBackgroundTask,
} from '@/pages/property-preview';

export const Component = () => {
  return (
    <>
      <NavBar position="relative" type="other" />
      <main className={styles.mainContainer}>
        <SideBar />
        <Dashboard />
      </main>
    </>
  );
};

function Content({ title, children }) {
  return (
    <section className={styles.content}>
      {title && <h2>{title}</h2>}
      <div className={styles.contentContainer}>{children}</div>
    </section>
  );
}

// Dashboard component
const Dashboard = () => (
  <Content>
    <Outlet />
  </Content>
);

// Sidebar component
const SideBar = () => (
  <aside className={styles.sidebar}>
    <NavLink
      to={PATHS.LISTINGS}
      className={({ isActive }) =>
        classNames(styles.menuButton, isActive && styles.active)
      }
    >
      MY PROPERTY LISTINGS
    </NavLink>
    <NavLink
      to={PATHS.ACTIVITY.VISITS}
      className={({ isActive }) =>
        classNames(styles.menuButton, isActive && styles.active)
      }
    >
      MY ACTIVITY
    </NavLink>
    <nav className={styles.subMenu}>
      <ul>
        {[
          { name: 'Property Visits', path: PATHS.ACTIVITY.VISITS },
          { name: 'Inquiries sent', path: PATHS.ACTIVITY.INQUIRIES },
          { name: 'Shortlisted Properties', path: PATHS.ACTIVITY.SHORTLISTED },
          { name: 'Saved Searches', path: PATHS.ACTIVITY.SEARCHES },
        ].map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.path}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
    <Accordion.Root
      className={styles.accordionRoot}
      type="single"
      collapsible={true}
      defaultValue={
        window.location.pathname.includes(PATHS.ACTIVITY.NOT_INTERESTED) ?
          'item-1'
        : ''
      }
    >
      <Accordion.Item className={styles.accordionItem} value="item-1">
        <AccordionTrigger className={styles.menuButton}>MORE</AccordionTrigger>
        <AccordionContent className={styles.accordionContent}>
          <nav className={styles.subMenu}>
            <ul>
              <li>
                <NavLink
                  to={PATHS.ACTIVITY.NOT_INTERESTED}
                  className={({ isActive }) => (isActive ? styles.active : '')}
                >
                  Not Interested Properties
                </NavLink>
              </li>
            </ul>
          </nav>
        </AccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  </aside>
);

function UserNotFoundMessage() {
  return (
    <div className={styles.nothingFound}>
      <h3>User not logged in</h3>
    </div>
  );
}

// Property Inquiries component
export const PropertyInquiries = () => {
  const { user } = useUser();

  const [loading, setLoading] = useState(true);

  const [inqueries, setInqueries] = useState(null);

  useEffect(() => {
    if (!user) return;

    runBackgroundTask(async () => {
      const { getInqueries } = await import('@/lib/db');

      const searchParams = new URLSearchParams();
      searchParams.set('type', 'properties');
      searchParams.set('createdBy', user.uid);

      const data = await getInqueries(searchParams, {
        limit: 20,
      });

      console.log('data', data);

      setInqueries(data);

      setLoading(false);
    });
  }, [user]);

  if (user === null) return <UserNotFoundMessage />;

  if (loading) return <LoadingSkeleton title="Property Inquiries Sent" />;

  return (
    <>
      <h2>Property Inquiries Sent</h2>
      <div className={styles.propertyInquiries}>
        {inqueries?.data && inqueries.data.length > 0 ?
          inqueries.data.map(({ data: inquiry, id }, index) => (
            <React.Fragment key={id}>
              <div className={styles.inquiryItem}>
                <h3>
                  {inquiry.timestamp
                    .toDate()
                    .toLocaleDateString(IN_LOCALE_CODE, {
                      day: 'numeric',
                      month: 'short',
                      year: '2-digit',
                    })}
                </h3>
                <div className={styles.inquiryItemContent}>
                  <p>{inquiry.context.propertyTitle}</p>
                  <p className={styles.location}>
                    <Icon src={icons.location} />
                    {inquiry.context.propertyLocation}
                  </p>
                </div>
              </div>
              {index !== inqueries.data.length - 1 && (
                <div className={styles.hr} />
              )}
            </React.Fragment>
          ))
        : <NothingFoundMessage
            message="You have no inquries."
            buttonText={'Quick Inquiry'}
            buttonLink={PATHS.CONTACT_US}
          />
        }
      </div>
    </>
  );
};

// Property Visits component
export const PropertyVisits = () => {
  const { user, scheduledVisits, setScheduledVisits } = useUser();
  const [activeVisitTab, setActiveVisitTab] = useState('scheduled');

  const { setToast } = useTost();

  const [loading, setLoading] = useState(true);

  const [visits, setVisits] = useState({});

  const { profile } = useProfile();

  useEffect(() => {
    if (!user) return;

    runBackgroundTask(async () => {
      const { getScheduledVisits } = await import('@/lib/db');

      const data = await getScheduledVisits(user.uid);

      console.log('data', data);

      setScheduledVisits(data);
      //   group data by status 'scheduled', 'pending', 'past'

      setLoading(false);
    });
  }, [user]);

  useEffect(() => {
    if (!scheduledVisits || scheduledVisits.length === 0) return;

    const groupedData = scheduledVisits.reduce((acc, curr) => {
      const status = curr.status;
      acc[status] = acc[status] || [];
      acc[status].push(curr);
      return acc;
    }, {});

    console.log('groupedData', groupedData);

    setVisits(groupedData);
  }, [scheduledVisits]);

  if (user === null) return <UserNotFoundMessage />;

  if (loading || !visits) return <LoadingSkeleton title={'Property Visits'} />;

  return (
    <>
      <h2>Property Visits</h2>
      <div className={styles.propertyVisits}>
        {Object.keys(visits).length > 0 ?
          <>
            <div className={styles.tabButtons}>
              {['scheduled', 'pending', 'past'].map((tab) => (
                <button
                  key={tab}
                  className={activeVisitTab === tab ? styles.activeTab : ''}
                  onClick={() => setActiveVisitTab(tab)}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>
            {activeVisitTab === 'scheduled' &&
              (visits.confirmed && visits.confirmed.length > 0 ?
                visits.confirmed.map((visit, index) => (
                  <div key={visit.id} className={styles.visitCard}>
                    <div className={styles.visitItem}>
                      <h6>Visit Scheduled for:</h6>
                      <div>
                        <p className={styles.title}>
                          <Icon src={icons.calendar} />
                          {formatDate(
                            visit[getName(ScheduleVisit.visitDate)].toDate(),
                          )}
                        </p>
                        <p className={styles.title}>
                          <Icon src={icons.clock} />
                          <span>
                            {formatTime(
                              visit[getName(ScheduleVisit.visitTime)].toDate(),
                            )}
                          </span>
                        </p>
                        {/* <a href="#">Change</a> */}
                      </div>
                    </div>
                    <div>
                      <h6>Visit at Property:</h6>
                      <p className={styles.title}>
                        {visit.context.propertyTitle}
                      </p>
                      <p className={styles.location}>
                        <Icon src={icons.location} />
                        {visit.context.propertyLocation}
                      </p>
                    </div>
                    <div>
                      <h6>Visit the property with:</h6>
                      <p className={styles.title}>{profile.name}</p>
                    </div>

                    {/* <button className={styles.primary}>CANCEL VISIT</button> */}
                  </div>
                ))
              : <h1>No scheduled Visits</h1>)}
            {activeVisitTab === 'pending' &&
              (visits.pending && visits.pending.length > 0 ?
                <>
                  <div className={styles.pendingStatus}>
                    <p>VISIT STATUS- PENDING</p>
                    <p>
                      We will get in touch with you soon to confirm your visit.
                    </p>
                  </div>
                  {visits.pending.map((visit) => (
                    <VisitCard visit={visit} key={visit.id} />
                  ))}
                </>
              : <h1>No pending visits</h1>)}

            {activeVisitTab === 'past' && (
              <div className={styles.pastVisitCardContainer}>
                {visits.past && visits.past.length > 0 ?
                  visits.past.map((visit, index) => (
                    <div key={index} className={styles.pastVisitCard}>
                      <div className={styles.visitItem}>
                        <h6>Visit Scheduled for:</h6>
                        <div>
                          <p className={styles.title}>
                            <Icon src={icons.calendar} />
                            {visits.scheduled[0].date}
                          </p>
                          <p className={styles.title}>
                            <Icon src={icons.clock} />
                            <span>{visits.scheduled[0].time}</span>
                          </p>
                          <a className={styles.detail} href="#">
                            details
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : <h1>No past Visits</h1>}
              </div>
            )}
          </>
        : <NothingFoundMessage
            message="You do not have any visits scheduled with us. "
            buttonText="View all Properties"
            buttonLink={PATHS.PROPERTIES}
          />
        }
      </div>
    </>
  );
};

function NothingFoundMessage({ message, buttonText, buttonLink }) {
  const navigate = useNavigate();

  return (
    <div className={styles.nothingFound}>
      <h3>{message}</h3>
      {buttonText && (
        <button
          onClick={() => {
            buttonLink && navigate(buttonLink);
          }}
          className={styles.primary}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
}

function VisitCard({ visit }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      key={visit.id}
      className={classNames(styles.visitCard, styles.pending)}
    >
      <div className={styles.visitItem}>
        <h6>Visit Scheduled for:</h6>
        <div>
          <p className={styles.title}>
            <Icon src={icons.calendar} />
            {formatDate(visit[getName(ScheduleVisit.visitDate)].toDate())}
          </p>
          <p className={styles.title}>
            <Icon src={icons.clock} />
            <span>
              {formatTime(visit[getName(ScheduleVisit.visitTime)].toDate())}
            </span>
          </p>
          <a
            onClick={() => {
              setOpen((prev) => !prev);
            }}
            className={styles.detail}
          >
            {open ? 'Hide details' : 'Details'}
          </a>
        </div>
      </div>
      {open && (
        <div>
          <h6>Visit at Property:</h6>
          <p className={styles.title}>{visit.context.propertyTitle}</p>
          <p className={styles.location}>
            <Icon src={icons.location} />
            {visit.context.propertyLocation}
          </p>
        </div>
      )}
      <button
        // onClick={() => handleDelete(visits.pending[0].id)}
        className={styles.primary}
      >
        CANCEL VISIT REQUEST
      </button>
    </div>
  );
}
// Saved Searches component
export const SavedSearches = () => {
  const { user, userData } = useUser();

  let searches = userData?.savedSearches;

  searches =
    searches &&
    Object.entries(searches)
      .sort((a, b) => b[1].time?.toDate() - a[1].time?.toDate())
      .map(([key, value]) => ({
        date: value.time.toDate().toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        description: key,
      }));

  return (
    user === undefined ? <LoadingSkeleton title="Saved Searches" />
    : user === null ? <UserNotFoundMessage />
    : !searches || Object.keys(searches).length === 0 ? <NoSavedSearches />
    : <>
        <h2>Saved Searches</h2>
        <div className={styles.savedSearches}>
          {searches.map((search) => (
            <SavedSearchCard key={search} search={search} />
          ))}
        </div>
      </>
  );
};

function NoSavedSearches() {
  return (
    <>
      <h2>Saved Searches</h2>
      <NothingFoundMessage message="You do not have any saved searches" />
    </>
  );
}

function SavedSearchCard({ search }) {
  const [open, setOpen] = useState(false);

  const { user, setUserData } = useUser();

  const { setToast } = useTost();

  const navigate = useNavigate();

  const formattedSearch = formattedSearchString(search.description);

  const MAX_LENGTH = 100;

  const needsTriming = formattedSearch.length > MAX_LENGTH;

  const desc =
    needsTriming ?
      formattedSearch.slice(0, MAX_LENGTH) + '...'
    : formattedSearch;

  function setDeleted(key) {
    setUserData((prev) => {
      const newData = { ...prev };

      delete newData.savedSearches[key];

      return newData;
    });
  }

  async function deleteSearchHandler(search) {
    const { removeSearch } = await import('@/lib/actions');

    const userId = user.uid;

    try {
      setDeleted(search);
      await removeSearch(userId, search);
    } catch (e) {
      console.error(e);
      setToast({
        open: true,
        message: 'Unable to remove search',
        type: 'error',
      });
    }
  }

  return (
    <div key={search.description} className={styles.searchCard}>
      <p className={styles.date}>{search.date}</p>
      <div className={styles.description}>
        <p>{open ? formattedSearch : desc}</p>
        {needsTriming && (
          <a href="#" onClick={() => setOpen((prev) => !prev)}>
            {open ? 'See Less' : 'See More'}
          </a>
        )}
      </div>
      <div className={styles.buttons}>
        <button
          onClick={() => {
            navigate(PATHS.SEARCH + '?' + search.description);
          }}
          className={styles.primary}
        >
          RUN SEARCH
        </button>
        <button
          onClick={() => {
            deleteSearchHandler(search.description);
          }}
          className={styles.secondary}
        >
          <Icon src={icons.delete} />
        </button>
      </div>
    </div>
  );
}

function LoadingSkeleton({ title }) {
  return (
    <>
      <h2>{title}</h2>
      <div className={styles.savedSearches}>
        {Array.from({ length: 5 }).map((_, i) => (
          <div
            key={i}
            style={{ width: '100%' }}
            className={classNames(styles.searchCard, styles.skeleton)}
          >
            <Skeleton.Span />
            <div style={{ width: '100%' }}>
              <Skeleton.Paragraph lineCount={3} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

// Property Listings component
export const PropertyListings = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [listings, setListings] = useState(null);

  const { user } = useUser();
  const { setToast } = useTost();

  useEffect(() => {
    if (user === null) {
      setLoading(false);
      return;
    }

    if (!user) return;

    setLoading(true);

    runBackgroundTask(async () => {
      try {
        const { getUserPropertyListings } = await import('@/lib/db');

        const data = await getUserPropertyListings(user.uid);

        console.log('God data: ', data);

        setListings(data);
      } catch (e) {
        setToast({
          open: true,
          message: e.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    });
  }, [user]);

  async function handleDelete(docId) {
    const confirm = window.confirm(
      'Are you sure you want to delete this property listing?',
    );

    if (!confirm) return;

    const { removePropertyListing } = await import('@/lib/db');

    try {
      await removePropertyListing(docId);
      setToast({
        open: true,
        message: 'Property listing deleted successfully',
        type: 'success',
      });

      setListings(listings.filter((listing) => listing.id !== docId));
    } catch (e) {
      setToast({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  }

  if (user === undefined || loading)
    return <LoadingSkeleton title="My Property Listings" />;

  if (user === null) return <UserNotFoundMessage />;

  return (
    <>
      <h2>My Property Listings</h2>
      <div className={styles.propertyListings}>
        {/* <div className={styles.tabButtons}> */}
        {/* <button className={styles.activeTab}>PUBLISHED</button>  */}
        {/* <button>DRAFTS</button> */}
        {/* </div> */}
      </div>
      {Array.isArray(listings) && listings.length > 0 ?
        <table className={styles.listingsTable}>
          <thead>
            <tr>
              <th className={styles.first}>Property Type</th>
              <th>Posted on</th>
              <th>Rent /Sale</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listings.map((data, index) => (
              <>
                <tr key={index}>
                  <td>
                    <div className={styles.tdBox}>
                      <p className={styles.title}>
                        {getPropertyTitle(data, [
                          'bhk',
                          'locality',
                          'area',
                          'for',
                        ])}
                      </p>

                      <p className={styles.location}>
                        <Icon src={icons.location} />
                        {getPropertyLocation(data, 'short')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        {data.timestamp.toDate().toLocaleDateString(undefined, {
                          day: 'numeric',
                          month: 'short',
                        })}{' '}
                      </p>

                      <p>
                        {data.timestamp.toDate().toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        For{' '}
                        {
                          propertyDetailData.propertyFor[
                            data[PROPERTY_DETAIL_NAME][
                              getName(propertyDetailData.propertyFor)
                            ]
                          ]
                        }
                      </p>
                      <p>₹{getPropertyPrice(data)}</p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      {'Open'}
                    </div>
                  </td>
                  <td>
                    <div
                      className={classNames(
                        styles.features,
                        styles.tdBox,
                        styles.flex,
                      )}
                    >
                      <span>
                        {data?.featured ? 'Featured' : 'Not Featured'}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <div className={styles.actions}>
                      <button
                        onClick={() =>
                          navigate(
                            PATHS.PROPERTY_LISTING +
                              '?mode=edit' +
                              '&id=' +
                              data.id,
                          )
                        }
                      >
                        EDIT
                        <Icon src={icons.edit} />
                      </button>
                      <button onClick={() => handleDelete(data.id)}>
                        DELETE
                        <Icon src={icons.deleteSmall} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr className={styles.spacer}>
                  <td colSpan={5}></td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      : <NothingFoundMessage
          message="You do not have any listed Property"
          buttonText="List your property"
          buttonLink={PATHS.PROPERTY_LISTING}
        />
      }
    </>
  );
};

export const ShortlistedProperties = () => {
  const [loading, setLoading] = useState(true);

  const [listings, setListings] = useState(null);

  const { user, userData } = useUser();
  const { setToast } = useTost();

  useEffect(() => {
    if (user === null) {
      setLoading(false);
      return;
    }

    if (!user || !userData || !userData?.wishlist) return;

    setLoading(true);

    runBackgroundTask(async () => {
      try {
        const { getUserShortlistedProperties } = await import('@/lib/db');

        const data = await getUserShortlistedProperties(userData.wishlist);

        setListings(data);
      } catch (e) {
        setToast({
          open: true,
          message: e.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    });
  }, [user, userData]);

  async function handleUndo(docId) {
    const { removePropertyFromUserWishList } = await import('@/lib/actions');

    try {
      await removePropertyFromUserWishList(user.uid, docId);
      setToast({
        open: true,
        message: 'Property removed from wishlist successfully',
        type: 'success',
      });

      setListings(listings.filter((listing) => listing.id !== docId));
    } catch (e) {
      setToast({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  }

  if (user === undefined || loading)
    return <LoadingSkeleton title="My Shortlisted Properties" />;

  if (user === null) return <UserNotFoundMessage />;

  return (
    <>
      <h2>My Shortlisted Properties</h2>

      {Array.isArray(userData?.wishlist) && listings.length > 0 ?
        <table className={styles.listingsTable}>
          <thead>
            <tr>
              <th className={styles.first}>Property Type</th>
              <th>Marked as Shortlisted</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listings.map((data, index) => (
              <>
                <tr key={index}>
                  <td>
                    <div className={styles.tdBox}>
                      <p className={styles.title}>
                        {getPropertyTitle(data, [
                          'bhk',
                          'locality',
                          'area',
                          'for',
                        ])}
                      </p>

                      <p className={styles.location}>
                        <Icon src={icons.location} />
                        {getPropertyLocation(data, 'short')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        {data.timestamp.toDate().toLocaleDateString(undefined, {
                          day: 'numeric',
                          month: 'short',
                        })}{' '}
                      </p>

                      <p>
                        {data.timestamp.toDate().toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        For{' '}
                        {
                          propertyDetailData.propertyFor[
                            data[PROPERTY_DETAIL_NAME][
                              getName(propertyDetailData.propertyFor)
                            ]
                          ]
                        }
                      </p>
                      <p>₹{getPropertyPrice(data)}</p>
                    </div>
                  </td>
                  <td>
                    <div
                      className={classNames(
                        styles.tdBox,
                        styles.flex,
                        styles.actions,
                      )}
                    >
                      <button
                        onClick={() => handleUndo(data.id)}
                        className={styles.cancle}
                      >
                        UNDO
                      </button>
                    </div>
                  </td>
                </tr>
                <tr className={styles.spacer}>
                  <td colSpan={4}></td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      : <NothingFoundMessage message="You do not have any shortlisted properties" />
      }
    </>
  );
};

export const NotInterestedProperties = () => {
  const [loading, setLoading] = useState(true);

  const [listings, setListings] = useState(null);

  const { user, userData } = useUser();
  const { setToast } = useTost();

  useEffect(() => {
    if (user === null) {
      setLoading(false);
      return;
    }

    if (!user || !userData || !userData?.notInterested) return;

    setLoading(true);

    runBackgroundTask(async () => {
      try {
        const { getUserNotInterestedProperties } = await import('@/lib/db');

        const data = await getUserNotInterestedProperties(
          userData.notInterested,
        );

        setListings(data);
      } catch (e) {
        setToast({
          open: true,
          message: e.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    });
  }, [user, userData]);

  async function handleUndo(docId) {
    const { removePropertyFromUserNotInterestedList } = await import(
      '@/lib/actions'
    );

    try {
      await removePropertyFromUserNotInterestedList(user.uid, docId);
      setToast({
        open: true,
        message: 'Property removed from not interested successfully',
        type: 'success',
      });

      setListings(listings.filter((listing) => listing.id !== docId));
    } catch (e) {
      setToast({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  }

  if (user === undefined || loading)
    return <LoadingSkeleton title="My Not Intersted Properties" />;

  if (user === null) return <UserNotFoundMessage />;

  return (
    <>
      <h2>My Not Intersted Properties</h2>

      {Array.isArray(userData?.notInterested) && listings.length > 0 ?
        <table className={styles.listingsTable}>
          <thead>
            <tr>
              <th className={styles.first}>Property Type</th>
              <th>Marked as not Intersested</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listings.map((data, index) => (
              <>
                <tr key={index}>
                  <td>
                    <div className={styles.tdBox}>
                      <p className={styles.title}>
                        {getPropertyTitle(data, [
                          'bhk',
                          'locality',
                          'area',
                          'for',
                        ])}
                      </p>

                      <p className={styles.location}>
                        <Icon src={icons.location} />
                        {getPropertyLocation(data, 'short')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        {data.timestamp.toDate().toLocaleDateString(undefined, {
                          day: 'numeric',
                          month: 'short',
                        })}{' '}
                      </p>

                      <p>
                        {data.timestamp.toDate().toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(styles.tdBox, styles.flex)}>
                      <p>
                        For{' '}
                        {
                          propertyDetailData.propertyFor[
                            data[PROPERTY_DETAIL_NAME][
                              getName(propertyDetailData.propertyFor)
                            ]
                          ]
                        }
                      </p>
                      <p>
                        ₹
                        {Number(
                          data[PROPERTY_PRICING_NAME][
                            getName(propertyPricingData.rent)
                          ],
                        ).toLocaleString(IN_LOCALE_CODE)}
                        /month
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      className={classNames(
                        styles.tdBox,
                        styles.flex,
                        styles.actions,
                      )}
                    >
                      <button
                        onClick={() => handleUndo(data.id)}
                        className={styles.cancle}
                      >
                        UNDO
                      </button>
                    </div>
                  </td>
                </tr>
                <tr className={styles.spacer}>
                  <td colSpan={4}></td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      : <NothingFoundMessage message="No property marked as not interested." />}
    </>
  );
};
