import { forwardRef } from 'react';

import styles from '@/styles/modules/form-input.module.css';

import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import { useController } from 'react-hook-form';

import { useAccessor } from '@/components/accessor';
import { useFormContext } from '@/components/form/provider';
import { icons } from '@/components/icons';
import { Symbols } from '@/data/symbols';
import { getSymbolValue, getValues, isRequired } from '@/data/utils';

const FormSelect = forwardRef(
  (
    {
      name,
      className,
      parentStyles,
      validation,
      placeholder,
      options,
      labelComp,
      ...rest
    },
    ref,
  ) => {
    const accessor = useAccessor();
    const { control } = useFormContext();

    name = name || getSymbolValue(accessor, Symbols.Name);
    validation = validation || getSymbolValue(accessor, Symbols.Validation);
    options = options || getValues(accessor);

    const {
      field: { onChange, value, ref: formRef, ...restFields },
    } = useController({
      name,
      control,
      rules: validation,
    });

    return (
      <div ref={ref} style={parentStyles} className={styles.select}>
        <Select.Root
          id={name}
          name={name}
          value={value}
          onValueChange={(value) => onChange(value)}
          {...rest}
          {...restFields}
        >
          <Select.Trigger className={classNames(className, styles.trigger)}>
            <Select.Value placeholder={placeholder || 'Select your option'} />
            <Select.Icon className="">
              <img
                style={{ pointerEvents: 'none' }}
                src={icons.selectDropdown}
                alt=""
              />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content position="popper" className={styles.content}>
              <Select.Viewport style={{ overflowY: undefined }}>
                <ScrollBar>
                  {options.map((option) => (
                    <Select.Item
                      className={styles.item}
                      key={option.id}
                      value={option.value}
                    >
                      <Select.ItemText>
                        {renderLabel(option.label, labelComp)}
                      </Select.ItemText>
                    </Select.Item>
                  ))}
                </ScrollBar>
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    );
  },
);

function renderLabel(label, comp) {
  if (typeof label === 'function') return label(comp);

  return label;
}

export function ScrollBar({ children }) {
  return (
    <ScrollArea.Root className={styles.scrollAreaRoot}>
      <ScrollArea.Viewport className={styles.viewport}>
        {children}
      </ScrollArea.Viewport>

      <ScrollArea.Scrollbar className={styles.scrollbar} orientation="vertical">
        <ScrollArea.Thumb className={styles.thumb} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}

export default FormSelect;
