import { useState } from 'react';

import styles from '@/styles/modules/inquery.module.css';

import Slider from 'react-slick';

import { Icon, icons } from '@/components/icons';

import '@/styles/calendar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useEffect } from 'react';

import Calendar from 'react-calendar';

import { useFormContext } from '@/components/form/provider';
import { Modal } from '@/components/Modal';
import { useTost } from '@/components/toast';
import { useUser } from '@/contexts/auth-context';
import { ScheduleVisit } from '@/data/misc';
import { getName, getValues } from '@/data/utils';
import {
  getPropertyLocation,
  getPropertyTitle,
} from '@/pages/property-preview';

const FORM_ID = 'property-visit-form';

export const PropertyVisitForm = ({ type, ...props }) => {
  const { user, scheduledVisits } = useUser();

  const [step, setStep] = useState(1);
  const [mode, setMode] = useState({
    type: 'normal',
  }); // normal | edit

  const { setValue, watch } = useFormContext();

  const [visitType, visitTime, visitDate] = watch([
    getName(ScheduleVisit.visitType),
    getName(ScheduleVisit.visitTime),
    getName(ScheduleVisit.visitDate),
  ]);

  const visitTypeValues = getValues(ScheduleVisit.visitType);

  const data = props.propertyDetails;

  const propertyLocation = getPropertyLocation(data, 'short');
  const propertyTitle = getPropertyTitle(data, [
    'bhk',
    'locality',
    'area',
    'for',
  ]);

  useEffect(() => {
    console.log('-----Debug : start------');
    console.log(visitType, visitTime, visitDate);
    console.log('-----Debug : end------');
  }, [visitType, visitTime, visitDate]);

  if (!user) return null;

  console.log('scheduled Visits', scheduledVisits);

  return (
    <form id={FORM_ID}>
      {/* <FormGroup {...props}> */}
      <div className={styles.container}>
        {(
          Array.isArray(scheduledVisits) &&
          scheduledVisits.find((visit) => visit.context.propertyId === data.id)
        ) ?
          <h1>Vist is already scheduled</h1>
        : <form className={styles.form}>
            <>
              <div>
                <h2 className={styles.title}>Book a Visit to the property</h2>
                <p className={styles.subtitle}>
                  (Schedule a visit to the property with us from your options)
                </p>
              </div>
              <div className={styles.visitTypeContainer}>
                {visitTypeValues.map(({ value, label }) => (
                  <button
                    type="button"
                    className={`${styles.visitTypeButton} ${visitType === value ? styles.active : ''}`}
                    onClick={() =>
                      setValue(getName(ScheduleVisit.visitType), value)
                    }
                  >
                    {String(label)}
                  </button>
                ))}
              </div>

              <div>
                <Slider
                  slidesToShow={3}
                  slidesToScroll={1}
                  infinite={false}
                  speed={500}
                  arrows={false}
                  swipeToSlide={true}
                  responsive={[
                    {
                      breakpoint: 300,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 200,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {getDates().map((date) => (
                    <div className={styles.dateContainer}>
                      <button
                        type="button"
                        key={date.toISOString()}
                        className={`${styles.dateButton} ${visitDate && visitDate.toDateString() === date.toDateString() ? styles.selected : ''}`}
                        onClick={() =>
                          setValue(getName(ScheduleVisit.visitDate), date)
                        }
                      >
                        <span className={styles.day}>{getWeekName(date)}</span>
                        <span className={styles.date}>{date.getDate()}</span>
                        <span className={styles.month}>
                          {getMonthName(date)}
                        </span>
                      </button>
                    </div>
                  ))}
                </Slider>
              </div>

              <div className={styles.timeContainer}>
                {getTimes().map((time) => (
                  <button
                    type="button"
                    key={time.toISOString()}
                    className={`${styles.timeButton} ${visitTime && visitTime.toTimeString() === time.toTimeString() ? styles.selected : ''}`}
                    onClick={() =>
                      setValue(getName(ScheduleVisit.visitTime), time)
                    }
                  >
                    {formatTime(time)}
                  </button>
                ))}
              </div>

              <Modal
                centerTitle={true}
                title={
                  step < 3 ?
                    'Bokk a vist to the Property'
                  : 'Visit Request Sent to Dylan Estates'
                }
                trigger={
                  <button
                    type="button"
                    className={styles.submitButton}
                    disabled={!visitType || !visitDate || !visitTime}
                  >
                    Schedule a Visit
                  </button>
                }
                footer={
                  step === 1 && (
                    <div className={styles.disclaimer}>
                      <p>
                        (We will contact you to confirm your visit to the
                        Property)
                      </p>
                    </div>
                  )
                }
              >
                {step === 1 && (
                  <VisitPropertyStep1
                    mode={mode}
                    setMode={setMode}
                    propertyId={props.propertyDetails.id}
                    propertyTitle={propertyTitle}
                    propertyLocation={propertyLocation}
                    setStep={setStep}
                  />
                )}
                {step === 2 && <ChangeVisit setStep={setStep} />}
                {step === 3 && <VisitPropertyStepFinal setStep={setStep} />}
              </Modal>
            </>
          </form>
        }
      </div>
      {/* </FormGroup> */}
    </form>
  );
};

function VisitPropertyStep1({
  setStep,
  propertyId,
  propertyTitle,
  propertyLocation,
  mode,
  setMode,
}) {
  const { setToast } = useTost(0);

  const { user, scheduledVisits, setScheduledVisits } = useUser();

  const { getValues, handleSubmit, errors } = useFormContext();

  const changeButtonHandler = () => {
    setStep(2);
  };

  const [visitType, visitTime, visitDate] = getValues([
    getName(ScheduleVisit.visitType),
    getName(ScheduleVisit.visitTime),
    getName(ScheduleVisit.visitDate),
  ]);

  async function onSubmit(data) {
    console.log('submitting');

    console.log(errors, data);

    if (Object.keys(errors).length > 0 || !Object.keys(data).length) {
      setToast({
        open: true,
        message: 'Please select all the fields',
        type: 'error',
      });

      return;
    }

    setStep(3);

    const { scheduleVisit, updateScheduledVisit } = await import(
      '@/lib/actions'
    );

    try {
      if (mode.type === 'normal') {
        const finaldata = await scheduleVisit({
          ...data,
          createdBy: user.uid,
          context: {
            propertyId,
            propertyTitle,
            propertyLocation,
            phoneNumber: user?.phoneNumber,
            email: user.email,
          },
        });

        setMode({
          type: 'edit',
          id: finaldata.id,
        });

        // setScheduledVisits([finaldata, ...(scheduledVisits || [])]);
      } else {
        await updateScheduledVisit(mode.id, data);
      }
    } catch (e) {
      setStep(1);

      setToast({
        open: true,
        message: e.message,
        type: 'error',
      });

      console.error(e);
      throw e;
    }
  }

  return (
    <div className={styles.confirmationContainer}>
      <div className={styles.visitInfo}>
        <span>
          <strong>VIST TYPE:</strong>
          {ScheduleVisit.visitType[visitType]}
        </span>
        <button
          type="button"
          className={styles.changeButton}
          onClick={changeButtonHandler}
        >
          Change
        </button>
      </div>
      <div className={styles.visitInfo}>
        <p>
          <span>
            <Icon src={icons.calendar} />
            {formatDate(visitDate)}
          </span>
          <span>
            <Icon src={icons.clock} />
            {formatTime(visitTime)}
          </span>
        </p>
        <button
          type="button"
          className={styles.changeButton}
          onClick={changeButtonHandler}
        >
          Change
        </button>
      </div>
      <div className={styles.propertyDetails}>
        <h3>PROPERTY DETAIL</h3>
        <div>
          <p>{propertyTitle}</p>
          <p className={styles.location}>
            <Icon src={icons.location} />
            {propertyLocation}
          </p>
        </div>
      </div>
      <div className={styles.visitInfo}>
        <p className={styles.ownerName}>
          Visit the property with:
          <span>Melvin Lasrado</span>
        </p>
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          className={styles.confirmButton}
        >
          Confirm Visit
        </button>
      </div>
    </div>
  );
}

export function ChangeVisit({
  setStep,
  showSideBar = true,
  showSubmitButton = true,
  onSubmit,
  ButtonText = 'Next',
}) {
  const { getValues: getFormValues, setValue, watch } = useFormContext();

  const [visitType, visitTime, visitDate] = watch([
    getName(ScheduleVisit.visitType),
    getName(ScheduleVisit.visitTime),
    getName(ScheduleVisit.visitDate),
  ]);

  const visitTypeValues = getValues(ScheduleVisit.visitType);

  console.log('visitdate', visitDate);

  return (
    <div className={styles.changeVisitContainer}>
      {showSideBar && (
        <div className={styles.leftContainer}>
          <div className={styles.visitInfo}>
            <p className={styles.ownerName}>
              Visit the property with:
              <span>Melvin Lasrado</span>
            </p>
          </div>
          <div className={styles.visitInfo}>
            <p className={styles.ownerName}>About In Person Visits</p>
            <pre>
              {`Once you schedule a visit, we'll review your request and confirm the appointment promptly.

You'll then receive further communication from us to finalize the details.

 In-person visits allow you to tour the property with an agent and see it firsthand.`}
            </pre>
          </div>
        </div>
      )}
      <div className={styles.rightContainer}>
        <div className={styles.visitTypeSelection}>
          {visitTypeValues.map(({ value, label }) => (
            <button
              type="button"
              className={`${styles.visitTypeButton} ${visitType === value ? styles.active : ''}`}
              onClick={() => setValue(getName(ScheduleVisit.visitType), value)}
            >
              {String(label)}
            </button>
          ))}
        </div>
        <div className={styles.propertyDetails}>
          <Calendar
            defaultValue={visitDate}
            onChange={(date) =>
              setValue(getName(ScheduleVisit.visitDate), date)
            }
            minDate={new Date()}
            view="month"
          />
        </div>
        <div className={styles.timeContainer}>
          {getTimes().map((time) => (
            <button
              type="button"
              key={time.toISOString()}
              className={`${styles.timeButton} ${visitTime && visitTime.toTimeString() === time.toTimeString() ? styles.selected : ''}`}
              onClick={() => setValue(getName(ScheduleVisit.visitTime), time)}
            >
              {formatTime(time)}
            </button>
          ))}
        </div>
        {showSubmitButton && (
          <button
            style={{
              textTransform: 'uppercase',
            }}
            onClick={() => {
              if (setStep && typeof setStep === 'function') setStep(1);

              if (onSubmit && typeof onSubmit === 'function')
                onSubmit(getFormValues());
            }}
            className={styles.nextButton}
          >
            {ButtonText}
          </button>
        )}
      </div>
    </div>
  );
}

function VisitPropertyStepFinal({ setStep }) {
  const { getValues: getFormValues } = useFormContext();
  const [visitType, visitTime, visitDate] = getFormValues([
    getName(ScheduleVisit.visitType),
    getName(ScheduleVisit.visitTime),
    getName(ScheduleVisit.visitDate),
  ]);

  function changeButtonHandler() {
    setStep(1);
  }

  return (
    <div className={styles.finalStepContainer}>
      <div className={styles.visitInfo}>
        <p>
          <span>
            <Icon src={icons.calendar} />
            {formatDate(visitDate)}
          </span>
          <span>
            <Icon src={icons.clock} />
            {formatTime(visitTime)}
          </span>
        </p>
        <button className={styles.changeButton} onClick={changeButtonHandler}>
          Change
        </button>
      </div>
      <div className={styles.visitInfo}>
        <p>We will get in touch with you soon to confirm your visit.</p>
      </div>
      <div className={styles.visitInfo}>
        <p className={styles.companyName}>-Dylan Estate</p>
      </div>
    </div>
  );
}

function getWeekName(date) {
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
  });
}

function getMonthName(date) {
  return date.toLocaleDateString('en-US', {
    month: 'short',
  });
}

export const formatDate = (date) => {
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });
};

export const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
};

const getTimes = () => {
  const times = [];
  for (let hour = 11; hour <= 20; hour++) {
    if (hour !== 15 && hour !== 15) {
      // Excluding 3:00 PM
      times.push(new Date(2024, 0, 1, hour, 0, 0));
    }
  }
  return times;
};

const getDates = () => {
  const dates = [];
  const currentDate = new Date();
  for (let i = 0; i < 7; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);
    dates.push(date);
  }
  return dates;
};
