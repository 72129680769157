import { overviewIcons } from '@/components/icons';
import { Symbols } from '@/data/symbols';
import { primitiveWithSymbol } from '@/data/utils';

export const propertyType = {
  flatAndApartment: primitiveWithSymbol(String, 'Flat / Apartment', {
    name: 'flatAndApartment',
  }),
  HouseAndVilla: primitiveWithSymbol(String, 'House / Villa', {
    name: 'HouseAndVilla',
  }),
  officeSpace: 'Office Space',
  coWorking: 'Co Working',
  resturantOrCafe: 'Resturant / Cafe',
  shopOrShowroom: 'Shop / Showroom',
  industrialBuilding: 'Industrial Bldg.',
  industrialShed: 'Industrial Shed',
  warehouseOrGodown: 'Warehouse / Godown',
  landPlot: primitiveWithSymbol(String, 'Land / Plot', {
    filterLabel: 'Agricultural Land',
  }),
  [Symbols.Validation]: {
    required: 'This field is required',
  },
  [Symbols.Icon]: overviewIcons.propertyType,
  [Symbols.Name]: 'propertyType',
  [Symbols.Label]: 'Property Type',
};

const propertyDetailData = {
  propertyFor: {
    rent: 'Rent',
    sale: 'Sale',
    [Symbols.Icon]: overviewIcons.propertyType,
    [Symbols.Name]: 'propertyFor',
    [Symbols.Label]: 'Property For',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  propertyType,

  propertyTypeValues: {
    residential: {
      flatAndApartment: propertyType.flatAndApartment,
      HouseAndVilla: propertyType.HouseAndVilla,
      [Symbols.Name]: 'residential',
      [Symbols.Label]: 'Residential',
    },
    commercial: {
      officeSpace: propertyType.officeSpace,
      coWorking: propertyType.coWorking,
      resturantOrCafe: propertyType.resturantOrCafe,
      shopOrShowroom: propertyType.shopOrShowroom,
      industrialBuilding: propertyType.industrialBuilding,
      industrialShed: propertyType.industrialShed,
      warehouseOrGodown: propertyType.warehouseOrGodown,

      [Symbols.Name]: 'commercial',
      [Symbols.Label]: 'Commercial',
    },
    landPlot: {
      [Symbols.Name]: 'landPlot',
      [Symbols.Label]: 'Land / Plot',
    },
    [Symbols.Validation]: {
      required: 'This field is required',
    },
    [Symbols.Name]: '_propertyType',
    [Symbols.Label]: 'Property Type',
  },

  builtUpArea: {
    [Symbols.Name]: 'builtUpArea',
    [Symbols.ValueLabel]: (value) => `${value} Sq.ft.`,
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'Built Up Area is required',
    },
    [Symbols.Icon]: overviewIcons.builtUpArea,
    [Symbols.Label]: 'Built Up Area',
  },

  carpetArea: {
    [Symbols.Name]: 'carpetArea',
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'Carpet Area is required',
    },
    [Symbols.Icon]: overviewIcons.builtUpArea,
    [Symbols.Label]: 'Carpet Area',
  },

  propertyOnFloor: {
    [Symbols.Name]: 'propertyOnFloor',
    [Symbols.ValueLabel]: (value) =>
      value < 0 ? 'Basement'
      : value === 0 ? 'Ground'
      : `${value}`,
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'Property on Floor is required',
    },
    [Symbols.Label]: 'Property on Floor',
    [Symbols.Icon]: overviewIcons.propertyOnFloor,
  },

  totalFloors: {
    [Symbols.Name]: 'totalFloors',
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'Total Floors is required',
    },
    [Symbols.Label]: 'Total Floors',
    [Symbols.Icon]: overviewIcons.totalFloor,
  },

  propertyFacing: {
    north: 'North',
    south: 'South',
    east: 'East',
    west: 'West',
    northeast: 'Noth-East',
    southeast: 'South-East',
    southwest: 'South-West',
    northwest: 'North-West',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
    [Symbols.Name]: 'propertyFacing',
    [Symbols.Icon]: overviewIcons.facing,
    [Symbols.Label]: 'Property Facing',
  },

  propertyAge: {
    lessThanOneYear: primitiveWithSymbol(Number, 0, {
      label: 'Less than 1 Year',
      filterLabel: '<1',
    }),
    lessThanThreeYears: primitiveWithSymbol(Number, 1, {
      label: '1 - 3 Years',
      filterLabel: '<3',
    }),
    lessThanFiveYears: primitiveWithSymbol(Number, 2, {
      label: '3 - 5 years',
      filterLabel: '<5',
    }),
    lessThanTenYears: primitiveWithSymbol(Number, 3, {
      label: '5 - 10 Years',
      filterLabel: '<10',
    }),
    greaterThanTenYears: primitiveWithSymbol(Number, 4, {
      label: 'Greater than 10 Years',
      filterLabel: '>10',
    }),
    [Symbols.Name]: 'propertyAge',
    [Symbols.Validation]: {
      required: 'propertyAge is required',
    },
    [Symbols.Icon]: overviewIcons.propertyAge,
    [Symbols.Label]: 'Property Age',
  },

  bhkType: {
    oneRk: primitiveWithSymbol(Number, 0, {
      label: 'One RK',
    }),
    oneBHK: primitiveWithSymbol(Number, 1, {
      label: '1 BHK',
    }),
    twoBHK: primitiveWithSymbol(Number, 2, {
      label: '2 BHK',
    }),
    threeBHK: primitiveWithSymbol(Number, 3, {
      label: '3 BHK',
    }),
    fourBHK: primitiveWithSymbol(Number, 4, {
      label: '4 BHK',
    }),
    fivePlusBHK: primitiveWithSymbol(Number, 5, {
      label: '5+ BHK',
    }),
    [Symbols.Name]: 'bhkType',
    [Symbols.Validation]: {
      required: 'BHK Type is required',
    },
    [Symbols.Icon]: overviewIcons.bedroom,
    [Symbols.Label]: 'BHK Type',
  },

  bedrooms: {
    one: primitiveWithSymbol(Number, 1, {
      label: '1',
      valueLabel: 'One (1)',
    }),
    two: primitiveWithSymbol(Number, 2, {
      label: '2',
      valueLabel: 'Two (2)',
    }),
    three: primitiveWithSymbol(Number, 3, {
      label: '3',
      valueLabel: 'Three (3)',
    }),
    four: primitiveWithSymbol(Number, 4, {
      label: '4',
      valueLabel: 'Four (4)',
    }),
    five: primitiveWithSymbol(Number, 5, {
      label: '5',
      valueLabel: 'Five (5)',
    }),
    six: primitiveWithSymbol(Number, 6, {
      label: '6+',
      valueLabel: 'More than 6',
    }),
    [Symbols.Name]: 'bedrooms',
    [Symbols.Validation]: {
      required: 'Bedrooms are required',
    },
    [Symbols.Icon]: overviewIcons.bathroom,
    [Symbols.Label]: 'Bedrooms',
  },

  bathrooms: {
    one: primitiveWithSymbol(Number, 1, {
      label: '1',
      valueLabel: 'One (1)',
    }),
    two: primitiveWithSymbol(Number, 2, {
      label: '2',
      valueLabel: 'Two (2)',
    }),
    three: primitiveWithSymbol(Number, 3, {
      label: '3',
      valueLabel: 'Three (3)',
    }),
    four: primitiveWithSymbol(Number, 4, {
      label: '4',
      valueLabel: 'Four (4)',
    }),
    five: primitiveWithSymbol(Number, 5, {
      label: '5',
      valueLabel: 'Five (5)',
    }),
    six: primitiveWithSymbol(Number, 6, {
      label: '6+',
      valueLabel: 'More than 6',
    }),
    [Symbols.Name]: 'bathrooms',
    [Symbols.Validation]: {
      required: 'Bathrooms is required',
    },
    [Symbols.Icon]: overviewIcons.bathroom,
    [Symbols.OverviewLabel]: (value) =>
      `${value} Bathroom${value > 1 ? 's' : ''}`,
    [Symbols.Label]: 'Bathrooms / Toilets',
  },

  balcony: {
    zero: primitiveWithSymbol(Number, 0, {
      label: '0',
      valueLabel: 'No Balcony',
    }),
    one: primitiveWithSymbol(Number, 1, {
      label: '1',
      valueLabel: 'One (1)',
    }),
    two: primitiveWithSymbol(Number, 2, {
      label: '2',
      valueLabel: 'Two (2)',
    }),
    three: primitiveWithSymbol(Number, 3, {
      label: '3',
      valueLabel: 'Three (3)',
    }),
    forPlus: primitiveWithSymbol(Number, 4, {
      label: '4+',
      valueLabel: ' More than 4',
    }),

    [Symbols.Name]: 'balcony',
    [Symbols.Validation]: {
      required: 'Balcony is required',
    },
    [Symbols.Icon]: overviewIcons.balcony,
    [Symbols.Label]: 'Balcony',
  },

  tenetPreference: {
    any: primitiveWithSymbol(String, 'Any', {
      valueLabel: 'Any (Family/Bachelor)',
    }),
    family: 'Family',
    bachelorMan: 'Bachelor (Man)',
    bachelorWoman: 'Bachelor (Woman)',
    [Symbols.Name]: 'tenetPreference',
    [Symbols.Validation]: {
      required: 'Tenet Preference is required',
    },
    [Symbols.Icon]: overviewIcons.tenant,
    [Symbols.Label]: 'Tenet Preference',
  },

  availability: {
    immediate: 'Immediate',
    within15Days: 'Within 15 Days',
    withing1Month: 'Within 1 Month',
    within2Months: 'Within 2 Months',
    [Symbols.Name]: 'availability',
    [Symbols.Validation]: {
      required: 'Availability is required',
    },
    [Symbols.Icon]: overviewIcons.availability,
    [Symbols.Label]: 'Availability',
  },

  propertyDescription: {
    [Symbols.Name]: 'propertyDescription',
    [Symbols.Validation]: {
      required: 'Property Description is required',
    },
    [Symbols.Label]: 'Property Description',
  },
};

export const PROPERTY_DETAIL_NAME = 'property_detail';

export default propertyDetailData;
