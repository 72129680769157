import { createContext, useContext, useEffect, useState } from 'react';

import styles from '@/styles/modules/modal.module.css';

import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';

import { ScrollBar } from '@/components/form/select';
import { Icon, icons } from '@/components/icons';
import { ToastProvider } from '@/components/toast';

const modalContext = createContext();

export const useModal = () => useContext(modalContext);

export function Modal({ centerTitle, title, trigger, children, footer }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function close(e) {
      if (window.innerWidth > 1000) setOpen(false);
    }

    window.addEventListener('resize', close);

    return () => {
      window.removeEventListener('resize', close);
    };
  }, []);

  return (
    <modalContext.Provider value={{ open, setOpen }}>
      <Dialog.Root open={open} onOpenChange={(value) => setOpen(value)}>
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={styles.dialogOverlay} />
          <Dialog.Content className={styles.dialogContent}>
            <div className={styles.header}>
              <Dialog.Title
                className={classNames(
                  styles.title,
                  centerTitle && styles.centerTitle,
                )}
              >
                {title}
              </Dialog.Title>
              <Dialog.Close>
                <Icon src={icons.close} />
              </Dialog.Close>
            </div>
            <div className={styles.content}>{children}</div>
            {footer}
            {/* <div>
            <h1>Footer</h1>
          </div> */}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </modalContext.Provider>
  );
}
