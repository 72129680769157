import React from 'react';

import styles from '@/styles/modules/home/featured-properties.module.css';

import { Link } from 'react-router-dom';

import linvingRoom from '@/assets/images/living-room.png';
import Container from '@/components/container';
import PropertyCard from '@/components/preview/preproty-card';
import { PATHS } from '@/constants/paths';
import propertyDetailData from '@/data/preporty-detail';
import propertyFeaturesData from '@/data/property-features';

const FeaturedProperties = React.forwardRef(function (props, ref) {
  const properties = [
    {
      id: 1,
      for: 'For sale',
      price: '60.50 lac',
      title: '2 BHK Flat/ apartment for Sale',
      location: 'Star Village chs 2, Silver Park, Mira road',
      bedrooms: 2,
      bathrooms: 2,
      area: 1850,
      details: {
        propertyFacing: 'north',
      },
      features: {
        petsAllowed: 'yes',
        furnishing: 'fullyFurnished',
      },
    },
    {
      id: 1,
      for: 'For sale',
      price: '60.50 lac',
      title: '2 BHK Flat/ apartment for Sale',
      location: 'Star Village chs 2, Silver Park, Mira road',
      bedrooms: 2,
      bathrooms: 2,
      area: 1850,
      details: {
        propertyFacing: 'north',
      },
      features: {
        petsAllowed: 'yes',
        furnishing: 'fullyFurnished',
      },
    },
    {
      id: 1,
      for: 'For sale',
      price: '60.50 lac',
      title: '2 BHK Flat/ apartment for Sale',
      location: 'Star Village chs 2, Silver Park, Mira road',
      bedrooms: 2,
      bathrooms: 2,
      area: 1850,
      details: {
        propertyFacing: 'north',
      },
      features: {
        petsAllowed: 'yes',
        furnishing: 'fullyFurnished',
      },
    },
    // ... Add two more similar objects for the other properties
  ];

  return (
    <section ref={ref} className={styles.featuredProperties}>
      <Container>
        <Heading>Featured Properties</Heading>
        <div className={styles.properties}>
          {properties.map((property) => (
            <PropertyCard
              type="featured"
              features={[
                {
                  dataObject: propertyDetailData,
                  data: property.details,
                },
                {
                  dataObject: propertyFeaturesData,
                  data: property.features,
                },
              ]}
              price={property.price}
              image={linvingRoom}
              location={property.location}
              title={property.title}
              key={property.id}
              for={property.for}
            />
          ))}
        </div>
        <Link to={PATHS.PROPERTIES} className={styles.viewAllButton}>
          VIEW ALL PROPERTIES
        </Link>
      </Container>
    </section>
  );
});

export function Heading({ children, sub }) {
  return (
    <h2 style={sub ? { marginBottom: 0 } : {}} className={styles.heading}>
      {children}
    </h2>
  );
}

export default FeaturedProperties;
