import { where } from 'firebase/firestore';

import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
  propertyType,
} from '@/data/preporty-detail';
import propertyFeaturesData, {
  PROPERTY_FEATURES_NAME,
} from '@/data/property-features';
import propertyLocationData, {
  PROPERTY_LOCATION_NAME,
} from '@/data/property-location';
import propertyPricingData, {
  PROPERTY_PRICING_NAME,
} from '@/data/property-pricing';
import { Symbols } from '@/data/symbols';
import { getName, getSymbolValue } from '@/data/utils';

export const DEFAULT_SORT = 'timestamp_desc';

export const sortFilter = {
  timestamp_desc: 'Posted (newest first)',
  timestamp_asc: 'Posted (oldest first)',
  price_desc: 'Price (highest first)',
  price_asc: 'Price (lowest first)',
  [Symbols.Name]: 'sort',
  [Symbols.Label]: 'Sort',
};

export const verifiedFilter = {
  [Symbols.Name]: 'verified',
  [Symbols.Label]: 'Verified',
  [Symbols.Query]: (value) => {
    return {
      order: 1,
      query: where('verified', '==', value === 'true'),
    };
  },
};

export const searchByIdFilter = {
  [Symbols.Name]: 'search',
  [Symbols.Label]: 'Search by id',
  [Symbols.Query]: (value) => {
    return {
      order: 0,
      query: where(
        '__name__',
        'in',
        String(value)
          .split(',')
          .map((v) => String(v).trim()),
      ),
    };
  },
};

export const propertyForFilter = {
  rent: 'RENT',
  sale: 'BUY',
  [Symbols.Name]: 'propertyFor',
  [Symbols.Label]: 'Property For',
  [Symbols.Query]: (value) => {
    return {
      order: 0,
      query: where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyFor),
        '==',
        value,
      ),
    };
  },
};

export const propertyTypeValuesFilter = {
  commercial: 'COMMERCIAL',
  [Symbols.Name]: 'propertyTypeValues',
  [Symbols.Query]: (value) => ({
    order: 1,
    query: where(
      getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyTypeValues),
      '==',
      value,
    ),
  }),
};

export const propertyTypeFiter = {
  residential: {
    flatAndApartment: propertyType.flatAndApartment,
    HouseAndVilla: propertyType.HouseAndVilla,
    [Symbols.Name]: 'residential',
    [Symbols.Label]: 'Residential',
  },
  commercial: {
    ...propertyDetailData.propertyTypeValues.commercial,
    [Symbols.Name]: 'commercial',
    [Symbols.Label]: 'Commercial',
  },
  landPlot: {
    landPlot: propertyType.landPlot,
    [Symbols.Name]: 'other',
    [Symbols.Label]: 'Other',
  },
  [Symbols.Validation]: {
    required: 'This field is required',
  },
  [Symbols.Name]: 'propertyType',
  [Symbols.Label]: 'Property Type',

  [Symbols.Query]: (value) => ({
    order: 1,
    query: where(
      getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyType),
      '==',
      value,
    ),
  }),
};

export const locationFilter = {
  [Symbols.Name]: 'location',
  [Symbols.Label]: 'Location',
  [Symbols.Query]: (value) => ({
    order: 3,
    query: where(
      getFieldName(PROPERTY_LOCATION_NAME, propertyLocationData.terms),
      'array-contains-any',
      value.split(',').map((v) => String(v).toLowerCase().trim()),
    ),
  }),
};

export const priceRangeFilter = {
  min: {
    [Symbols.Name]: 'priceRange[0]',
    [Symbols.Label]: 'Min Price',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  max: {
    [Symbols.Name]: 'priceRange[1]',
    [Symbols.Label]: 'Max Price',
    [Symbols.Validation]: {
      required: 'This field is required',
      // max: 'Max price must be greater than min price',
    },
  },

  [Symbols.Name]: 'priceRange',
  [Symbols.Label]: 'Price Range',
  [Symbols.Query]: (value) => {
    console.log(value);
    const [min, max] = value.split(',').map((v) => Number(v));
    console.log(min, max);
    return {
      order: Infinity,
      query: [
        max &&
          !isNaN(max) &&
          max !== Infinity &&
          where(
            getFieldName(PROPERTY_PRICING_NAME, propertyPricingData.rent),
            '<=',
            max,
          ),
        min &&
          where(
            getFieldName(PROPERTY_PRICING_NAME, propertyPricingData.rent),
            '>=',
            min,
          ),
      ].filter(Boolean),
      // query: (data) => {
      //   return data.filter(({ data }) => {
      //     const rent =
      //       data[PROPERTY_PRICING_NAME][getName(propertyPricingData.rent)];
      //     return rent <= max && rent >= min;
      //   });
      // },
    };
  },
};

export function convertFirebaseToAlgoliaFilter(filterArray) {
  const algoliaFilters = [];

  for (const query of filterArray) {
    const { _field, _op: op, _value: filterValue } = query;
    const field = _field.segments.join('.');

    let algoliaFilter;
    switch (op) {
      case '==':
        algoliaFilter = `${field}:${JSON.stringify(filterValue)}`;
        break;
      case '!=':
        algoliaFilter = `NOT ${field}:${JSON.stringify(filterValue)}`;
        break;
      case '>':
        algoliaFilter = `${field} > ${filterValue}`;
        break;
      case '>=':
        algoliaFilter = `${field} >= ${filterValue}`;
        break;
      case '<':
        algoliaFilter = `${field} < ${filterValue}`;
        break;
      case '<=':
        algoliaFilter = `${field} <= ${filterValue}`;
        break;
      case 'in':
        algoliaFilter = filterValue
          .map((v) => `${field}:${JSON.stringify(v)}`)
          .join(' OR ');
        break;
      case 'not-in':
        algoliaFilter = `NOT (${filterValue.map((v) => `${field}:${JSON.stringify(v)}`).join(' OR ')})`;
        break;
      case 'array-contains':
        algoliaFilter = `${field}:${JSON.stringify(filterValue)}`;
        break;
      case 'array-contains-any':
        algoliaFilter = filterValue
          .map((v) => `${field}:${JSON.stringify(v)}`)
          .join(' OR ');
        break;
      case 'array-contains-all':
        algoliaFilter = filterValue
          .map((v) => `${field}:${JSON.stringify(v)}`)
          .join(' AND ');
        break;
      default:
        console.warn(`Unsupported operator: ${op}`);
        continue;
    }

    algoliaFilters.push(`(${algoliaFilter})`);
  }

  return algoliaFilters.join(' AND ');
}

export const ALOGLIA_TYPE = '_algolia_filter';

export const additionalFeaturesFilter = {
  allowsNonVeg: {
    allowed: propertyFeaturesData.nonVeg.allowed,
    [Symbols.Name]: 'allowsNonVeg',
    [Symbols.Label]: 'Allows Non Veg',
    [Symbols.Query]: (value) => ({
      order: 1,
      type: ALOGLIA_TYPE,
      query: where(
        getFieldName(PROPERTY_FEATURES_NAME, propertyFeaturesData.nonVeg),
        '==',
        value,
      ),
    }),
  },

  bhkType: {
    ...propertyDetailData.bhkType,
    [Symbols.Name]: 'bhkType',
    [Symbols.Label]: 'BHK',
    [Symbols.Query]: (value) => ({
      order: 1,

      type: ALOGLIA_TYPE,
      query: where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.bhkType),
        '==',
        value,
      ),
    }),
  },

  bathrooms: {
    ...propertyDetailData.bathrooms,
    [Symbols.Name]: 'bathrroms',
    [Symbols.Label]: 'Bathrooms/Toilet',
    [Symbols.Query]: (value) => ({
      order: 1,
      query: where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.bathrooms),
        '==',
        value,
      ),
    }),
  },

  tenetPreference: {
    ...propertyDetailData.tenetPreference,
    [Symbols.Name]: getName(propertyDetailData.tenetPreference),
    [Symbols.Label]: 'Tenet Type',
    [Symbols.Query]: (value) => ({
      order: 1,
      query: where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.tenetPreference),
        '==',
        value,
      ),
    }),
  },

  propertyAge: {
    ...propertyDetailData.propertyAge,
    [Symbols.Name]: getName(propertyDetailData.propertyAge),
    [Symbols.Label]: 'Property Age',
    [Symbols.Query]: (value) => {
      if (value === 'greaterThanTenYears')
        return {
          order: 1,
          type: ALOGLIA_TYPE,
          query: where(
            getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyAge),
            '==',
            value,
          ),
        };

      const keys = Object.keys(propertyDetailData.propertyAge);

      const index = keys.findIndex((key) => key === value);

      if (index === -1) return;

      const preceedingKeys = keys.slice(0, index);

      return {
        order: 1,
        type: ALOGLIA_TYPE,
        query: where(
          getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyAge),
          'in',
          [...preceedingKeys, value],
        ),
      };
    },
  },

  availability: {
    ...propertyDetailData.availability,
    [Symbols.Name]: getName(propertyDetailData.availability),
    [Symbols.Label]: 'Availability',
    [Symbols.Query]: (value) => {
      const keys = Object.keys(propertyDetailData.availability);

      const index = keys.findIndex((key) => key === value);

      if (index === -1) return;

      const preceedingKeys = keys.slice(0, index);

      return {
        order: 1,
        type: ALOGLIA_TYPE,
        query: where(
          getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.availability),
          'in',
          [...preceedingKeys, value],
        ),
      };
    },
  },

  petsAllowed: {
    yes: propertyFeaturesData.petsAllowed.yes,
    [Symbols.Name]: 'petsAllowed',
    [Symbols.Label]: 'Pet Allowed',
    [Symbols.Query]: (value) => ({
      order: 1,
      type: ALOGLIA_TYPE,
      query: where(
        getFieldName(PROPERTY_FEATURES_NAME, propertyFeaturesData.petsAllowed),
        '==',
        value,
      ),
    }),
  },

  furnshing: {
    ...propertyFeaturesData.furnishing,
    [Symbols.Name]: getName(propertyFeaturesData.furnishing),
    [Symbols.Label]: 'Furnishing',
    [Symbols.Query]: (value) => ({
      order: 1,
      type: ALOGLIA_TYPE,
      query: where(
        getFieldName(PROPERTY_FEATURES_NAME, propertyFeaturesData.furnishing),
        '==',
        value,
      ),
    }),
  },

  safety: {
    ...propertyFeaturesData.safety,
    [Symbols.Name]: getName(propertyFeaturesData.safety),
    [Symbols.Label]: 'Safety',
    [Symbols.Query]: (value) => {
      // const queries = value
      //   .split(',')
      //   .map((a) =>
      //     where(
      //       getFieldName(
      //         PROPERTY_FEATURES_NAME,
      //         propertyFeaturesData.safety,
      //         a,
      //       ),
      //       '==',
      //       a,
      //     ),
      //   );

      // return {
      //   order: 1,
      //   query: queries,
      // };
      return {
        order: 1,
        type: ALOGLIA_TYPE,
        query: where(
          getFieldName(PROPERTY_FEATURES_NAME, propertyFeaturesData.safety),
          'array-contains-any',
          value.split(','),
        ),
      };
    },
  },

  accessabilty: {
    groundFloor: 'Ground Floor',
    firstFloor: 'First Floor',
    lift: 'Lift',
    [Symbols.Name]: 'accessability',
    [Symbols.Label]: 'Accessability',
    [Symbols.Query]: (value) => {
      if (value === 'groundFloor')
        return {
          order: 1,
          type: ALOGLIA_TYPE,
          query: where(
            getFieldName(
              PROPERTY_DETAIL_NAME,
              propertyDetailData.propertyOnFloor,
            ),
            '==',
            0,
          ),
        };

      if (value === 'firstFloor')
        return {
          order: 1,
          type: ALOGLIA_TYPE,
          query: where(
            getFieldName(
              PROPERTY_DETAIL_NAME,
              propertyDetailData.propertyOnFloor,
            ),
            '==',
            1,
          ),
        };

      if (value === 'lift')
        return {
          order: 1,
          type: ALOGLIA_TYPE,
          query: where(
            getFieldName(
              PROPERTY_FEATURES_NAME,
              propertyFeaturesData.amenities,
            ),
            'array-contains',
            'lift',
          ),
        };
    },
  },

  additionalFeatures: {
    ceilingFan: propertyFeaturesData.additionalFeatures.ceilingFan,
    airConditioning: propertyFeaturesData.additionalFeatures.airConditioning,
    [Symbols.Name]: 'additionalFeatures',
    [Symbols.Label]: 'Additional Features',
    [Symbols.Query]: (value) => {
      // const queries = value
      //   .split(',')
      //   .map((a) =>
      //     where(
      //       getFieldName(
      //         PROPERTY_FEATURES_NAME,
      //         propertyFeaturesData.additionalFeatures,
      //         a,
      //       ),
      //       '==',
      //       a,
      //     ),
      //   );

      // return {
      //   order: 1,
      //   query: queries,
      // };
      return {
        order: 1,
        type: ALOGLIA_TYPE,
        query: where(
          getFieldName(
            PROPERTY_FEATURES_NAME,
            propertyFeaturesData.additionalFeatures,
          ),
          'array-contains-all',
          value.split(','),
        ),
      };
    },
  },

  amenities: {
    // ...propertyFeaturesData.amenities,
    propertyGym: propertyFeaturesData.amenities.propertyGym,
    reservedParking: propertyFeaturesData.amenities.reservedParking,
    regularWaterSupply: propertyFeaturesData.amenities.regularWaterSupply,
    powerBackupFull: propertyFeaturesData.amenities.powerBackupFull,
    powerBackupPartial: propertyFeaturesData.amenities.powerBackupPartial,
    kidsPlayArea: propertyFeaturesData.amenities.kidsPlayArea,
    swimingPool: propertyFeaturesData.amenities.swimingPool,
    clubHouse: propertyFeaturesData.amenities.clubHouse,
    [Symbols.Name]: getName(propertyFeaturesData.amenities),
    [Symbols.Label]: 'Other Amenities',
    [Symbols.Query]: (value) => {
      // const queries = value
      //   .split(',')
      //   .map((a) =>
      //     where(
      //       getFieldName(
      //         PROPERTY_FEATURES_NAME,
      //         propertyFeaturesData.amenities,
      //         a,
      //       ),
      //       '==',
      //       a,
      //     ),
      //   );

      return {
        order: 1,
        type: ALOGLIA_TYPE,
        query: where(
          getFieldName(PROPERTY_FEATURES_NAME, propertyFeaturesData.amenities),
          'array-contains-all',
          value.split(','),
        ),
      };
    },
  },
};

const filters = {
  verifiedFilter,
  searchByIdFilter,
  propertyForFilter,
  propertyTypeFiter,
  priceRangeFilter,
  locationFilter,
  propertyTypeValuesFilter,
  ...Object.values(additionalFeaturesFilter),
};

export function getFilter(filterName) {
  for (const filter in filters) {
    if (getName(filters[filter]) === filterName) {
      return filters[filter];
    }
  }
}

export function getFieldName(objName, fieldName, ...rest) {
  return `${objName}.${getSymbolValue(fieldName, Symbols.Name)}${rest && rest.length > 0 ? '.' + rest.join('.') : ''}`;
}

const SORT_ORDER = [
  getName(locationFilter),
  getName(propertyForFilter),
  getName(propertyTypeFiter),
];

export function parseSearchString(searchString) {
  const searchParams = new URLSearchParams(searchString);

  let parsed = deserializeSearchParams(searchParams);

  parsed = Object.entries(parsed).map(([key, value]) => {
    const filter = getFilter(key);

    if (!filter) return;

    const valueLabe = filter[value]?.[Symbols.ValueLabel];
    const label = filter[value]?.[Symbols.Label];
    const noramalizedValue = filter[value];

    if (
      key === getName(propertyForFilter) &&
      parsed.hasOwnProperty(getName(propertyTypeFiter))
    )
      return {
        [key]: `${valueLabe ?? label ?? noramalizedValue}`,
      };

    if (key === getName(propertyType))
      return {
        [key]: `${propertyType[value]}`,
      };

    if (key == getName(locationFilter))
      return {
        [key]: `(${Array.isArray(value) ? value.join(', ') : value})`,
      };

    if (
      key === getName(propertyFeaturesData.amenities) ||
      key === getName(propertyFeaturesData.additionalFeatures) ||
      key === getName(propertyFeaturesData.safety)
    )
      return {
        [key]: `${filter[Symbols.Label]} (${Array.isArray(value) ? value.map((a) => filter[a]).join(', ') : filter[value]})`,
      };

    if (key === getName(priceRangeFilter)) {
      const type = parsed[getName(propertyForFilter)];

      return {
        [key]: `${parsePrice(value[0], type)} - ${parsePrice(value[1], type)}`,
      };
    }

    return {
      [key]: `${filter[Symbols.Label]} (${valueLabe ?? label ?? noramalizedValue})`,
    };
  });

  parsed = parsed.sort((a, b) => {
    const aIndex = SORT_ORDER.indexOf(Object.keys(a)[0]);
    const bIndex = SORT_ORDER.indexOf(Object.keys(b)[0]);

    if (aIndex === bIndex) return 0;

    return aIndex > bIndex ? -1 : 1;
  });

  return parsed;
}

const SEPERATOR = {
  [getName(propertyTypeFiter)]: ' For ',
};

export function formattedSearchString(searchString) {
  const parsed = parseSearchString(searchString);

  const formatted = parsed.map((obj, index) => {
    const [key, value] = Object.entries(obj)[0];

    if (index === parsed.length - 1) return value;

    return `${value}${SEPERATOR[key] ?? ', '}`;
  });

  return formatted.join('');
}

export function deserializeSearchParams(searchParams) {
  const params = {};

  searchParams.forEach((value, key) => {
    value = String(decodeURIComponent(value));

    if (key === getSymbolValue(locationFilter, Symbols.Name)) {
      params[key] = value.split(',');
    } else if (key === getSymbolValue(priceRangeFilter, Symbols.Name)) {
      params[key] = value.split(',').map((v) => Number(v));
    } else if (value.includes(',')) {
      params[key] = value.split(',');
    } else {
      const filterExists = getFilter(key);

      if (filterExists) {
        params[key] = value;
      }
    }
  });

  return params;
}

/* PRICES */

const PRICES_FOR_RENT = [
  {
    value: 0,
    label: 10000,
    decorator: {
      start: '<',
    },
  },
  {
    value: 25000,
    label: 25000,
  },
  {
    value: 40000,
    label: 40000,
  },
  {
    value: 55000,
    label: 55000,
  },
  {
    value: 70000,
    label: 70000,
  },
  {
    value: 85000,
    label: 85000,
  },
  {
    value: Infinity,
    label: 100000,
    decorator: {
      end: '+',
    },
  },
];
// 30 lakhs, interval of 20lakhs
const PRICES_FOR_BUY = [
  {
    value: 0,
    label: 3000000,
    decorator: {
      start: '<',
    },
  },
  {
    value: 5000000,
    label: 5000000,
  },
  {
    value: 7000000,
    label: 7000000,
  },
  {
    value: 9000000,
    label: 9000000,
  },
  {
    value: 11000000,
    label: 11000000,
  },
  {
    value: 13000000,
    label: 13000000,
  },
  {
    value: Infinity,
    label: 15000000,
    decorator: {
      end: '+',
    },
  },
];

export const getPrices = (type) => {
  if (type === 'rent') return PRICES_FOR_RENT;
  if (type === 'sale') return PRICES_FOR_BUY;

  return PRICES_FOR_RENT;
};

function parsePrice(price, type) {
  price = Number(price);

  if (price === 0 || isNaN(price)) return '0';

  const Prices = getPrices(type ?? propertyForFilter.rent);

  const preDefinedPrice = Prices.find(({ value }) => value === price);

  const RUPEE_SYMBOL = '₹';

  if (preDefinedPrice) {
    return (
      RUPEE_SYMBOL +
      getPriceLabel(preDefinedPrice.label, {
        decorators: preDefinedPrice.decorator,
      })
    );
  } else {
    return RUPEE_SYMBOL + getPriceLabel(price);
  }
}

/**
 * Formats a price to a human-readable string.
 * @example formatPrice(1000) => '1K'
 * @example formatPrice(1000000) => '1 Lac'
 * @example formatPrice(123456789) => '12 Lac'
 * @example formatPrice(10000000) => '1 Cr'
 * @param {number} price
 * @param {{ decorators: {start: string, end: string}}} options
 * @returns {string}
 */

export function getPriceLabel(price, { decorators = {} } = {}) {
  if (typeof price === 'string') price = Number(price.replace(/[^\d]/g, ''));

  if (price <= 0 || isNaN(price)) return '';

  const { start = '', end = '' } = decorators;
  if (price >= 1e7) {
    return start + (price / 1e7).toFixed(2).replace(/\.00$/, '') + ' Cr' + end;
  } else if (price >= 1e5) {
    return start + (price / 1e5).toFixed(2).replace(/\.00$/, '') + ' Lac' + end;
  } else if (price >= 1e3) {
    return start + (price / 1e3).toFixed(2).replace(/\.00$/, '') + 'K' + end;
  } else {
    return start + price + end;
  }
}
