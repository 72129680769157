import { useMemo } from 'react';

import styles from '@/styles/modules/property-overview.module.css';

import OverviewItem, {
  OverviewItemSkeleton,
} from '@/components/preview/overview-item';
import propertyDetailData from '@/data/preporty-detail';
import propertyFeaturesData from '@/data/property-features';
import { Symbols } from '@/data/symbols';
import { getName, getValues } from '@/data/utils';

const includeDate = [
  propertyDetailData.bedrooms,
  propertyDetailData.bathrooms,
  propertyDetailData.builtUpArea,
  propertyDetailData.propertyOnFloor,
  propertyDetailData.totalFloors,
  propertyDetailData.propertyFacing,
  propertyDetailData.balcony,
  propertyFeaturesData.furnishing,
  propertyDetailData.tenetPreference,
  propertyFeaturesData.tiles,
  propertyDetailData.availability,
  propertyFeaturesData.waterSupply,
  propertyFeaturesData.petsAllowed,
  propertyFeaturesData.nonVeg,
  propertyFeaturesData.electricity,
  propertyDetailData.propertyAge,
  propertyDetailData.propertyType,
].map((item) => getName(item));

console.log(includeDate);

const PropertyOverview = ({ data }) => {
  const items = useMemo(
    () =>
      data &&
      data
        .map((item) =>
          getValues(item.dataObject)
            .filter(
              (values) =>
                includeDate.includes(values.id) &&
                item.data.hasOwnProperty(values.value),
            )
            // sort based on the order of the includeDate
            .sort(
              (a, b) => includeDate.indexOf(a.id) - includeDate.indexOf(b.id),
            )
            .map((values, index) => ({
              ...values,
              value:
                item.dataObject[values.id][item.data[values.value]]?.[
                  Symbols.Label
                ] ??
                item.dataObject[values.id][item.data[values.value]] ??
                item.data[values.value],

              valueLabel:
                item.dataObject[values.id][item.data[values.value]]?.[
                  Symbols.ValueLabel
                ] ?? item.dataObject[values.id]?.[Symbols.ValueLabel],
            })),
        )
        .flat()
        .filter((e) => e.value !== null || e.valueLabel !== undefined),
    [data],
  );

  console.log(items);
  // throw 'Test';
  return (
    <div className={styles.grid}>
      {items.map((item, index) => (
        <OverviewItem key={index} {...item} />
      ))}
    </div>
  );
};

export function OverviewSkeleton() {
  const items = Array.from({ length: 10 }).map((_, index) => ({
    id: index,
    value: index,
    label: index,
  }));

  return (
    <div className={styles.grid}>
      {items.map((_, index) => (
        <OverviewItemSkeleton key={index} />
      ))}
    </div>
  );
}

export default PropertyOverview;
