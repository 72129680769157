import React from 'react';

import styles from '@/styles/modules/amenities-item.module.css';

import Skeleton from '@/components/skeleton';

const AmenityItem = ({ icon, label }) => {
  return (
    <div className={styles.item}>
      <span className={styles.icon}>{<img src={icon} alt={label} />}</span>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export const AmenityItemSkeleton = () => {
  return (
    <div className={styles.item}>
      <span className={styles.icon}>
        <Skeleton.Image />
      </span>
      <Skeleton.Span lineCount={1} />
    </div>
  );
};

export default AmenityItem;
