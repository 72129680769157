import styles from '@/styles/modules/form.module.css';

import { useFormGroupConfig } from '@/components/form/group';
import { useProfile } from '@/contexts/profile-context';

export const FormHeader = ({ children }) => (
  <div className={styles.formHeader}>{children}</div>
);

export const FormBody = ({ children }) => (
  <div className={styles.formBody}>{children}</div>
);

export const FormFooter = ({ children }) => {
  const { type } = useFormGroupConfig() ?? { type: 'simple' };

  const { profile } = useProfile();

  return type === 'simple' ?
      <div
        style={{
          justifyContent: 'flex-end',
        }}
        className={styles.formFooter}
      >
        {children}
      </div>
    : <div className={styles.formFooter}>
        <p>
          <span className={styles.lightText}>Need Help?</span> Call{' '}
          {profile?.phoneNumber}
        </p>

        {children}
      </div>;
};

export function FormStack({ gap = 2, children }) {
  return (
    <div
      style={{
        '--gap': gap,
      }}
      className={styles.formStack}
    >
      {children}
    </div>
  );
}

export function FormGrid({ children }) {
  return <div className={styles.formGrid}>{children}</div>;
}

export function FormHeading({ gap, children }) {
  return (
    <h2
      style={{
        '--gap': gap,
      }}
      className={styles.formHeading}
    >
      {children}
    </h2>
  );
}

export function FomrDivider() {
  const { gap } = useFormGroupConfig() ?? { gap: 1 };
  return <div style={{ '--gap': gap }} className={styles.formDivider}></div>;
}
