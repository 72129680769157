import React from 'react';

import styles from '@/styles/modules/property-heading.module.css';

import classNames from 'classnames';

import { ConfitteProvider } from '@/components/confitte';
import { Icon, icons } from '@/components/icons';
import { WishlistButton } from '@/components/preview/preproty-card';
import Skeleton from '@/components/skeleton';
import { useTost } from '@/components/toast';
import { PATHS } from '@/constants/paths';

export function ShareButton({ id, ...props }) {
  const { setToast } = useTost();

  function handler() {
    const pageUrl = location.origin + PATHS.PROPERTY_PREVIEW + '?id=' + id;
    navigator.clipboard
      .writeText(pageUrl) // Write URL to clipboard
      .then(() => {
        setToast({
          open: true,
          message: 'Url copied to clipboard',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          open: true,
          message: 'Failed to copy url to clipboard',
          type: 'error',
        });
      });
  }

  return (
    <button onClick={handler} {...props}>
      <Icon src={icons.upload} />
    </button>
  );
}

const PropertyHeading = ({ title, location, id }) => {
  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.location}>
          <Icon src={icons.location} />
          {location}
        </p>
      </div>
      <Actions id={id} />
    </div>
  );
};

export function Actions({ id, className, slots = ['heart', 'share'] }) {
  return (
    <div className={classNames(styles.actions, className)}>
      {slots.includes('heart') && (
        <ConfitteProvider>
          <WishlistButton className={styles.favoriteButton} id={id} />
        </ConfitteProvider>
      )}
      {slots.includes('share') && (
        <ShareButton id={id} className={styles.shareButton} />
      )}
    </div>
  );
}
export const PropertyHeadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <div
        style={{
          width: '80%',
        }}
      >
        <Skeleton.Title />
        <Skeleton.Paragraph lineCount={1} />
      </div>

      <div className={styles.actions}>
        <button className={styles.favoriteButton}>
          <Icon src={icons.heart} />
        </button>
        <button className={styles.shareButton}>
          <Icon src={icons.upload} />
        </button>
      </div>
    </div>
  );
};

export default PropertyHeading;
