import styles from '@/styles/modules/container-box.module.css';

import classNames from 'classnames';

import Skeleton from '@/components/skeleton';

function ContainerBox({ title, subHeading, children, type }) {
  return (
    <div className={classNames(styles.container, styles[type])}>
      <div className={styles.heading}>
        <h2 className={styles.title}>{title}</h2>
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
      </div>
      {children}
    </div>
  );
}

export function ContainerBoxSkeleton({ title, subHeading, children, type }) {
  return (
    <div className={classNames(styles.container, styles[type])}>
      <div className={styles.heading}>
        <h2 className={styles.title}>{title}</h2>
        {subHeading && (
          <Skeleton.Span
            style={{
              maxWidth: 200,
            }}
          />
        )}
      </div>
      {children}
    </div>
  );
}

export default ContainerBox;
