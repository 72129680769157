import React from 'react';

import styles from '@/styles/modules/home/header.module.css';

function Header() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Your Neighborhood Experts</h1>
      </header>
      <p>Guiding you to dream homes since 1998</p>
    </div>
  );
}

export default Header;
