import React, { useState } from 'react';

import styles from '@/styles/modules/review.module.css';

import { IN_LOCALE_CODE } from '@/components/filters/price-range-filter';
import { Icon, icons } from '@/components/icons';

const Review = ({ name, timestamp, stars, title, review, lived }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.review}>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <Icon src={icons.user} />
        </div>
        <div className={styles.userInfo}>
          <span className={styles.name}>{name}</span>
          <span className={styles.time}>
            {timestamp.toDate().toLocaleDateString(IN_LOCALE_CODE, {
              day: 'numeric',
              month: 'short',
              year: '2-digit',
            })}
            {lived && (
              <span style={{ textTransform: 'capitalize' }}>
                {lived !== 'no' && ` • ${lived}`}
              </span>
            )}
          </span>
        </div>
        <div className={styles.rating}>{stars} ★</div>
      </div>
      <h3 className={styles.title}>{title}</h3>
      <pre className={styles.comment}>
        {expanded ?
          review
        : `${review.length > 100 ? review.slice(0, 100) + '...' : review} `}
      </pre>
      {review.length > 100 && (
        <a
          className={styles.showMoreButton}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Show less' : 'Show more'}
        </a>
      )}
    </div>
  );
};

export default Review;
