import checkboxStyles from '@/styles/modules/checkbox.module.css';
import styles from '@/styles/modules/radio-group.module.css';

import classNames from 'classnames';

import { useAccessor } from '@/components/accessor';
import { useFormGroupConfig } from '@/components/form/group';
import FormInput from '@/components/form/input';
import { Symbols } from '@/data/symbols';
import { getSymbolValue, getValues } from '@/data/utils';

function getInputStyles(type) {
  if (type === 'button') {
    return checkboxStyles.button;
  } else if (type === 'square') {
    return checkboxStyles.square;
  }
  return checkboxStyles.round;
}

export const radioSizes = {
  small: 'small',
};

const RadioInput = ({
  size,
  type,
  name,
  cols,
  options,
  validation,
  spacing,
  children,
  className,
  ...rest
}) => {
  const accessor = useAccessor();

  validation = validation || getSymbolValue(accessor, Symbols.Validation);
  name = name || getSymbolValue(accessor, Symbols.Name);
  options = options || getValues(accessor);

  return (
    <RadioGroup type={type} spacing={spacing} cols={cols}>
      {options.map((option) => (
        <div className={classNames(styles.option, className)} key={option.id}>
          <FormInput
            name={name}
            type="radio"
            value={option.value}
            id={name + option.id}
            className={classNames(getInputStyles(type), checkboxStyles[size])}
            validation={validation}
            {...rest}
          />
          <label htmlFor={name + option.id}>
            {children && typeof children === 'function' ?
              children(option)
            : null}
            {option.label}
          </label>
        </div>
      ))}
    </RadioGroup>
  );
};

export function RadioGroup({ cols, spacing, type, children }) {
  return (
    <div
      style={{
        '--grid-cols': cols ?? 3,
        ...(spacing === false ?
          {
            margin: 0,
            padding: 0,
          }
        : {}),
      }}
      className={type === 'button' ? checkboxStyles.group : styles.group}
    >
      {children}
    </div>
  );
}
export default RadioInput;
