import { Suspense } from 'react';

import styles from '@/styles/modules/pages/error.module.css';

import { BiError } from 'react-icons/bi';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';

import { RequireSignInProvider } from '@/components/preview/preproty-card';
import { ToastProvider } from '@/components/toast';
import { PATHS } from '@/constants/paths';
import { UserContext } from '@/contexts/auth-context';
import { ProfileContextProvider } from '@/contexts/profile-context';
import NotFoundPage from '@/pages/404';
import {
  NotInterestedProperties,
  PropertyInquiries,
  PropertyListings,
  PropertyVisits,
  SavedSearches,
  ShortlistedProperties,
} from '@/pages/dashboard';
import { GlobalStateProvider } from '@/store/global-state';

export function Error() {
  const error = useRouteError();

  if (error) console.error(error);

  return (
    <div className={styles.error}>
      <h1>
        <BiError /> Error
      </h1>
      <p>Some error occured, please try again later.</p>
    </div>
  );
}

function ErrorPage() {
  const error = useRouteError();

  if (error) console.error(error);

  if (error.status === 404) return <NotFoundPage />;

  return <Error />;
}

const router = createBrowserRouter([
  {
    path: PATHS.HOME,
    lazy: () => import('@/pages/home'),
    errorElement: <ErrorPage />,
  },
  {
    path: PATHS.PROPERTIES,
    lazy: () => import('@/pages/properties'),
  },
  {
    path: PATHS.SEARCH,
    lazy: () => import('@/pages/search'),
  },
  {
    path: PATHS.REGISTER,
    lazy: () => import('@/pages/register'),
  },
  {
    path: PATHS.PROPERTY_LISTING,
    lazy: () => import('@/pages/property-listing'),
  },
  {
    path: PATHS.PROPERTY_PREVIEW,
    lazy: () => import('@/pages/property-preview'),
  },
  {
    path: PATHS.THANKYOU,
    lazy: () => import('@/pages/thankyou'),
  },
  {
    path: PATHS.DASHBOARD,
    lazy: () => import('@/pages/dashboard'),
  },

  {
    path: PATHS.CONTACT_US,
    lazy: () => import('@/pages/contact-us'),
  },

  // DASHBORAD
  {
    path: '/dashboard',
    lazy: () => import('@/pages/dashboard'),
    children: [
      {
        index: true,
        path: PATHS.LISTINGS,
        element: <PropertyListings />,
      },
      {
        path: PATHS.ACTIVITY.VISITS,
        element: <PropertyVisits />,
      },
      {
        path: PATHS.ACTIVITY.INQUIRIES,
        element: <PropertyInquiries />,
      },
      {
        path: PATHS.ACTIVITY.SHORTLISTED,
        element: <ShortlistedProperties />,
      },
      {
        path: PATHS.ACTIVITY.SEARCHES,
        element: <SavedSearches />,
      },
      {
        path: PATHS.ACTIVITY.NOT_INTERESTED,
        element: <NotInterestedProperties />,
      },
    ],
  },

  {
    path: PATHS.ADMIN.PATH,
    lazy: () => import('@/admin/admin'),
    children: [
      {
        index: true,
        path: PATHS.ADMIN.PROFILE,
        lazy: () => import('@/admin/profile'),
      },
      {
        path: PATHS.ADMIN.URGENT_ACTIONS,
        lazy: () => import('@/admin/urgent-actions'),
      },
      {
        path: PATHS.ADMIN.INQUERIES,
        lazy: () => import('@/admin/inqueries'),
      },
      {
        path: PATHS.ADMIN.PROPERTIES,
        lazy: () => import('@/admin/properties-table'),
      },
      {
        path: PATHS.ADMIN.POST_RPROPERTY,
        lazy: () => import('@/admin/property-post'),
      },
      {
        path: PATHS.ADMIN.PENDING_APPROVAL,
        lazy: () => import('@/admin/pending-approvals'),
      },
      {
        path: PATHS.ADMIN.SCHEDULED_VISITS,
        lazy: () => import('@/admin/scheduled-visits'),
      },
      {
        path: PATHS.ADMIN.SCHEDULE_VISIT,
        lazy: () => import('@/admin/schedule-visit'),
      },
    ],
  },
]);

function App() {
  return (
    <GlobalStateProvider>
      <UserContext>
        <ToastProvider>
          <RequireSignInProvider>
            <ProfileContextProvider>
              <Suspense fallback={<h1>Loading...</h1>}>
                <RouterProvider router={router}></RouterProvider>
              </Suspense>
            </ProfileContextProvider>
          </RequireSignInProvider>
        </ToastProvider>
      </UserContext>
    </GlobalStateProvider>
  );
}

export default App;
