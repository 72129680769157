import {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import formInputStyles from '@/styles/modules/form-input.module.css';
import styles from '@/styles/modules/property-listing.module.css';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import classNames from 'classnames';
// import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { useController } from 'react-hook-form';
import { TiLocation } from 'react-icons/ti';

import { AccessorProvider } from '@/components/accessor';
import { FormBody, FormFooter, FormStack } from '@/components/form';
import FormGroup from '@/components/form/group';
import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import Map, {
  MapProvider,
  MIRA_ROAD_COORDINATES,
  useMap,
} from '@/components/google/maps';
import { icons } from '@/components/icons';
import { useTost } from '@/components/toast';
import propertyLocationData, {
  PROPERTY_LOCATION_NAME,
} from '@/data/property-location';
import { Symbols } from '@/data/symbols';
import { getName, getSymbolValue } from '@/data/utils';
import { useDebounce } from '@/hooks/useDebounce';
import { AutoComplete, GeoCoder } from '@/services/ola-maps';
import { useGlobalState } from '@/store/global-state';

const PropertyLocation = (props) => {
  const { getSavedDate } = useGlobalState();

  const defaultValues = getSavedDate(PROPERTY_LOCATION_NAME) ?? {
    societyName: '', // Building / Society Name
    locality: '', // Locality / Area
    landmark: '', // Landmark / Street Name
    city: 'Mumbai', // City
  };

  return (
    <FormProvider id={props.id} options={{ defaultValues }}>
      <MapProvider>
        <PropertyLocationForm {...props} />
      </MapProvider>
    </FormProvider>
  );
};

const PropertyLocationForm = ({ id, onClickNext, Footer }) => {
  const { setToast } = useTost();
  const { updateState, state } = useGlobalState();

  const [location, setLocation] = useState(
    state.propertyLocation?.marker ?? MIRA_ROAD_COORDINATES,
  );

  // const { mapRef, setMapCenter, setMarker } = useMap();
  const { handleSubmit, errors, control, getValues, setValue } =
    useFormContext();

  const onSubmit = (data) => {
    if (!data.marker) {
      setToast({
        open: true,
        message: 'Please add a marker of the location',
        type: 'error',
      });
      return;
    }

    const [terms] = getValues(['terms']);

    const { societyName, locality, landmark, city } = data;

    const addressTerms = [societyName, landmark, locality, city]
      .filter(Boolean)
      .flatMap((t) => t.split(','))
      .map((t) => String(t).trim().toLowerCase())
      .filter((t) => !terms?.includes(t));

    if (!terms || !terms.length) setValue('terms', addressTerms);
    else setValue('terms', [...terms, ...addressTerms]);

    console.log('addressTerms', addressTerms);
    console.log(getValues());

    updateState((state) => {
      state[PROPERTY_LOCATION_NAME] = getValues();
    });

    onClickNext();
  };

  const handleSelect = (selectedItem) => {
    //clear the terms first

    setValue('terms', null);

    const value = getValues();

    const { terms: form_terms, types: form_types } = value;

    const { terms, types } = selectedItem;
    const selectedValues = terms
      .map((term) => String(term.value).toLowerCase())
      .filter((t) => !form_terms?.includes(t));

    const selectedTypes = types
      .map((type) => String(type).toLowerCase())
      .filter((t) => !form_types?.includes(t));

    if (!form_terms?.length) setValue('terms', selectedValues);
    else setValue('terms', [...form_terms, ...selectedValues]);

    if (!form_types?.length) setValue('types', selectedTypes);
    else setValue('types', [...form_types, ...selectedTypes]);

    console.log(getValues());
    // const values = getValues();
    // const { city, locality, sublocality, landmark, societyName } = values;
    // let address = [societyName, landmark, sublocality, locality, city];
    // // return if any of the field is empty
    // if (address.some(() => !address)) return;
    // address = [societyName, landmark, sublocality, locality, city]
    //   .filter(Boolean)
    //   .join(', ');
    // console.log('Getting geocode for', address);
    // updateMapCenter(address);
  };

  // const updateMapCenter = async (address) => {
  //   if (!window.geocoder)
  //     window.geocoder = new GeoCoder(import.meta.env.VITE_OLA_MAPS_API_KEY);

  //   try {
  //     let results = await window.geocoder.geocode(address);
  //     results = results.geocodingResults;

  //     console.log(results);

  //     const { lat, lng } = results[0].geometry.location;
  //     const newCenter = { lat, lng };

  //     setMapCenter(newCenter);
  //     setMarker(newCenter);

  //     mapRef.current.panTo(newCenter);
  //     mapRef.current.setZoom(14);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <form id={id} className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <FormBody>
        <FormStack>
          <AutoCompleteProvider
            location={location}
            setLocation={setLocation}
            onSelect={handleSelect}
            types={['establishment']}
          >
            <LocationFormGroup
              control={control}
              placeholder="Enter Building/Society Name"
              label={getSymbolValue(
                propertyLocationData.societyName,
                Symbols.Label,
              )}
              name={getName(propertyLocationData.societyName)}
              error={errors[getName(propertyLocationData.societyName)]}
              location={location}
            />
          </AutoCompleteProvider>
          <AutoCompleteProvider
            location={location}
            onSelect={handleSelect}
            types={['sublocality']}
          >
            <LocationFormGroup
              label={getSymbolValue(
                propertyLocationData.locality,
                Symbols.Label,
              )}
              name={getName(propertyLocationData.locality)}
              error={errors[getName(propertyLocationData.locality)]}
              control={control}
              onValueChange={(value) => {
                setValue(getName(propertyLocationData.locality), value?.trim());

                return value;
              }}
              // modifiedValue={() =>
              //   getValues()[getName(propertyLocationData.locality)] +
              //   ', ' +
              //   getValues()[getName(propertyLocationData.sublocality)]
              // }
              placeholder="Enter Locality/Area"
              location={location}
            />
          </AutoCompleteProvider>
        </FormStack>
        <FormStack>
          <AutoCompleteProvider
            location={location}
            onSelect={handleSelect}
            types={['landmark', 'street_address', 'point_of_interest']}
          >
            <LocationFormGroup
              label={getSymbolValue(
                propertyLocationData.landmark,
                Symbols.Label,
              )}
              name={getName(propertyLocationData.landmark)}
              error={errors[getName(propertyLocationData.landmark)]}
              control={control}
              placeholder="Prominent Landmark"
              location={location}
            />
          </AutoCompleteProvider>

          <AutoCompleteProvider
            location={location}
            onSelect={handleSelect}
            types={['locality']}
          >
            <LocationFormGroup
              readonly={true}
              label={getSymbolValue(propertyLocationData.city, Symbols.Label)}
              name={getName(propertyLocationData.city)}
              error={errors[getName(propertyLocationData.city)]}
              control={control}
              placeholder="Mumbai, Maharashtra"
              location={location}
              setLocation={setLocation}
            />
          </AutoCompleteProvider>
        </FormStack>
        <div height="800px">
          {/* <LocationFormGroup
            styling={false}
            readonly={true}
            label={'Mark locality on map'}
            name={'autocomplete'}
            error={errors['autocomplete']}
            control={control}
            onSelect={handleSelect}
            placeholder="Search you society or nearst landmark"
            location={location}
            setLocation={setLocation}
          > */}
          <AccessorProvider accessor={propertyLocationData.marker}>
            <FormGroup>
              <Map />
            </FormGroup>
          </AccessorProvider>

          {/* </LocationFormGroup> */}
        </div>
      </FormBody>
      <div className={styles.footer}>
        <Footer />
      </div>
    </form>
  );
};

const LocationFormGroup = ({
  label,
  name,
  error,
  control,
  placeholder,
  onValueChange,
  modifiedValue,
  readonly,
  children,
}) => {
  const {
    field: { onChange, ref, ...rest },
  } = useController({
    name,
    control,
    rules: { required: `${name} is required` },
  });

  function selectHandler(selectedItem) {
    const [subLocality, locality] = selectedItem.terms;
    onChange(
      onValueChange ?
        onValueChange(subLocality.value + ', ' + locality.value)
      : selectedItem.terms[0].value,
    );
  }

  const { onChange: autoComplteOnChange, onKeyDown } = useAutoCompleteContext();

  return (
    <FormGroup label={label} required error={error}>
      <AutoCompleteWrapper onSelect={selectHandler}>
        <FormInput
          decoration={{
            end: <DropDownIcon />,
          }}
          ref={ref}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            onChange(
              onValueChange ? onValueChange(e.target.value) : e.target.value,
            );
            autoComplteOnChange(e);
          }}
          type="text"
          name={name}
          placeholder={placeholder}
          validation={{ required: 'This field is required' }}
          value={modifiedValue ? modifiedValue(rest.value) : rest.value}
          {...rest}
          readOnly={readonly}
        />
      </AutoCompleteWrapper>
      {children}
    </FormGroup>
  );
};

const AutoCompleteInputContext = createContext();

export const useAutoCompleteContext = () =>
  useContext(AutoCompleteInputContext);

const AutoCompleteProvider = ({
  types,
  location,
  radius,
  strictBounds,
  setLocation,
  children,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const itemRef = useRef({});

  const selectRef = useRef(null);

  const [focusIndex, setFocusIndex] = useState(0);

  const { placePredictions, setPlacePredictions, getPlacePredictions } =
    useAutoComplete(import.meta.env.VITE_OLA_MAPS_API_KEY, {
      types,
    });

  function selectHandler(selectedItem) {
    console.log(selectedItem);

    const locatin = selectedItem.geometry.location;

    setLocation && setLocation(locatin);

    if (selectRef.current && typeof selectRef.current === 'function') {
      selectRef.current(selectedItem);
    }

    onSelect && typeof onSelect === 'function' && onSelect(selectedItem);

    setOpen(false);
  }

  useEffect(() => {
    if (placePredictions && placePredictions.length > 0) setOpen(true);
  }, [placePredictions]);

  function onChange(e) {
    const value = e.target.value.trim();
    if (placePredictions && placePredictions.length > 0 && value) setOpen(true);
    else setOpen(false);

    if (!value) return;

    const options = {};

    if (location) options.location = location;
    if (radius) options.radius = radius;
    if (strictBounds) options.strictbounds = strictBounds;
    if (types) options.types = types;

    console.log('Options', options);
    getPlacePredictions(e.target.value.trim(), options);
  }

  function onKeyDown(e) {
    if (e.key === 'ArrowDown') {
      // itemRef.current[focusIndex].dataset.highlighted = true;
      setFocusIndex((prev) => {
        if (prev >= placePredictions.length - 1) return 0;
        return prev + 1;
      });

      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setFocusIndex((prev) => {
        if (prev <= 0) return placePredictions.length - 1;
        return prev - 1;
      });

      e.preventDefault();
    } else if (e.key === 'Enter') {
      open && selectHandler(placePredictions[focusIndex]);

      e.stopPropagation();
      e.preventDefault();
    } else {
      setFocusIndex(0);
    }
  }

  const value = {
    open,
    setOpen,
    placePredictions,
    setPlacePredictions,
    getPlacePredictions,
    focusIndex,
    setFocusIndex,
    itemRef,
    onChange,
    onKeyDown,
    selectHandler,
    setLocation,
    selectRef,
  };

  return (
    <AutoCompleteInputContext.Provider value={value}>
      {children}
    </AutoCompleteInputContext.Provider>
  );
};

export const AutoCompleteWrapper = ({
  onSelect,
  children,
  anchorWidth = false,
  showFullLocation = false,
}) => {
  const {
    open,
    setOpen,
    placePredictions,
    focusIndex,
    setFocusIndex,
    itemRef,
    selectRef,
    selectHandler,
  } = useAutoCompleteContext();

  useEffect(() => {
    if (onSelect && typeof onSelect === 'function') {
      selectRef.current = onSelect;
    }
  }, [onSelect]);

  return (
    <Popover.Root modal={false} open={open} onOpenChange={setOpen}>
      <Popover.Anchor asChild>{children}</Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          style={
            anchorWidth && {
              width: 'var(--radix-popper-anchor-width)',
            }
          }
          onOpenAutoFocus={(e) => e.preventDefault()}
          autoFocus={false}
          hideWhenDetached={true}
          avoidCollisions={false}
          sideOffset={5}
          align="start"
          className={classNames(
            formInputStyles.content,
            formInputStyles.autocomplete,
          )}
        >
          {placePredictions.map((item, index, arr) => (
            <div
              onMouseEnter={() => setFocusIndex(index)}
              data-focus={index === focusIndex}
              ref={(e) => {
                itemRef.current[index] = e;
              }}
              data-value={item.terms[0].value}
              onClick={(e) => {
                selectHandler(item);
              }}
              className={formInputStyles.item}
              key={item.place_id}
            >
              <LocationItem showFullLocation={showFullLocation} item={item} />
            </div>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

// export function GoogleMapsAutoComplete({
//   children,
//   onSelect,
//   simple,
//   ...rest
// }) {
//   const itemRef = useRef({
//     start: null,
//     last: null,
//   });
//   const inputRef = useRef(null);

//   const [inputValue, setInputValue] = useState('');

//   // const { placePredictions, getPlacePredictions,  } =
//   //   usePlacesService({
//   //     debounce: 1000,
//   //     apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
//   //     options: {
//   //       locationRestriction: {
//   //         center: {
//   //           lat: 19.2967,
//   //           lng: 72.8702,
//   //         },
//   //         radius: 6200.0,
//   //       },

//   //       types: ['(regions)'],
//   //       componentRestrictions: { country: 'in' },
//   //     },
//   //   });

//   const { placePredictions, setPlacePredictions, getPlacePredictions } =
//     useAutoComplete(import.meta.env.VITE_OLA_MAPS_API_KEY);

//   useEffect(() => {
//     const value = inputValue.trim();

//     if (!value) setPlacePredictions([]);
//   }, [inputValue]);

//   function isPredistionEmpty() {
//     return (
//       !placePredictions ||
//       placePredictions.length === 0 ||
//       inputValue.trim().length === 0
//     );
//   }

//   return (
//     <DropdownMenu.Root modal={false} open={true}>
//       <DropdownMenu.Trigger asChild>
//         <div
//           style={{
//             width: '100%',
//           }}
//         >
//           <input
//             ref={inputRef}
//             onKeyDown={(e) => {
//               e.stopPropagation();
//               // it it'a an arrow key, prevent default
//               if (e.key === 'ArrowDown') {
//                 itemRef.current?.start?.focus();
//                 e.preventDefault();
//               }

//               if (e.key === 'ArrowUp') {
//                 itemRef.current?.last?.focus();
//                 e.preventDefault();
//               }
//             }}
//             onChange={(evt) => {
//               const value = evt.target.value.trim();

//               setInputValue(value);

//               if (!value && value.length < 1) return;
//               // getPlacePredictions({ input: evt.target.value.trim() });
//               getPlacePredictions(
//                 value,
//                 !simple && {
//                   location: {
//                     lat: 19.29102998701604,
//                     lng: 72.86345946004514,
//                   },
//                   radius: 6200.0,
//                   strictbounds: true,
//                   types: ['sublocality'],
//                 },
//               );
//             }}
//             {...rest}
//           />
//         </div>
//       </DropdownMenu.Trigger>

//       <DropdownMenu.Portal>
//         <DropdownMenu.Content
//           hideWhenDetached={true}
//           avoidCollisions={false}
//           style={
//             isPredistionEmpty() ?
//               {
//                 display: 'none',
//               }
//             : simple && {
//                 width: '100vw',
//                 maxWidth: '700px',
//               }
//           }
//           sideOffset={5}
//           align="start"
//           onEscapeKeyDown={() => inputRef.current?.focus()}
//           className={classNames(
//             formInputStyles.content,
//             formInputStyles.autocomplete,
//           )}
//         >
//           <ScrollArea.Root className={formInputStyles.scrollAreaRoot}>
//             <ScrollArea.Viewport
//               style={{
//                 '--height': '400px',
//               }}
//               className={formInputStyles.viewport}
//             >
//               {placePredictions.map((item, index, arr) => (
//                 <DropdownMenu.Item
//                   ref={(e) => {
//                     if (index === 0) itemRef.current.start = e;
//                     if (index === arr.length - 1) itemRef.current.last = e;
//                   }}
//                   onKeyDown={(e) => {
//                     if (index === 0 && e.key === 'ArrowUp') {
//                       inputRef.current?.focus();
//                       e.preventDefault();
//                     }
//                     if (index === arr.length - 1 && e.key === 'ArrowDown') {
//                       inputRef.current?.focus();
//                       e.preventDefault();
//                     }
//                   }}
//                   textValue={item.terms[0].value}
//                   data-value={item.terms[0].value}
//                   onSelect={(e) => {
//                     onSelect(item);
//                     inputRef.current.value = '';
//                     setInputValue('');
//                   }}
//                   className={formInputStyles.item}
//                   key={item.place_id}
//                 >
//                   <LocationItem simple={simple} item={item} />
//                 </DropdownMenu.Item>
//               ))}
//             </ScrollArea.Viewport>

//             <ScrollArea.Scrollbar
//               className={formInputStyles.scrollbar}
//               orientation="vertical"
//             >
//               <ScrollArea.Thumb className={formInputStyles.thumb} />
//             </ScrollArea.Scrollbar>
//           </ScrollArea.Root>

//           {/* <div className={styles.googleLogoContainer}>
//             <p>Powered by</p>
//             <img
//               loading="lazy"
//               src={
//                 'https://developers.google.com/static/maps/documentation/images/google_on_white.png'
//               }
//               alt="google-logo"
//             />
//           </div> */}
//         </DropdownMenu.Content>
//       </DropdownMenu.Portal>
//     </DropdownMenu.Root>
//   );
// }

export function StandAloneSearchBox({
  location,
  radius,
  strictbounds,
  types,
  ...props
}) {
  return (
    <AutoCompleteProvider
      location={location}
      radius={radius}
      strictBounds={strictbounds}
      types={types}
    >
      <StandAloneInput {...props} />
    </AutoCompleteProvider>
  );
}

function StandAloneInput({
  showFullLocation,
  anchorWidth,
  onChange,
  onSelect,
  ...props
}) {
  const { onChange: onAutCompleteChange, onKeyDown } = useAutoCompleteContext();

  return (
    <AutoCompleteWrapper
      showFullLocation={showFullLocation}
      anchorWidth={anchorWidth}
      onSelect={onSelect}
    >
      <input
        {...props}
        onChange={(e) => {
          onChange && onChange(e);
          onAutCompleteChange(e);
        }}
        onKeyDown={onKeyDown}
      />
    </AutoCompleteWrapper>
  );
}

function LocationItem({ item, simple, showFullLocation }) {
  const matchedText = item.structured_formatting.main_text_matched_substrings;
  const secondaryMatchedText =
    item.structured_formatting.secondary_text_matched_substrings;

  const originalMainText = item.structured_formatting.main_text;
  const originalSecondaryText = item.structured_formatting.secondary_text;

  let secondaryText = originalSecondaryText;
  let mainText = originalMainText;

  if (matchedText && matchedText.length > 0) {
    // map all the matched substrings to the main text and add strong html tag to them

    for (const matched of matchedText) {
      const { offset, length } = matched;
      const matchedSliced = originalMainText.slice(offset, offset + length);

      mainText = mainText.replace(
        matchedSliced,
        `<strong>${matchedSliced}</strong>`,
      );
    }

    for (const matched of secondaryMatchedText) {
      const { offset, length } = matched;
      const matchedSliced = originalSecondaryText.slice(
        offset,
        offset + length,
      );

      secondaryText = secondaryText.replace(
        matchedSliced,
        `<strong>${matchedSliced}</strong>`,
      );
    }

    // const splittedMainText = mainText.split();

    // const { offset, length } = matchedText[0];

    // const before = mainText.slice(0, offset);
    // const midd = `<strong>${mainText.slice(offset, offset + length)}</strong>`;
    // const after = mainText.slice(offset + length);

    // mainText = before + midd + after;
  }

  console.log(mainText);
  return (
    <div className={formInputStyles.itemText}>
      <div className={formInputStyles.itemIcon}>
        <TiLocation size={24} />
      </div>
      <p
        style={
          simple && {
            display: 'inline-block',
          }
        }
      >
        <span data-main dangerouslySetInnerHTML={{ __html: mainText }}></span>
        <span
          data-secondary
          dangerouslySetInnerHTML={{
            __html:
              simple || showFullLocation ? secondaryText : (
                item.structured_formatting.secondary_text
                  .split(',')
                  .slice(0, 2)
                  .join(', ')
              ),
          }}
        ></span>
      </p>
    </div>
  );
}

export const CustomSearchBox = ({ ...props }) => {
  const options = {
    location: {
      lat: 19.29102998701604,
      lng: 72.86345946004514,
    },
    radius: 6200.0,
    strictbounds: true,
    types: ['sublocality'],
  };

  return <StandAloneSearchBox {...options} {...props} />;
};

export function DropDownIcon() {
  return <img src={icons.selectDropdown} alt="select-dropdown" />;
}

function removeDuplicates(arr) {
  return arr.filter(
    (item, index, self) =>
      self.findIndex((t) => t.place_id === item.place_id) === index,
  );
}

function useAutoComplete(apiKey, { debounce = 500 } = {}) {
  const [placePredictions, setPlacePredictions] = useState([]);

  const autoCompleteRef = useRef(null);

  const getPlacePredictions = useDebounce(async (...args) => {
    const res = await autoCompleteRef?.current(...args);

    setPlacePredictions(removeDuplicates(res.predictions));
  }, debounce);

  useEffect(() => {
    const autocomplete = new AutoComplete(apiKey);

    autoCompleteRef.current = autocomplete.r.bind(autocomplete);
  }, []);

  return {
    placePredictions,
    setPlacePredictions,
    getPlacePredictions,
  };
}

export default PropertyLocation;
