import { v4 as uuidv4 } from 'uuid';

export class AutoComplete {
  controller = new AbortController();

  constructor(apiKey) {
    if (!apiKey) throw new Error('Api Key is required for Ola Maps');

    this.apiKey = apiKey;
    this.correlationId = uuidv4();
    this.url = `https://api.olamaps.io/places/v1/autocomplete`;
    this.controller = new AbortController();

    this.r.bind(this);
  }

  /**
   * A function to autocomplete places
   * @param {String} query
   * @param {{
   *   location?: { lat: number; lng: number };
   *   radius?: number;
   *   strictbounds?: boolean;
   *   types : string[];
   * }} param1
   * @returns
   */
  async r(query, { location, radius, strictbounds, types } = {}) {
    // Abort previous request
    // why is this undefined?

    console.log(this);
    // this.controller.abort("");

    const id = uuidv4();

    const url = new URL(this.url);

    url.searchParams.append('api_key', this.apiKey);
    url.searchParams.append('input', query);

    if (location)
      url.searchParams.append('location', `${location.lat},${location.lng}`);

    if (radius) url.searchParams.append('radius', radius);

    if (strictbounds) url.searchParams.append('strictbounds', strictbounds);

    if (types) url.searchParams.append('types', types.join(','));

    console.log('getting Autocomplete Result', url.toString());

    let response;

    try {
      response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'X-Request-Id': id,
          Accept: 'application/json',
          // 'Content-Type': 'application/json',
          'X-Correlation-Id': this.correlationId,
        },
        signal: this.controller.signal,
      });
    } catch (error) {
      response = {
        error: true,
        status: error.status,
        message: error.message,
        ...error,
      };
    }

    const data = await response.json();

    if (response.error || response.status !== 200)
      throw new Error(response.message);

    console.log('Autocomplete Result', response.status);
    console.log('Autocomplete Result', data);

    return data;
  }
}

export class GeoCoder {
  controller = new AbortController();

  constructor(apiKey) {
    if (!apiKey) throw new Error('Api Key is required for Ola Maps');

    this.apiKey = apiKey;
    this.correlationId = uuidv4();
    this.url = `https://api.olamaps.io/places/v1/geocode`;
    this.controller = new AbortController();
  }

  async geocode(address) {
    const id = uuidv4();

    const url = new URL(this.url);

    url.searchParams.append('api_key', this.apiKey);
    url.searchParams.append('address', address);

    console.log('getting Geocode Result', url.toString());

    let response;

    try {
      response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'X-Request-Id': id,
          Accept: 'application/json',
          'X-Correlation-Id': this.correlationId,
        },
        signal: this.controller.signal,
      });
      console.log(response);
    } catch (error) {
      response = {
        error: true,
        status: error.status,
        message: error.message,
        ...error,
      };
    }

    const data = await response.json();

    if (response.error || response.status !== 200)
      throw new Error(response.message);

    console.log('GeoCode response', response.status);
    console.log('GeoCode Result', data);

    return data;
  }
}

export class PlaceDetails {
  controller = new AbortController();

  constructor(apiKey) {
    if (!apiKey) throw new Error('Api Key is required for Ola Maps');

    this.apiKey = apiKey;
    this.correlationId = uuidv4();
    this.url = `https://api.olamaps.io/places/v1/details`;
    this.controller = new AbortController();
  }

  async details(placeId) {
    const id = uuidv4();

    const url = new URL(this.url);

    url.searchParams.append('api_key', this.apiKey);
    url.searchParams.append('place_id', placeId);

    console.log('getting Place Details', url.toString());

    let response;

    try {
      response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'X-Request-Id': id,
          Accept: 'application/json',
          'X-Correlation-Id': this.correlationId,
        },
        signal: this.controller.signal,
      });
    } catch (error) {
      response = {
        error: true,
        status: error.status,
        message: error.message,
        ...error,
      };
    }

    const data = await response.json();

    if (response.error || response.status !== 200)
      throw new Error(response.message);

    console.log('Place Details response', response.status);
    console.log('Place Details Result', data);

    return data;
  }
}

// class DelayedRequestQueue {
//   queue = [];
//   timeout = null;

//   constructor(timeout = 1000) {
//     this.timeout = timeout;
//   }

//   add(request) {
//     this.queue.push(request);
//     this.process();
//   }

//   process() {
//     if (this.queue.length === 0) return;

//     const request = this.queue.shift();

//     if (this.timeout) {
//       clearTimeout(this.timeout);
//       this.timeout = null;
//     }

//     this.timeout = setTimeout(() => {
//       request();
//     }, this.timeout);
//   }
// }

export class DistanceMatrix {
  controller = new AbortController();

  constructor(apiKey) {
    if (!apiKey) throw new Error('Api Key is required for Ola Maps');

    this.apiKey = apiKey;
    this.correlationId = uuidv4();
    this.url = `https://api.olamaps.io/routing/v1/distanceMatrix`;
    this.controller = new AbortController();
    this.queue = new DelayedRequestQueue(1000);
  }

  async get(origin, destinations) {
    const id = uuidv4();

    const url = new URL(this.url);

    const destinationsString = destinations
      .map((destination) => `${destination.latitude},${destination.longitude}`)
      .join('|');

    url.searchParams.set('api_key', this.apiKey);
    url.searchParams.set('origins', `${origin.latitude},${origin.longitude}`);
    url.searchParams.set('destinations', destinationsString);

    console.log('getting Distance Matrix Result', url.toString());

    let response;

    try {
      response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'X-Request-Id': id,
          Accept: 'application/json',
          'X-Correlation-Id': this.correlationId,
        },
        signal: this.controller.signal,
      });
    } catch (error) {
      response = {
        error: true,
        status: error.status,
        message: error.message,
        ...error,
      };
    }

    const data = await response.json();

    if (response.error || response.status !== 200)
      throw new Error(response.message);

    console.log('Distance Matrix response', response.status);
    console.log('Distance Matrix Result', data);

    return data;
  }
}

class DelayedRequestQueue {
  queue = [];
  isProcessing = false;

  constructor(timeout = 1000) {
    this.timeout = timeout;
  }

  add(request) {
    return new Promise((resolve, reject) => {
      this.queue.push({ request, resolve, reject });
      this.process();
    });
  }

  async process() {
    if (this.isProcessing || this.queue.length === 0) return;

    this.isProcessing = true;

    const { request, resolve, reject } = this.queue.shift();

    try {
      const result = await request();
      resolve(result);
    } catch (error) {
      reject(error);
    }

    setTimeout(() => {
      this.isProcessing = false;
      this.process(); // Process the next request in the queue after the delay
    }, this.timeout);
  }
}

// export class DistanceMatrix {
//   controller = new AbortController();

//   constructor(apiKey) {
//     if (!apiKey) throw new Error('Api Key is required for Ola Maps');

//     this.apiKey = apiKey;
//     this.correlationId = uuidv4();
//     this.url = `https://api.olamaps.io/routing/v1/distanceMatrix`;
//     this.controller = new AbortController();
//     this.queue = new DelayedRequestQueue(1000);
//   }

//   async fetchDistanceMatrix(origin, destinations) {
//     const id = uuidv4();

//     const url = new URL(this.url);

//     const destinationsString = destinations
//       .map((destination) => `${destination.latitude},${destination.longitude}`)
//       .join('|');

//     url.searchParams.set('api_key', this.apiKey);
//     url.searchParams.set('origins', `${origin.latitude},${origin.longitude}`);
//     url.searchParams.set('destinations', destinationsString);

//     console.log('getting Distance Matrix Result', url.toString());

//     let response;

//     try {
//       response = await fetch(url.toString(), {
//         method: 'GET',
//         headers: {
//           'X-Request-Id': id,
//           Accept: 'application/json',
//           'X-Correlation-Id': this.correlationId,
//         },
//         signal: this.controller.signal,
//       });
//     } catch (error) {
//       response = {
//         error: true,
//         status: error.status,
//         message: error.message,
//         ...error,
//       };
//     }

//     const data = await response.json();

//     if (response.error || response.status !== 200)
//       throw new Error(response.message);

//     console.log('Distance Matrix response', response.status);
//     console.log('Distance Matrix Result', data);

//     return data;
//   }

//   async get(origin, destinations) {
//     // Add the request to the delayed queue and return the result
//     return this.queue.add(async () => {
//       return await this.fetchDistanceMatrix(origin, destinations);
//     });
//   }
// }
