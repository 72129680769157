import styles from '@/styles/modules/pages/not-found.module.css';

import { Link } from 'react-router-dom';

import { Icon, icons } from '@/components/icons';
import NavBar from '@/components/nav';

export default function NotFoundPage() {
  return (
    <>
      <NavBar type="other" position="relative" />
      <main className={styles.container}>
        <h1>404</h1>
        <p>We are sorry, but the page you requested was not found</p>
        <Link to="/">
          Back to Home
          <Icon src={icons.arrowPointingRight} />
        </Link>
      </main>
    </>
  );
}
