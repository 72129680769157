import styles from '@/styles/modules/home/contact-info.module.css';

import backgroundImage from '@/assets/images/home/enquiry-background.png';
import Container from '@/components/container';
import { Heading } from '@/components/home/featured-properties';
import { Icon, icons } from '@/components/icons';
import { EnquiryBackgroundImage } from '@/components/images/enquiry-background';
import InquiryForm from '@/components/inquery';
import { useProfile } from '@/contexts/profile-context';

const ContactInfo = () => {
  const { profile } = useProfile();
  const { officeAddress, phoneNumber, email } = profile;

  return (
    <section className={styles.contactSection}>
      <div className={styles.contactBg}>
        <EnquiryBackgroundImage loading="lazy" />
      </div>
      <Container className={styles.container}>
        <div className={styles.contactInfo}>
          <Heading>Contact Info</Heading>
          <div className={styles.infoItem}>
            <div className={styles.icon}>
              <Icon src={icons.phoneLarge} alt="phone-icon" />
            </div>
            <div className={styles.info}>
              <h4>Phone</h4>
              <p>{phoneNumber}</p>
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.icon}>
              <Icon src={icons.emailLarge} alt="email-icon" />
            </div>
            <div className={styles.info}>
              <h4>Email</h4>
              <p>{email}</p>
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.icon}>
              <Icon src={icons.locationMedium} alt="location-icon" />
            </div>
            <div className={styles.info}>
              <h4>Address</h4>
              <p>{officeAddress}</p>
            </div>
          </div>
        </div>

        {/* Assume you have an EnquiryForm component */}
        <div className={styles.enquiryForm}>
          <InquiryForm title="Quick Enquiry" type="minimal" />
        </div>
      </Container>
    </section>
  );
};

export default ContactInfo;
