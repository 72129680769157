import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const profileContext = createContext();

export const useProfile = () => useContext(profileContext);

const DefaultProfile = {
  name: '',
  phoneNumber: '',
  whatsAppNumber: '',
  email: '',
  officeAddress: '',
  officeStartDay: '',
  officeEndDay: '',
  officeOpeningHours: '',
  officeClosingHours: '',
};

export const ProfileContextProvider = ({ children }) => {
  const [profile, setProfile] = useState(DefaultProfile);

  const formatedProfile = useCallback(() => {
    const address = profile?.officeAddress;
    const officeDays = `${profile?.officeStartDay.slice(0, 3)} - ${profile?.officeEndDay.slice(0, 3)}`;
    const officeHours = `${formatTime(profile?.officeOpeningHours)} - ${formatTime(profile?.officeClosingHours)}`;
    return { address, officeDays, officeHours };
  }, [profile]);

  useEffect(() => {
    (async () => {
      try {
        const { getProfileData } = await import('@/lib/db');
        const profileData = await getProfileData();

        setProfile(profileData);

        console.log('initialized profile', profileData);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <profileContext.Provider
      value={{
        profile,
        setProfile,
        formatedProfile,
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

/**
 *  Converts 24hr time to 12hr time
 * @param {String} time
 */
function formatTime(time) {
  if (!time) return '';

  const [hours, minutes] = time.split(':');

  return `${hours}:${minutes.slice(0, 2)} ${hours > 12 ? 'PM' : 'AM'}`;
}
