import { Suspense, useEffect } from 'react';

import styles from '@/styles/modules/property-preview.module.css';

import { SmartDisplay } from '@mui/icons-material';
import classNames from 'classnames';
import { Await, defer, useLoaderData } from 'react-router-dom';

import Container from '@/components/container';
import ContainerBox, { ContainerBoxSkeleton } from '@/components/container-box';
import { IN_LOCALE_CODE } from '@/components/filters/price-range-filter';
import FormProvider from '@/components/form/provider';
import InquiryForm, {
  InqueryForm,
  PropertyInqueryForm,
  PropertyVisitScheduleForm,
} from '@/components/inquery';
import { PropertyVisitForm } from '@/components/misc/visit-scheduling';
import { Modal } from '@/components/Modal';
import NavBar from '@/components/nav';
import { ExploreNeighborhood } from '@/components/Neighbourhood';
import AboutLocation from '@/components/preview/about';
import Amenities, { AmenitiesSkeleton } from '@/components/preview/amenities';
import Description from '@/components/preview/description';
import Footer from '@/components/preview/Footer';
import ImageCarousel, {
  ImagesSkeletonLoading,
} from '@/components/preview/image-carousel';
import PropertyOverview, {
  OverviewSkeleton,
} from '@/components/preview/overview';
import PriceInfo, {
  MorePriceInfo,
  PriceInfoSkeleton,
} from '@/components/preview/price-info';
import PropertyHeading, {
  PropertyHeadingSkeleton,
} from '@/components/preview/property-heading';
import SimilarProperties from '@/components/preview/similar-properties';
import RatingsAndReviews from '@/components/preview/user-reviews';
import PropertyDetailsForm from '@/components/property-forms/property-detail';
import { useUser } from '@/contexts/auth-context';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
} from '@/data/preporty-detail';
import propertyFeaturesData, {
  PROPERTY_FEATURES_NAME,
} from '@/data/property-features';
import { PROPERTY_LOCATION_NAME } from '@/data/property-location';
import { PROPERTY_PRICING_NAME } from '@/data/property-pricing';
import { Symbols } from '@/data/symbols';
import { getLabel, getName, getSymbolValue } from '@/data/utils';
import { Main } from '@/pages/home';

//todo : move all data to mock data file.
const reviews = [
  {
    name: 'Melvin Lasrado',
    time: '2 days ago',
    rating: 4,
    title: 'Beautiful Property',
    comment:
      'This is a beautiful property located in Gokul village chs 2 Shanti Park, near st. Xaviours High school',
  },
  {
    name: 'Melvin Lasrado',
    time: '2 days ago',
    rating: 4,
    title: 'Beautiful Property',
    comment:
      'This is a beautiful property located in Gokul village chs 2 Shanti Park, near st. Xaviours High school',
  },
];

const aboutDescription = `\nSpread over 510 sqft. this home is an ideal place to live in.  Access to bus station & medical stores is very easy & convenient from this house.

If you are a frequent traveller, then you'll be happy to note that train station is less than 10 minutes from this house.  With PVR Cinemas - Mira Road, Maxus Cinemas & INOX close by, you can catch your favourite movies running & never worry about missing a show because of traffic.

Never miss out on lifestyle as Rassaz Mall.
  `;

// function getUser() {
//   const state = JSON.parse(localStorage.getItem('state'));
//   return state && state.userRegisterd;
// }

// function isFormSubmitted() {
//   const state = JSON.parse(localStorage.getItem('state'));
//   return state && state.formSubmitted;
// }

// function wait(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export async function runBackgroundTask(task) {
  task();
  // if (typeof window !== 'undefined') {
  //   setTimeout(task, 0);
  // }
}

export const loader = async ({ request, params }) => {
  const { getProperty, getScheduledVisits, getAllReviews } = await import(
    '@/lib/db'
  );

  const url = new URL(request.url);
  const id = url.searchParams.get('id');

  const reviewSearchParams = new URLSearchParams();
  reviewSearchParams.set('propertyId', id);

  const reviews = getAllReviews(reviewSearchParams, { limit: 2 });

  return defer({
    reviews,
    property: new Promise(async (res, rej) => {
      try {
        const property = await getProperty(id);
        // await wait(10000);
        const data = property.data();

        res({ id: property.id, ...data });
      } catch (e) {
        console.error(e);
        rej(e);
      }
    }),
  });
};

export const Component = () => {
  const { user, userData, setUserData, setScheduledVisits } = useUser();
  const { property } = useLoaderData();

  useEffect(() => {
    if (!user) return;

    runBackgroundTask(async () => {
      console.warn('Running background task');
      const data = await property;
      const currentPropertyData = {
        id: data.id,
        [PROPERTY_LOCATION_NAME]: data[PROPERTY_LOCATION_NAME],
        [PROPERTY_DETAIL_NAME]: {
          [getName(propertyDetailData.propertyFor)]:
            data[PROPERTY_DETAIL_NAME][getName(propertyDetailData.propertyFor)],
          [getName(propertyDetailData.propertyType)]:
            data[PROPERTY_DETAIL_NAME][
              getName(propertyDetailData.propertyType)
            ],
          [getName(propertyDetailData.propertyTypeValues)]:
            data[PROPERTY_DETAIL_NAME][
              getName(propertyDetailData.propertyTypeValues)
            ],
        },
        [PROPERTY_PRICING_NAME]: data[PROPERTY_PRICING_NAME],
      };

      const visitedProperties = userData?.visitedProperties || [];

      if (visitedProperties.find((p) => p.id === currentPropertyData.id)) {
        console.log('property already visited');
        return;
      }

      // max five properties can be visited, if the limti is alreday reached, then pop the last visited property and add the current property in the starting so that it is sorted.
      if (visitedProperties.length >= 20) {
        visitedProperties.pop();
      }

      visitedProperties.unshift(currentPropertyData);

      console.log('visitedProperties', visitedProperties);
      try {
        const { updateUserVisitedProperty } = await import('@/lib/actions');
        await updateUserVisitedProperty(user.uid, visitedProperties);
        setUserData((prev) => ({
          ...prev,
          visitedProperties,
        }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    });

    runBackgroundTask(async () => {
      const { getScheduledVisits } = await import('@/lib/db');

      const data = await property;
      const scheduledVisit = await getScheduledVisits(user.uid, data.id);

      if (scheduledVisit && scheduledVisit.length > 0) {
        console.log('scheduledVisit', scheduledVisit);
        setScheduledVisits(scheduledVisit);
      }
    });
  }, [user]);

  return (
    <Suspense fallback={<PropertyLoadingSkeleton />}>
      <Await
        // errorElement={<div>Could not load reviews 😬</div>}
        resolve={property}
        children={(property) => <Property data={property} />}
      />
    </Suspense>
  );
};

/**
 *
 * @param {Object} data
 * @param {['bhk', 'locality', 'area' , 'for']} slots
 */
export const getPropertyTitle = (data, slots = []) => {
  const {
    [PROPERTY_DETAIL_NAME]: { bhkType, propertyFor },
  } = data;
  const bhkVlaueLabel = getSymbolValue(
    propertyDetailData.bhkType[bhkType],
    Symbols.Label,
  );
  const title = `${slots.includes('bhk') ? bhkVlaueLabel : ''}
  ${slots.includes('for') ? 'for ' + propertyFor : ''}  ${slots.includes('locality') ? 'in ' + data[PROPERTY_LOCATION_NAME].locality : ''} ${slots.includes('area') ? '(' + data[PROPERTY_DETAIL_NAME].builtUpArea + ' Sq.ft.)' : ''}`;
  return title;
};

/**
 *
 * @param {Object} data
 * @param {"short" | "long"} type
 * @returns
 */
export const getPropertyLocation = (data, type = 'long') => {
  const location = [
    data[PROPERTY_LOCATION_NAME].societyName,
    data[PROPERTY_LOCATION_NAME].landmark ?
      `near ${data[PROPERTY_LOCATION_NAME].landmark}`
    : null,
    data[PROPERTY_LOCATION_NAME].sublocality,
    data[PROPERTY_LOCATION_NAME].locality,
    data[PROPERTY_LOCATION_NAME].city,
  ].filter(Boolean);

  const shortLocation = location.slice(0, 2);

  return type === 'long' ? location.join(', ') : shortLocation.join(', ');
};

export function getPropertyPrice(data, price) {
  return (
    (price ??
      Number(data[PROPERTY_PRICING_NAME].rent).toLocaleString(IN_LOCALE_CODE)) +
    (data[PROPERTY_DETAIL_NAME].propertyFor === 'rent' ? ' / Month' : '')
  );
}

const Property = ({ data }) => {
  console.log(data);
  const { amenities, ...features } = data[PROPERTY_FEATURES_NAME];
  const {
    [PROPERTY_DETAIL_NAME]: {
      bhkType,
      _propertyType,
      propertyDescription,
      ...restPropertyOverview
    },
  } = data;

  const title = getPropertyTitle(data, ['bhk', 'locality', 'area', 'for']);
  const location = getPropertyLocation(data);

  const nearbyPlaces = data.nearby_places;

  return (
    <Main>
      <NavBar position="relative" type="other" />
      {/* //todo: Move this to SEO file or component */}
      {/* <Helmet>
        <title>{title}</title>
        <meta name="description" content={data.description} />
        <meta
          name="keywords"
          content="Dylan Estate, Dylan Estate, Estate, Property, Rent, Apartment, House, Land, Real Estate"
        />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={data.description} />
        <meta
          name="og:image"
          content="https://dylan-estate-xvif.onrender.com/og-image.png"
        />
        <meta name="og:url" content="https://dylan-estate-xvif.onrender.com" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={data.description} />
        <meta
          name="twitter:image"
          content="https://dylan-estate-xvif.onrender.com/og-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}

      <Container className={styles.widthContainer}>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <TabPlus>
              <PropertyHeading id={data.id} title={title} location={location} />
              <ImageCarousel images={data.property_images} id={data.id} />
            </TabPlus>
            <Mobile>
              <ImageCarousel images={data.property_images} id={data.id} />
              <PropertyHeading id={data.id} title={title} location={location} />
              <PriceInfoModal data={data} />
            </Mobile>
            <ContainerBox
              title="Property Overview"
              subHeading={`${data[PROPERTY_LOCATION_NAME].societyName}`}
            >
              {/* //todo : improve this */}
              <PropertyOverview
                data={[
                  {
                    dataObject: propertyDetailData,
                    data: restPropertyOverview,
                  },
                  {
                    dataObject: propertyFeaturesData,
                    data: features,
                  },
                ]}
              />
            </ContainerBox>

            <ContainerBox title="Amenities">
              {amenities && <Amenities data={amenities} />}
            </ContainerBox>
            <ContainerBox title="Description">
              <Description text={propertyDescription} />
            </ContainerBox>
          </div>
          <TabPlus className={styles.rightColumn}>
            <div className={styles.sticky}>
              <PriceInfoModal data={data} />

              <InquiryForm
                textareaPlaceholder="I would like more information about Sector 5, shanti nagar, anubhav society"
                propertyDetails={data}
              />
            </div>
          </TabPlus>
        </div>
      </Container>
      <div>
        {nearbyPlaces && (
          <Container className={styles.widthContainer}>
            <ContainerBox type="deepShadow" title="Explore Neighborhood">
              <ExploreNeighborhood
                data={nearbyPlaces}
                location={data[PROPERTY_LOCATION_NAME].marker}
              />
            </ContainerBox>
          </Container>
        )}
        <Container className={styles.widthContainer}>
          <ContainerBox title="Ratings & Reviews">
            <RatingsAndReviews propertyDetails={data} />
          </ContainerBox>
        </Container>

        <SimilarProperties
          property={data}
          location={data[PROPERTY_LOCATION_NAME].locality}
        />
        {/* <AboutLocation location={'Mira Road'} description={aboutDescription} /> */}
      </div>
      <Footer />

      <div className={styles.footerContainer}>
        <Modal trigger={<button>SCHEDULE VISIT</button>}>
          {/* <PropertyInqueryForm
            textareaPlaceholder="I would like more information about Sector 5, shanti nagar, anubhav society"
            propertyDetails={data}
          /> */}
          <FormProvider>
            <div
              style={{
                width: '100vw',
                maxWidth: '100%',
                contain: 'size',
              }}
            >
              <PropertyVisitForm propertyDetails={data} />
            </div>
          </FormProvider>
        </Modal>
        <Modal trigger={<button>SEND INQUIRY</button>}>
          {/* <PropertyInqueryForm
            textareaPlaceholder="I would like more information about Sector 5, shanti nagar, anubhav society"
            propertyDetails={data}
          /> */}
          <FormProvider>
            <InqueryForm
              textareaPlaceholder="I would like more information about Sector 5, shanti nagar, anubhav society"
              propertyDetails={data}
            />
          </FormProvider>
        </Modal>
      </div>
    </Main>
  );
};

export function PropertyLoadingSkeleton() {
  return (
    <>
      <Container>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <PropertyHeadingSkeleton />
            <ImagesSkeletonLoading />

            <ContainerBoxSkeleton title="Property Overview" subHeading>
              <OverviewSkeleton />
            </ContainerBoxSkeleton>

            <ContainerBoxSkeleton title="Amenities">
              <AmenitiesSkeleton />
            </ContainerBoxSkeleton>
            {/* <ContainerBox title="Description">
              <Description text={description} />
            </ContainerBox> */}
          </div>
          <div className={styles.rightColumn}>
            <div className={styles.sticky}>
              <PriceInfoSkeleton />
              {/* <InquiryForm
                textareaPlaceholder="I would like more information about Sector 5, shanti nagar, anubhav society"
                contactPerson={'Owner'}
              /> */}
            </div>
          </div>
        </div>
      </Container>
      <div>
        <Container>
          <ContainerBox type="border" title="Ratings & Reviews">
            <RatingsAndReviews />
          </ContainerBox>
        </Container>

        {/* <SimilarProperties properties={similarProperties} location={location} /> */}
        <AboutLocation location={'Mira Road'} description={aboutDescription} />
      </div>
      <Footer />
    </>
  );
}

export function PriceInfoModal({ data }) {
  return (
    <Modal
      title="Price Info"
      centerTitle={true}
      trigger={
        <button
          style={{
            width: '100%',
            color: 'unset',
            padding: 'unset',
            background: 'unset',
            border: 'unset',
            boxShadow: 'unset',
            cursor: 'pointer',
          }}
        >
          <PriceInfo
            type={data[PROPERTY_DETAIL_NAME].propertyFor}
            price={Number(data[PROPERTY_PRICING_NAME].rent).toLocaleString()}
            availability={
              propertyDetailData.availability[
                data[PROPERTY_DETAIL_NAME].availability
              ]
            }
          />
        </button>
      }
    >
      <MorePriceInfo
        type={data[PROPERTY_DETAIL_NAME].propertyFor}
        price={Number(data[PROPERTY_PRICING_NAME].rent)}
        security={Number(data[PROPERTY_PRICING_NAME].security)}
        maintenanceCost={
          data[PROPERTY_PRICING_NAME].maintenanceCost?.price &&
          Number(data[PROPERTY_PRICING_NAME].maintenanceCost.price)
        }
        maintainceCostPeriod={
          data[PROPERTY_PRICING_NAME].maintenanceCost?.period
        }
      />
    </Modal>
  );
}

export function TabPlus({ children, className, ...props }) {
  return (
    <div className={classNames(styles.tabPlus, className)} {...props}>
      {children}
    </div>
  );
}

export function Mobile({ children, className, ...props }) {
  return (
    <div className={classNames(styles.mobile, className)} {...props}>
      {children}
    </div>
  );
}
