import { Symbols } from '@/data/symbols';

export const propertyReviewData = {
  stars: {
    [Symbols.Label]: 'Stars',
    [Symbols.Name]: 'stars',
    [Symbols.Validation]: {
      valueAsNumber: true,
      required: 'This field is required',
    },
  },
  lived: {
    tenant: 'Yes (tenant)',
    owner: 'Yes (owner)',
    no: 'No',
    [Symbols.Label]: 'Have you lived at this property?  (optional)',
    [Symbols.Name]: 'lived',
  },

  review: {
    [Symbols.Label]: 'Your Review',
    [Symbols.Name]: 'review',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  name: {
    [Symbols.Label]: 'Your Name',
    [Symbols.Name]: 'name',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  email: {
    [Symbols.Label]: 'Email',
    [Symbols.Name]: 'email',
    [Symbols.Validation]: {
      required: 'This field is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
};
