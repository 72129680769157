import styles from '@/styles/modules/home/services.module.css';

import classNames from 'classnames';

import Container from '@/components/container';
import { Heading } from '@/components/home/featured-properties';
import {
  Service1Image,
  Service2Image,
  Service3Image,
  Service4Image,
  Service5Image,
  Service6Image,
} from '@/components/images/services';

const services = [
  {
    title: 'Easy Property Search',
    description: `Find your dream home from the comfort of your couch. Explore a wide range of properties, from cozy apartments to luxurious villas.`,
    image: <Service1Image loading="lazy" />,
  },
  {
    title: 'Property Purchase and Rentals',
    description: `Explore curated listings.
Trust our expertise for personalized guidance, exclusive insights, and safe neighborhood recommendations.`,
    image: <Service2Image loading="lazy" />,
  },

  {
    title: 'Verification & Documentation',
    description: ` We've got you covered! We'll take care of all the documentation and Verification to make your house moving process a breeze`,
    image: <Service3Image loading="lazy" />,
  },
  {
    title: 'Minimal Charges',
    description: `Pay only a minimal service charge & enjoy a stress-free apartment search.
Well worth the time and effort you'll save.`,
    image: <Service4Image loading="lazy" />,
  },
  {
    title: 'Property Sale',
    description: `Ready to sell? We’ll create a customized strategy to attract verified buyers and achieve the best possible price.
Benefit from our strategic marketing and expert advice to maximize your property's value.`,
    image: <Service5Image loading="lazy" />,
  },
  {
    title: 'Property Management',
    description: `Trust us to find your ideal tenant.
We handle everything from property marketing to tenant screening, providing you with a stress-free property sale.`,
    image: <Service6Image loading="lazy" />,
  },
];

const Services = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.headingContainer}>
          <Heading>Services we offer</Heading>
        </div>
        <div className={styles.servicesContainer}>
          {services.map((service) => (
            <div className={styles.card} key={service.title}>
              <div className={styles.imageContainer}>{service.image}</div>
              <ContentContainer>
                <Content>
                  <h3>{service.title}</h3>
                  <pre>{service.description}</pre>
                </Content>
              </ContentContainer>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

function ContentContainer({ children, className }) {
  return (
    <div className={classNames(styles.contentContainer, className)}>
      {children}
    </div>
  );
}

function Content({ children, className }) {
  return (
    <div className={classNames(styles.content, className)}>{children}</div>
  );
}

export const Service = {
  Container: ContentContainer,
  Content,
};

export default Services;
