import { useEffect } from 'react';

import styles from '@/styles/modules/filters/price-range-filter.module.css';

import * as Slider from '@radix-ui/react-slider';

import { FormStack } from '@/components/form';
import FormInput from '@/components/form/input';
import { useFormContext } from '@/components/form/provider';
import {
  getPriceLabel,
  getPrices,
  priceRangeFilter,
  propertyForFilter,
} from '@/data/filters';
import { Symbols } from '@/data/symbols';
import { getSymbolValue } from '@/data/utils';

export const IN_LOCALE_CODE = 'en-IN';

function PriceRangeFilter() {
  const { setValue, watch } = useFormContext();

  const propertyForValue = watch(
    getSymbolValue(propertyForFilter, Symbols.Name),
  );

  const priceRangeMin = watch(
    getSymbolValue(priceRangeFilter.min, Symbols.Name),
  );
  const priceRangeMax = watch(
    getSymbolValue(priceRangeFilter.max, Symbols.Name),
  );

  const Prices = getPrices(propertyForValue);

  useEffect(() => {
    let [min, max] = [priceRangeMin, priceRangeMax];

    setValue(
      getSymbolValue(priceRangeFilter.min, Symbols.Name),
      !min ? Prices[0].value : min,
    );

    setValue(
      getSymbolValue(priceRangeFilter.max, Symbols.Name),
      !max ? Prices[Prices.length - 1].value : max,
    );
  }, [propertyForValue]);

  const steps = Prices.length - 1;

  return (
    <div
      style={{
        '--steps': steps + 1,
      }}
      className={styles.container}
    >
      <FormStack gap={1}>
        <FormInput
          // readOnly
          maxLength={10}
          rounded
          solidLabel
          decoration={{ start: 'MIN: ₹' }}
          type="text"
          value={priceRangeMin <= 0 ? '' : priceRangeMin}
          onChange={(e) => {
            const value = Number(e.target.value.replace(/[^\d]/g, ''));

            setValue(
              getSymbolValue(priceRangeFilter.min, Symbols.Name),
              value <= 0 ? undefined : value,
            );
          }}
          name={getSymbolValue(priceRangeFilter.min, Symbols.Name)}
          label={getSymbolValue(priceRangeFilter.min, Symbols.Label)}
          placeholder="Price Range"
        />
        <FormInput
          rounded
          solidLabel
          maxLength={10}
          decoration={{ start: 'MAX: ₹' }}
          type="text"
          value={
            priceRangeMax === Infinity ?
              Prices[Prices.length - 1].label +
              Prices[Prices.length - 1].decorator.end
            : priceRangeMax <= 0 ?
              ''
            : priceRangeMax
          }
          onChange={(e) => {
            const value = Number(e.target.value.replace(/[^\d]/g, ''));

            setValue(
              getSymbolValue(priceRangeFilter.max, Symbols.Name),
              value <= 0 ? undefined : value,
            );
          }}
          name={getSymbolValue(priceRangeFilter.max, Symbols.Name)}
          label={getSymbolValue(priceRangeFilter.max, Symbols.Label)}
          placeholder="Price Range"
        />
      </FormStack>
      <div>
        <Range steps={steps} Prices={Prices} />
        <div className={styles.sliderValues}>
          {Array.from({ length: steps + 1 }).map((_, index) => (
            <span
              key={index}
              style={{ left: calcStepMarkOffset(index, steps) }}
            >
              {getPriceLabel(Prices[index].label, {
                decorators: {
                  start: '₹ ' + (Prices[index].decorator?.start ?? ''),
                  end: Prices[index].decorator?.end,
                },
              })}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

function Range({ Prices, steps }) {
  const { setValue, getValues } = useFormContext();

  return (
    <Slider.Root
      onValueChange={([min, max]) => {
        max = Math.round(max / (10 + steps));
        min = Math.round(min / (10 + steps));

        const [priceRangeMin, priceRangeMax] = getValues([
          getSymbolValue(priceRangeFilter.min, Symbols.Name),
          getSymbolValue(priceRangeFilter.max, Symbols.Name),
        ]);

        if (priceRangeMin !== Prices[min].value) {
          setValue(
            getSymbolValue(priceRangeFilter.min, Symbols.Name),
            Prices[min].value,
          );
        }

        if (priceRangeMax !== Prices[max].value) {
          setValue(
            getSymbolValue(priceRangeFilter.max, Symbols.Name),
            Prices[max].value,
          );
        }
      }}
      className={styles.sliderRoot}
      defaultValue={[0, 100]}
      // value={[
      //   Math.max(
      //     0,
      //     Prices.length -
      //       1 -
      //       reverse(Prices).findIndex((price) => price <= priceRangeMin) || 0,
      //   ) *
      //     (10 + (steps + 1)),
      //   Prices.findIndex((price) => price >= priceRangeMax) *
      //     (10 + (steps + 1)),
      // ]}
      step={100 / steps}
      minStepsBetweenThumbs={0.5}
    >
      <Slider.Track className={styles.sliderTrack}>
        <Slider.Range className={styles.sliderRange} />
      </Slider.Track>
      <Slider.Thumb className={styles.sliderThumb} aria-label="Volume" />
      <Slider.Thumb className={styles.sliderThumb} aria-label="Volume" />
    </Slider.Root>
  );
}

// function findClosestPrice(price) {
//   return Prices.findIndex((p) => Math.abs(p - price) < Math.abs(p - Prices[0]));
// }

function reverse(arr) {
  return arr.reduce((acc, val) => [val, ...acc], []);
}

const THUMB_SIZE = 20;

function calcStepMarkOffset(index, maxIndex) {
  const percent = convertValueToPercentage(index, 0, maxIndex);
  const thumbInBoundsOffset = getThumbInBoundsOffset(THUMB_SIZE, percent, 1);
  return `calc(${percent}% + ${thumbInBoundsOffset}px)`;
}

function convertValueToPercentage(value, min, max) {
  const maxSteps = max - min;
  const percentPerStep = 100 / maxSteps;
  const percentage = percentPerStep * (value - min);
  return clamp(percentage, { max: 100, min: 0 });
}

function getThumbInBoundsOffset(width, left, direction) {
  const halfWidth = width / 2;
  const halfPercent = 50;
  const offset = linearScale([0, halfPercent], [0, halfWidth]);
  return (halfWidth - offset(left) * direction) * direction;
}

function linearScale(input, output) {
  return (value) => {
    if (input[0] === input[1] || output[0] === output[1]) return output[0];
    const ratio = (output[1] - output[0]) / (input[1] - input[0]);
    return output[0] + ratio * (value - input[0]);
  };
}

function clamp(value, range) {
  return Math.min(Math.max(value, range.min), range.max);
}

export default PriceRangeFilter;
