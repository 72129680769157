import { createContext, useContext } from 'react';

import styles from '@/styles/modules/form-group.module.css';

import classNames from 'classnames';

import { useAccessor } from '@/components/accessor';
import { useFormContext } from '@/components/form/provider';
import { Symbols } from '@/data/symbols';
import { getSymbolValue, isRequired } from '@/data/utils';

const configContext = createContext();

export const useFormGroupConfig = () => useContext(configContext);

const FormGroup = ({
  required,
  error,
  label,
  children,
  end,
  bottom,
  showLabel = true,
}) => {
  const { gap, useConfigGap } = useFormGroupConfig() ?? { gap: 1 };

  const { errors } = useFormContext();
  const accessor = useAccessor();

  required = required || isRequired(accessor);
  label = label || getSymbolValue(accessor, Symbols.Label);
  error = error || errors[getSymbolValue(accessor, Symbols.Name)];

  return (
    <div
      style={{ '--gap': gap }}
      className={classNames(styles.group, useConfigGap && styles.useConfigGap)}
    >
      {label && showLabel && (
        <span className={styles.label}>
          <span>
            {required && <span className={styles.required}>*</span>}
            {label}
          </span>
          {end}
        </span>
      )}
      {children}
      {bottom && <div className={styles.bottom}>{bottom}</div>}
      {error && <p className={styles.error}>{error.message}</p>}
    </div>
  );
};

export function FormGroupConfig({ children, ...props }) {
  return (
    <configContext.Provider value={{ ...props }}>
      {children}
    </configContext.Provider>
  );
}

export default FormGroup;
