import styles from '@/styles/modules/logo.module.css';

import classNames from 'classnames';

export const NavBarTypes = {
  ForDarkBackground: 'forDarkBackground',
  ForLightBackground: 'forLightBackground',
};

export const LogoSizes = {
  Small: 'small',
  Large: 'large',
};

const Logo = ({ type = NavBarTypes.ForLightBackground, size }) => {
  return (
    <div className={classNames(styles.logo, styles[size])}>
      {type === NavBarTypes.ForDarkBackground && (
        <svg
          width="48"
          height="28"
          viewBox="0 0 48 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M47.4776 27.03L23.8089 6.51242L12.2896 16.4211L0 27.03V20.3075L23.8089 0L47.4776 20.3075V27.03Z"
            fill="url(#paint0_linear_175_8293)"
          />
          <path
            d="M9.7334 22.775L23.8434 10.0303L37.7438 22.705L23.8434 11.4308L9.7334 22.775Z"
            fill="#F5DBC2"
          />
          <rect
            x="19.6592"
            y="19.7754"
            width="3.36125"
            height="3.36125"
            fill="url(#paint1_linear_175_8293)"
          />
          <rect
            x="24.457"
            y="19.7754"
            width="3.36125"
            height="3.36125"
            fill="url(#paint2_linear_175_8293)"
          />
          <rect
            x="19.6592"
            y="24.5732"
            width="3.36125"
            height="3.36125"
            fill="url(#paint3_linear_175_8293)"
          />
          <rect
            x="24.457"
            y="24.5732"
            width="3.36125"
            height="3.36125"
            fill="url(#paint4_linear_175_8293)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_175_8293"
              x1="23.7388"
              y1="0"
              x2="23.7388"
              y2="27.03"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CBAB81" />
              <stop offset="0.49" stop-color="#FDE4CF" />
              <stop offset="1" stop-color="#CBAB81" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_175_8293"
              x1="21.3398"
              y1="19.7754"
              x2="21.3398"
              y2="23.1366"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DBBD9A" />
              <stop offset="1" stop-color="#F5DBC2" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_175_8293"
              x1="26.1377"
              y1="19.7754"
              x2="26.1377"
              y2="23.1366"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DBBD9A" />
              <stop offset="1" stop-color="#F5DBC2" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_175_8293"
              x1="21.3398"
              y1="24.5732"
              x2="21.3398"
              y2="27.9345"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DBBD9A" />
              <stop offset="1" stop-color="#F5DBC2" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_175_8293"
              x1="26.1377"
              y1="24.5732"
              x2="26.1377"
              y2="27.9345"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DBBD9A" />
              <stop offset="1" stop-color="#F5DBC2" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {type === NavBarTypes.ForLightBackground && (
        <svg
          width="48"
          height="28"
          viewBox="0 0 48 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M47.4776 27.03L23.8089 6.51242L12.2896 16.4211L0 27.03V20.3075L23.8089 0L47.4776 20.3075V27.03Z"
            fill="url(#paint0_linear_5771_8767)"
          />
          <path
            d="M9.7334 22.775L23.8434 10.0303L37.7438 22.705L23.8434 11.4308L9.7334 22.775Z"
            fill="#AC8259"
          />
          <rect
            x="19.6592"
            y="19.7754"
            width="3.36125"
            height="3.36125"
            fill="url(#paint1_linear_5771_8767)"
          />
          <rect
            x="24.457"
            y="19.7754"
            width="3.36125"
            height="3.36125"
            fill="url(#paint2_linear_5771_8767)"
          />
          <rect
            x="19.6592"
            y="24.5732"
            width="3.36125"
            height="3.36125"
            fill="url(#paint3_linear_5771_8767)"
          />
          <rect
            x="24.457"
            y="24.5732"
            width="3.36125"
            height="3.36125"
            fill="url(#paint4_linear_5771_8767)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5771_8767"
              x1="23.7388"
              y1="0"
              x2="23.7388"
              y2="27.03"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A87E47" />
              <stop offset="0.49" stop-color="#FFDEC2" />
              <stop offset="1" stop-color="#AE7F41" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5771_8767"
              x1="21.3398"
              y1="19.7754"
              x2="21.3398"
              y2="23.1366"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A57A46" />
              <stop offset="1" stop-color="#F7D9BC" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5771_8767"
              x1="26.1377"
              y1="19.7754"
              x2="26.1377"
              y2="23.1366"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A57A46" />
              <stop offset="1" stop-color="#F7D9BC" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_5771_8767"
              x1="21.3398"
              y1="24.5732"
              x2="21.3398"
              y2="27.9345"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A57A46" />
              <stop offset="1" stop-color="#F7D9BC" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_5771_8767"
              x1="26.1377"
              y1="24.5732"
              x2="26.1377"
              y2="27.9345"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A57A46" />
              <stop offset="1" stop-color="#F7D9BC" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <div className={classNames(styles.logoText, styles[type])}>
        <p>Dylan Estate</p>
      </div>
    </div>
  );
};

export default Logo;
