import styles from '@/styles/modules/container.module.css';

const Container = ({ children, ...props }) => {
  return (
    <div {...props} className={[styles.container, props.className].join(' ')}>
      {children}
    </div>
  );
};

export default Container;
