import { Symbols } from '@/data/symbols';

export const ScheduleVisit = {
  visitType: {
    inPerson: 'In Person',
    virtual: 'Virtual Tour(via Video call)',
    [Symbols.Name]: 'visitType',
    [Symbols.Label]: 'Visit Type',
    [Symbols.Validation]: {
      required: 'Visit Type is required',
    },
  },

  visitDate: {
    [Symbols.Name]: 'visitDate',
    [Symbols.Label]: 'Visit Date',
    [Symbols.Validation]: {
      required: 'Visit Date is required',
      valueAsDate: true,
    },
  },

  visitTime: {
    [Symbols.Name]: 'visitTime',
    [Symbols.Label]: 'Visit Time',
    [Symbols.Validation]: {
      required: 'Visit Time is required',
      valueAsTime: true,
    },
  },
};
