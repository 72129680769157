export const PATHS = {
  HOME: '/',
  PROPERTIES: '/properties',
  SEARCH: '/search',
  REGISTER: '/register',
  PROPERTY_LISTING: '/form',
  PROPERTY_PREVIEW: '/property',
  THANKYOU: '/thankyou/:id',
  DASHBOARD: '/dashboard/listings',
  LISTINGS: '/dashboard/listings',
  ACTIVITY: {
    VISITS: '/dashboard/activity/visits',
    INQUIRIES: '/dashboard/activity/inquiries',
    SHORTLISTED: '/dashboard/activity/shortlisted',
    SEARCHES: '/dashboard/activity/searches',
    NOT_INTERESTED: '/dashboard/more/not-interested',
  },
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  ADMIN: {
    PATH: '/admin',
    PROFILE: '/admin/profile',
    URGENT_ACTIONS: '/admin/urgent-actions',
    PROPERTIES: '/admin/properties',
    INQUERIES: '/admin/inqueries',
    POST_RPROPERTY: '/admin/post-property',
    PENDING_APPROVAL: '/admin/pending-approval',
    SCHEDULED_VISITS: '/admin/scheduled-visits',
    SCHEDULE_VISIT: '/admin/schedule-visit',
  },
};
