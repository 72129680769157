import styles from '@/styles/modules/amenities.module.css';

import AmenityItem, {
  AmenityItemSkeleton,
} from '@/components/preview/amenties-item';
import propertyFeaturesData from '@/data/property-features';
import { getIcon } from '@/data/utils';

const Amenities = ({ data }) => {
  if (!data) return null;

  const items = Object.values(data)
    .filter(Boolean)
    .map((key) => ({
      label: propertyFeaturesData.amenities[key],
      icon: getIcon(propertyFeaturesData.amenities[key]),
    }));

  return (
    <div className={styles.grid}>
      {items.map((item, index) => (
        <AmenityItem key={index} icon={item.icon} label={item.label} />
      ))}
    </div>
  );
};

export const AmenitiesSkeleton = () => {
  return (
    <div className={styles.grid}>
      {Array.from({ length: 6 }).map((_, index) => (
        <AmenityItemSkeleton key={index} />
      ))}
    </div>
  );
};

export default Amenities;
