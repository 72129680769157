import React, { useState } from 'react';

import styles from '@/styles/modules/explore-neighbourhood.module.css';

import * as Accordion from '@radix-ui/react-accordion';

import {
  AccordionContent,
  AccordionTrigger,
} from '@/components/filters/property-type-filter';
import { Icon, icons } from '@/components/icons';
import {
  CustomMarker,
  MapProvider,
  OlaMap,
  useOlaMap,
} from '@/components/ola-map-component';

export const ExploreNeighborhood = ({ data, location }) => {
  const tabValues = Object.keys(data);

  const [activeTab, setActiveTab] = useState(tabValues[0]);

  return (
    <MapProvider>
      <div className={styles.container}>
        <div className={styles.map}>
          <LocationSearch location={'Abhinave society'} />
          <Map location={location} />
        </div>

        <div className={styles.sidebar}>
          <div className={styles.tabs}>
            <button
              className={`${styles.tab} ${activeTab === 'transit' ? styles.active : ''}`}
              onClick={() => setActiveTab('transit')}
            >
              Transport
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'essential' ? styles.active : ''}`}
              onClick={() => setActiveTab('essential')}
            >
              Essentials
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'utility' ? styles.active : ''}`}
              onClick={() => setActiveTab('utility')}
            >
              Utility
            </button>
          </div>
          {<TransportTabs data={data[activeTab]} />}
        </div>
      </div>
    </MapProvider>
  );
};

export const LocationSearch = ({ location }) => {
  return (
    <div className={styles.search}>
      <Icon src={icons.locationHome} />
      <span className={styles.location}>{location}</span>

      <div className={styles.searchBox}>
        <span>To:</span>
        <input type="text" placeholder="search" className={styles.input} />
      </div>
      {/* <button className={styles.searchButton}>Search</button> */}
    </div>
  );
};

const OlaMapsApiKey = import.meta.env.VITE_OLA_MAPS_API_KEY;

export const Map = ({ location }) => {
  return (
    <div className={styles.mapContainer}>
      <OlaMap location={location} zoom={12} apikey={OlaMapsApiKey}>
        <CustomMarker src={icons.locationHome} location={location} />
      </OlaMap>
    </div>
  );
  1;
};

// TransportTabs.js
export const TransportTabs = ({ data }) => {
  return (
    <div className={styles.transportTabs}>
      <Accordion.Root
        className={styles.accordionRoot}
        type="single"
        collapsible={true}
        defaultValue={['bus_station']}
      >
        {Object.keys(data).map((key) => {
          return (
            <Accordion.Item
              className={styles.accordionItem}
              value={key}
              key={key}
            >
              <AccordionTrigger className={styles.accordionTrigger}>
                {key.replaceAll('_', ' ')}
              </AccordionTrigger>
              <AccordionContent className={styles.accordionContent}>
                <TransportSection items={data[key]} />
              </AccordionContent>
            </Accordion.Item>
          );
        })}
      </Accordion.Root>

      {/* <TransportSection title="Bus Station" items={data.bus_station} />
      <TransportSection title="Railway Station" items={data.train_station} />
      <TransportSection title="Metro stations" items={data.subway_station} />
      <TransportSection title="Airport" items={[]} /> */}
    </div>
  );
};

const TransportSection = ({ items }) => {
  const { setDestinationPoint } = useOlaMap();

  return (
    <div className={styles.section}>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              console.log('item', item);
              const { latitude: lat, longitude: lng } = item.location;
              setDestinationPoint({
                lat,
                lng,
                popUpData: item,
              });
            }}
            className={styles.listItem}
          >
            <span>{item.displayName.text}</span>
            <span className={styles.distance}>
              {formatDistance(item.distance)} | {formatDuration(item.duration)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export function formatDistance(distance) {
  if (distance < 1000) {
    return `${distance} m`;
  } else {
    return `${(distance / 1000).toFixed(1)} km`;
  }
}

/**
 *
 * @example
 * formatDuration(120) => 2 mins
 * formatDuration(3660) => 1 hr 1 min
 * @param {number} duration
 * @returns {string}
 */
export function formatDuration(duration) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  if (hours > 0 && minutes > 0) return `${hours} hrs ${minutes} mins`;
  if (hours > 0) return `${hours} hrs`;
  if (minutes > 0) return `${minutes} mins`;
  return `${seconds} secs`;
}
