import React, { useEffect, useState } from 'react';

import styles from '@/styles/modules/home/hero.module.css';

import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';

import Container from '@/components/container';
import AboutUs from '@/components/home/about-us';
import ClientReviews from '@/components/home/client-reviews';
import ContactInfo from '@/components/home/contact-info';
import FeaturedProperties, {
  Heading,
} from '@/components/home/featured-properties';
import Header from '@/components/home/header';
import HomeFooter from '@/components/home/home-footer';
import SearchBar from '@/components/home/search-bar';
import Services from '@/components/home/service';
import { HeroImage } from '@/components/images/hero';
import NavBar from '@/components/nav';
import Footer from '@/components/preview/Footer';
import { useUser } from '@/contexts/auth-context';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
} from '@/data/preporty-detail';
import propertyLocationData, {
  PROPERTY_LOCATION_NAME,
} from '@/data/property-location';
import { getName } from '@/data/utils';
import { FeaturedPropertyCard, RenderProperties } from '@/pages/search';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useRef } from 'react';

import { ConfitteProvider } from '@/components/confitte';

const Hero = React.forwardRef(function (props, ref) {
  return (
    <div ref={ref} className={styles.hero}>
      <div className={styles.heroMain}>
        <HeroImage className={styles.heroImage} />
        <Header />
      </div>
      <SearchBar />
      <p className={styles.description}>
        Residential, commercial and office spaces for rent and sale in miraroad.
      </p>
    </div>
  );
});

export function Component() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <Main>
      <NavBar variant={inView && 'ghost'} />
      <Hero ref={ref} />
      {/* <FeaturedProperties /> */}
      <Services />
      <AboutUs />
      <ClientReviews />

      <HomesForYou />
      <ContactInfo />
      <HomeFooter />

      <Footer />
    </Main>
  );
}

function getHomesForYou(user, userData) {
  return new Promise(async (res, rej) => {
    const { getHomeForYouProperties } = await import('@/lib/db');
    const first5VisitedProperties = userData.visitedProperties.slice(0, 5);

    let propertyFor = first5VisitedProperties.map(
      (property) =>
        property[PROPERTY_DETAIL_NAME][getName(propertyDetailData.propertyFor)],
    );

    let propertyType = first5VisitedProperties.map(
      (property) =>
        property[PROPERTY_DETAIL_NAME][
          getName(propertyDetailData.propertyType)
        ],
    );

    let propertyTypeValues = first5VisitedProperties.map(
      (property) =>
        property[PROPERTY_DETAIL_NAME][
          getName(propertyDetailData.propertyTypeValues)
        ],
    );

    let locationTerms = first5VisitedProperties
      .flatMap((property) => [
        property[PROPERTY_LOCATION_NAME][
          getName(propertyLocationData.landmark)
        ],
        property[PROPERTY_LOCATION_NAME][
          getName(propertyLocationData.locality)
        ],
      ])
      .flatMap((loaction) => String(loaction).split(','))
      .map((e) => String(e).toLowerCase().trim());

    propertyFor = getMostRepeatedValue(propertyFor);
    propertyType = getMostRepeatedValue(propertyType);
    propertyTypeValues = getMostRepeatedValue(propertyTypeValues);
    locationTerms = getMostRepeatedValue(locationTerms);

    console.log(propertyFor, propertyType, propertyTypeValues, locationTerms);

    const data = await getHomeForYouProperties(
      propertyFor[0],
      propertyType[0],
      propertyTypeValues[0],
      locationTerms,
    );

    console.log('Home for you data', data);
    res(data);
  });
}

function getMostRepeatedValue(array) {
  const counts = new Map();
  for (const value of array) {
    counts.set(value, (counts.get(value) || 0) + 1);
  }
  return [...counts.entries()]
    .sort((a, b) => b[1] - a[1])
    .map(([value]) => value);
}

function removeDuplicates(array) {
  return [...new Set(array)];
}

let settings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: false,
  adaptiveHeight: true,
  slidesToShow: 3,
  slidesToScroll: 3,
};

function ArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="44"
      fill="none"
      viewBox="0 0 47 44"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#122B49"
        strokeWidth="0.8"
        d="M23.5 43.4c12.62 0 22.9-9.556 22.9-21.4S36.12.6 23.5.6.6 10.156.6 22s10.28 21.4 22.9 21.4z"
      ></path>
      <path
        stroke="#122B49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 30l8-8-8-8"
      ></path>
    </svg>
  );
}

function HomesForYou() {
  const { user, userData } = useUser();
  const [data, setData] = useState([]);

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  useEffect(() => {
    if (!user || !userData || !userData.visitedProperties) return;

    getHomesForYou(user, userData).then((data) => {
      data && setData(data);
    });
  }, [user, userData]);

  if (!data || data.length < 1) return null;
  return (
    <section className={styles.homesForYou}>
      <Container>
        <div className={styles.heading}>
          <div>
            <Heading sub>Homes for You</Heading>
            <p className={styles.subHeading}>
              Based on homes you recently viewed
            </p>
          </div>
          <div className={styles.buttons}>
            <button onClick={previous}>
              <ArrowRight style={{ transform: 'rotate(180deg)' }} />
            </button>
            <button onClick={next}>
              <ArrowRight />
            </button>
          </div>
        </div>
        <div>
          <ConfitteProvider>
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
              responsive={[
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },

                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {data.map(({ data, id }) => (
                <div>
                  <div key={id} className={styles.cardContainer}>
                    <FeaturedPropertyCard key={id} data={data} id={id} />
                  </div>
                </div>
              ))}
            </Slider>
          </ConfitteProvider>
        </div>
      </Container>
    </section>
  );
}

export function Main({ children, ...props }) {
  return (
    <main
      id="main"
      style={{
        isolation: 'isolate',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 0,
        scrollBehavior: 'smooth',
      }}
    >
      {children}
    </main>
  );
}
