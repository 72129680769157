import React from 'react';

import styles from '@/styles/modules/pagination.module.css';

import { Icon, icons } from '@/components/icons';

const Pagination = ({
  disableLeftArrow,
  disableRightArrow,
  onlyArrows,
  currentPage,
  totalPages,
  onPageChange,
  maxButtons = 5,
}) => {
  const getPageNumbers = () => {
    const halfButtons = Math.floor(maxButtons / 2);
    let start = Math.max(currentPage - halfButtons, 1);
    let end = Math.min(start + maxButtons - 1, totalPages);

    if (end - start + 1 < maxButtons) {
      start = Math.max(end - maxButtons + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const pageNumbers = getPageNumbers();

  if (totalPages === 1) return null;

  return (
    <div className={styles.pagination}>
      {(onlyArrows || (totalPages > maxButtons && currentPage > 1)) && (
        <button
          disabled={disableLeftArrow}
          className={styles.arrow}
          onClick={() => onPageChange(currentPage - 1)}
          aria-label="Previous page"
        >
          <Icon src={icons.arrowLeftSmall} />
          {onlyArrows && <span>Prev</span>}
        </button>
      )}

      {!onlyArrows &&
        pageNumbers.map((number) => (
          <button
            key={number}
            className={`${styles.pageButton} ${number === currentPage ? styles.active : ''}`}
            onClick={() => onPageChange(number)}
          >
            {number}
          </button>
        ))}

      {(onlyArrows ||
        (totalPages > maxButtons && currentPage < totalPages)) && (
        <button
          disabled={disableRightArrow}
          className={styles.arrow}
          onClick={() => onPageChange(currentPage + 1)}
          aria-label="Next page"
        >
          {onlyArrows && <span>Next</span>}
          <Icon src={icons.arrowRightSmall} />
        </button>
      )}
    </div>
  );
};

export default Pagination;
