import React from 'react';

import styles from '@/styles/modules/home/home-footer.module.css';

import { Link } from 'react-router-dom';

import Container from '@/components/container';
import { PATHS } from '@/constants/paths';

const HomeFooter = () => (
  <div className={styles.footer}>
    <Container>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3>Find a Home</h3>
          <p>Select from a curated list of properties</p>
          <Link to={PATHS.PROPERTIES} className={styles.footerButton}>
            View Properties
          </Link>
        </div>
        <div className={styles.footerSection}>
          <h3>List Your Property</h3>
          <p>For Free</p>
          <Link to={PATHS.PROPERTY_LISTING} className={styles.footerButton}>
            Free Posting
          </Link>
        </div>
      </div>
    </Container>
  </div>
);

export default HomeFooter;
