import React, { useEffect } from 'react';

import styles from '@/styles/modules/property-listing.module.css';

import { AccessorProvider } from '@/components/accessor';
import { FormBody, FormFooter, FormStack } from '@/components/form';
import FormGroup from '@/components/form/group';
import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import RadioInput from '@/components/form/radio';
import FormSelect from '@/components/form/select';
import data, { PROPERTY_DETAIL_NAME } from '@/data/preporty-detail';
import { getName, getValues } from '@/data/utils';
import { useGlobalState } from '@/store/global-state';

const PropertyDetailsForm = React.forwardRef((props, ref) => {
  const { getSavedDate } = useGlobalState();

  return (
    <FormProvider
      id={props.id}
      options={{ defaultValues: getSavedDate(PROPERTY_DETAIL_NAME) }}
    >
      <Form {...props} />
    </FormProvider>
  );
});

function Form({ id, onClickNext, Footer }) {
  const { updateState } = useGlobalState();

  const {
    handleSubmit,
    errors,
    watch,
    setValue,
    resetField,
    getValues: getFormValues,
  } = useFormContext();

  const onSubmit = (data) => {
    updateState((state) => {
      state[PROPERTY_DETAIL_NAME] = data;
    });

    onClickNext();
  };

  const _propertyType = watch('_propertyType');

  useEffect(() => {
    console.log(getFormValues());

    resetField(getName(data.propertyType));

    if (_propertyType === getName(data.propertyTypeValues.landPlot))
      setValue(getName(data.propertyType), _propertyType);
  }, [_propertyType]);

  return (
    <form id={id} className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <FormBody>
        <AccessorProvider accessor={data.propertyFor}>
          <FormGroup>
            <RadioInput />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.propertyType}>
          <FormGroup
            error={
              errors[getName(data.propertyTypeValues)] ??
              errors[getName(data.propertyType)]
            }
          >
            <AccessorProvider accessor={data.propertyTypeValues}>
              <RadioInput />
            </AccessorProvider>
            {_propertyType && (
              // <div
              //   style={{
              //     width: '60%',
              //   }}
              // >
              <RadioInput
                type="button"
                options={getValues(data.propertyTypeValues[_propertyType])}
              />
              // </div>
            )}
          </FormGroup>
        </AccessorProvider>
        <FormStack>
          <AccessorProvider accessor={data.builtUpArea}>
            <FormGroup>
              <FormInput
                type="number"
                decoration={{ end: 'Sq. Ft.' }}
                placeholder="0"
              />
            </FormGroup>
          </AccessorProvider>

          <AccessorProvider accessor={data.carpetArea}>
            <FormGroup>
              <FormInput
                type="number"
                decoration={{ end: 'Sq. Ft.' }}
                placeholder="0"
              />
            </FormGroup>
          </AccessorProvider>
        </FormStack>
        <FormStack>
          <FormStack>
            <AccessorProvider accessor={data.propertyOnFloor}>
              <FormGroup>
                <FormInput placeholder="Floor" />
              </FormGroup>
            </AccessorProvider>
            <AccessorProvider accessor={data.totalFloors}>
              <FormGroup>
                <FormInput type="number" placeholder="Total Floors" />
              </FormGroup>
            </AccessorProvider>
          </FormStack>
          <AccessorProvider accessor={data.propertyFacing}>
            <FormGroup>
              <FormSelect placeholder="Select" />
            </FormGroup>
          </AccessorProvider>
        </FormStack>
        <AccessorProvider accessor={data.propertyAge}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.bhkType}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.bedrooms}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.bathrooms}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.balcony}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.tenetPreference}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.availability}>
          <FormGroup>
            <RadioInput type="button" />
          </FormGroup>
        </AccessorProvider>
        <AccessorProvider accessor={data.propertyDescription}>
          <FormGroup>
            <FormInput
              placeholder="Add a description for your property to attract the best tenant"
              type="textarea"
            />
          </FormGroup>
        </AccessorProvider>
      </FormBody>

      <div className={styles.footer}>{<Footer />}</div>
    </form>
  );
}

export default PropertyDetailsForm;
