// create a small placeholder and import its metadata
import {
  height,
  src as placeholder,
  width,
} from '@/assets/images/home/hero.png?w=300&as=metadata';
import srcsetAvif from '@/assets/images/home/hero.png?w=900;1200;1800&format=avif&as=srcset';
import srcsetWebp from '@/assets/images/home/hero.png?w=900;1200;1800&format=webp&as=srcset';
import Image from '@/components/Image';

export function HeroImage({ ...rest }) {
  return (
    <Image
      avif={srcsetAvif}
      webp={srcsetWebp}
      placeholder={placeholder}
      width={width}
      height={height}
      {...rest}
    />
  );
}
