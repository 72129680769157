import { createContext, useContext, useState } from 'react';

import ConfettiExplosion from 'react-confetti-explosion';

const confitteContext = createContext();

export const useConfitte = () => useContext(confitteContext);

export const ConfitteProvider = ({ children }) => {
  const [isExploding, setIsExploding] = useState(false);

  return (
    <confitteContext.Provider
      value={{
        isExploding,
        setIsExploding,
      }}
    >
      {children}
    </confitteContext.Provider>
  );
};

export const ConfitteExplosion = ({ id }) => {
  const { isExploding, setIsExploding } = useConfitte();

  return (
    isExploding &&
    isExploding === id && (
      <ConfettiExplosion
        {...{
          force: 0.4,
          duration: 2200,
          particleCount: 30,
          width: 400,
        }}
        onComplete={() => setIsExploding(false)}
      />
    )
  );
};
