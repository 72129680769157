import styles from '@/styles/modules/notification-panel.module.css';

import { useUser } from '@/contexts/auth-context';

export function NotificationPanel() {
  const { user, userData } = useUser();

  if (!user)
    return (
      <div className={styles.panel}>
        <h2 className={styles.heading}>Notifications</h2>
        <div className={styles.notificationContainer}>
          <div className={styles.notification}>
            <pre>User not logged in</pre>{' '}
          </div>
        </div>
      </div>
    );

  const notifications = userData?.notifications;

  return (
    <div className={styles.panel}>
      <h2 className={styles.heading}>Notifications</h2>
      <div className={styles.notificationContainer}>
        {notifications && notifications.length > 0 ?
          notifications
            .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
            .map((notification) => (
              <div className={styles.notification} key={notification.id}>
                <pre
                  dangerouslySetInnerHTML={{
                    __html: notification.message,
                  }}
                ></pre>
              </div>
            ))
        : <div className={styles.notification}>
            <pre>No notifications</pre>
          </div>
        }
      </div>
      {/* <button className={styles.viewAllButton}>View all Notifications</button> */}
    </div>
  );
}
