import styles from '@/styles/modules/inquery.module.css';

import classNames from 'classnames';

import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import { Icon, icons } from '@/components/icons';
import { PhoneInput } from '@/components/register-form';

import '@/styles/calendar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Slider from 'react-slick';

import CheckboxGroup from '@/components/form/checkbox';
import FormGroup, { FormGroupConfig } from '@/components/form/group';
import { PropertyVisitForm } from '@/components/misc/visit-scheduling';
import { useUser } from '@/contexts/auth-context';
import { useProfile } from '@/contexts/profile-context';
import {
  getPropertyLocation,
  getPropertyTitle,
} from '@/pages/property-preview';

export const types = {
  minimal: 'minimal',
  rawForm: 'rawForm',
};

const DEFAULT_OPTIONS = {
  defaultValues: {
    name: '',
    email: '',
    phone: { code: '91', number: '' },
  },
};

export default function InquiryForm({ ...props }) {
  const { user } = useUser();

  if (props.type === types.minimal || !user || props.type === types.rawForm) {
    return (
      <FormProvider options={DEFAULT_OPTIONS}>
        <Form {...props} />
      </FormProvider>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        maxWidth: '100%',
        contain: 'size',
      }}
    >
      {/* <div className={styles.sliderContainer}>
          <PropertyVisitForm {...props} />
        </div> */}
      <Slider
        slidesToScroll={1}
        slidesToShow={1}
        infinite={true}
        autoplay={true}
        autoplaySpeed={2000}
        pauseOnFocus={true}
        speed={500}
        arrows={false}
        swipeToSlide={false}
        swipe={false}
      >
        <PropertyInqueryForm {...props} />
        <PropertyVisitScheduleForm {...props} />
      </Slider>
    </div>
  );
}

export function PropertyInqueryForm({ ...props }) {
  return (
    <FormProvider options={DEFAULT_OPTIONS}>
      <div className={styles.sliderContainer}>
        <Form {...props} />
      </div>
    </FormProvider>
  );
}

export function PropertyVisitScheduleForm({ ...props }) {
  return (
    <FormProvider>
      <div className={styles.sliderContainer}>
        <PropertyVisitForm {...props} />
      </div>
    </FormProvider>
  );
}

const Form = (props) => {
  const { profile } = useProfile();

  const { user } = useUser();

  const { name, phoneNumber } = profile;

  const { handleSubmit, register, errors, reset } = useFormContext();

  const onSubmit = async (data) => {
    console.log(data);

    const { sendInquiry } = await import('@/lib/actions');

    try {
      console.log('Inquery form props are', props);
      const propertyData = props.propertyDetails;

      console.log('propertyData', propertyData);
      if (propertyData) {
        const context = {
          propertyId: propertyData.id,
          propertyTitle: getPropertyTitle(propertyData, ['bhk', 'area', 'for']),
          propertyLocation: getPropertyLocation(propertyData, 'long'),
          phoneNumber: user && user.phoneNumber,
        };

        await sendInquiry(
          {
            ...data,
            ...{
              createdBy: user.uid,
            },
            context,
          },
          'properties',
        );
      } else {
        await sendInquiry(data);
      }

      window.alert('Inquiry sent successfully');

      reset();
    } catch (e) {
      console.error(e);
      window.alert('Something went wrong');
    }
  };

  return (
    <div className={classNames(styles.container, styles[props.type])}>
      {props.type !== types.rawForm &&
        (props.title ?
          <h1 className={styles.title}>{props.title}</h1>
        : <h2 className={styles.title}>Send an Inquiry for this property?</h2>)}

      {props.type !== types.minimal && props.type !== types.rawForm && (
        <>
          <p className={styles.contactPerson}>Contact Person : {name}</p>
          <div className={styles.phoneDisplay}>
            <span className={styles.phoneIcon}>
              <Icon src={icons.phone} />
            </span>
            <span>{phoneNumber}</span>
          </div>
        </>
      )}

      <FormGroupConfig gap={0}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={errors.name}>
            <FormInput
              decoration={{ end: '*' }}
              className={styles.input}
              name="name"
              placeholder="Name"
              validation={{ required: 'Name is required' }}
            />
          </FormGroup>

          <FormGroup error={errors.email}>
            <FormInput
              decoration={{ end: '*' }}
              className={styles.input}
              name="email"
              placeholder="Email"
              validation={{
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
            />
          </FormGroup>

          <FormGroup error={errors.phone?.number}>
            <PhoneInput
              staticCode
              placeHolder={'Mobile No. (optional)'}
              required={false}
              className={styles.input}
            />
          </FormGroup>

          <textarea
            placeholder={props.textareaPlaceholder || 'Type you message here'}
            {...register('message')}
            className={styles.textarea}
          />
          {props.type === types.rawForm && (
            <FormGroup error={errors.confirmation}>
              <CheckboxGroup
                name="confirmation"
                type="square"
                cols={1}
                options={[
                  {
                    label:
                      'By submitting this form, you agree to be contacted by our team regarding your inquiry.',
                  },
                ]}
                validation={{
                  required:
                    'This field is required to proceed with your inquiry',
                }}
              />
            </FormGroup>
          )}
          <button type="submit" className={styles.submitButton}>
            SEND INQUIRY
          </button>
        </form>
      </FormGroupConfig>
    </div>
  );
};
export { Form as InqueryForm };
