import { useEffect, useState } from 'react';

import styles from '@/styles/modules/home/search-bar.module.css';

import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Filter, { FilterTypes } from '@/components/filters/filter';
import FormProvider, { useFormContext } from '@/components/form/provider';
import { Icon, icons } from '@/components/icons';
import { Modal } from '@/components/Modal';
import { RequireSignInButton } from '@/components/preview/preproty-card';
import { CustomSearchBox } from '@/components/property-forms/location';
import { useTost } from '@/components/toast';
import { PATHS } from '@/constants/paths';
import { useUser } from '@/contexts/auth-context';
import {
  additionalFeaturesFilter,
  deserializeSearchParams,
  getPriceLabel,
  getPrices,
  locationFilter,
  priceRangeFilter,
  propertyForFilter,
  propertyTypeFiter,
  propertyTypeValuesFilter,
} from '@/data/filters';
import propertyDetailData from '@/data/preporty-detail';
import { Symbols } from '@/data/symbols';
import { getLabel, getName, getSymbolValue, getValues } from '@/data/utils';

export function getDefaultValues(searchParams) {
  return searchParams.size > 0 ?
      deserializeSearchParams(searchParams)
    : {
        [getSymbolValue(locationFilter, Symbols.Name)]: [],
        [getSymbolValue(propertyForFilter, Symbols.Name)]: 'rent',
      };
}

function SearchBar({ useSecondarySearchBar, tabsChildren }) {
  const [searchParams] = useSearchParams();

  const options = {
    defaultValues: getDefaultValues(searchParams),
  };

  return (
    <FormProvider options={options} id="filter-form">
      <Form
        tabsChildren={tabsChildren}
        useSecondarySearchBar={useSecondarySearchBar}
      />
    </FormProvider>
  );
}

function Form({ useSecondarySearchBar, tabsChildren }) {
  const [searchbBarToggle, setSearchBarToggle] = useState(
    useSecondarySearchBar,
  );

  const { handleSubmit, reset } = useFormContext();

  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    reset(getDefaultValues(searchParams));
  }, [searchParams]);

  function searchSubmitHandler(data) {
    //if path is equal to search path
    if (window.location.pathname === PATHS.SEARCH) setSearchBarToggle(true);

    if (searchParams.toString() === searlizeSearchParams(data).toString())
      return;

    navigate(PATHS.SEARCH + '?' + searlizeSearchParams(data).toString());
  }

  return (
    <form
      id="filters"
      onSubmit={handleSubmit(searchSubmitHandler)}
      className={styles.searchBar}
    >
      <div className={styles.mobileContainer}>
        <Tabs />

        <Modal
          title="Filters"
          trigger={
            <div
              style={{
                cursor: 'pointer',
              }}
              className={styles.container}
            >
              <p>Search locality, neighborhood, landmark</p>
              <SearchButton />
            </div>
          }
          footer={
            <div
              style={{
                display: 'flex',
                padding: 'var(--spacing)',
              }}
            >
              <button form="filters" type="submit" style={{ flex: 1 }}>
                View properties
              </button>
            </div>
          }
        >
          <Filters raw={true}>
            <Tabs />
          </Filters>
        </Modal>
      </div>

      <div className={styles.desktopContainer}>
        {searchbBarToggle ?
          <SecondarySearchBar setSearchBarToggle={setSearchBarToggle} />
        : <>
            <Tabs children={tabsChildren} />

            <Filters />
          </>
        }
      </div>
    </form>
  );
}

function Filters({ children, raw }) {
  const { watch } = useFormContext();
  const propertyType = watch(getSymbolValue(propertyTypeFiter, Symbols.Name));

  const priceRangeMin = watch(
    getSymbolValue(priceRangeFilter.min, Symbols.Name),
  );
  const priceRangeMax = watch(
    getSymbolValue(priceRangeFilter.max, Symbols.Name),
  );

  const propertyForValue = watch(
    getSymbolValue(propertyForFilter, Symbols.Name),
  );

  const Prices = getPrices(propertyForValue);

  const min = Prices.find((p) => p.value === Number(priceRangeMin));
  const max = Prices.find(
    (p) => p.value === Number(priceRangeMax) && p.value > 0,
  );

  const priceRange = [
    min !== undefined &&
      getPriceLabel(min?.label, {
        decorators: min.decorator,
      }),
    max !== undefined &&
      getPriceLabel(max?.label, {
        decorators: max.decorator,
      }),
  ];

  return (
    <div
      id="search-bar-container"
      onClick={(e) => {
        if (window.location.pathname !== PATHS.HOME) return;

        const y = e.currentTarget.offsetTop;

        const navBarHeight =
          document.getElementsByTagName('nav')[0].clientHeight;

        const main = document.getElementById('main');

        if (window && main) main.scrollTo(0, y - navBarHeight - 50);
      }}
      className={styles.container}
    >
      {children}
      <div className={styles.filtersContainer}>
        <LocationFilter />
        <div className={styles.filters}>
          <Filter
            data-propertyType
            raw={raw}
            type={FilterTypes.propertyType}
            label="Property type"
          >
            <input
              readOnly
              type="text"
              value={propertyDetailData.propertyType[propertyType] ?? ''}
              placeholder="Add ..."
            />
          </Filter>
          <Filter raw={raw} type={FilterTypes.priceRange} label="Price Range">
            <input
              readOnly
              type="text"
              value={
                (priceRangeMin !== undefined ?
                  getPriceLabel(priceRangeMin)
                : '') +
                (priceRangeMin || priceRangeMax ? ' - ' : '') +
                (priceRangeMax !== undefined ?
                  priceRange[1] || getPriceLabel(priceRangeMax)
                : '')
              }
              placeholder="Add budget..."
            />
          </Filter>

          <MoreFilter
            raw={raw}
            label={
              !raw ? 'More Filters' : (
                <>
                  More Filters
                  <Icon src={icons.filter} />
                </>
              )
            }
          >
            {!raw && (
              <div className={styles.icon}>
                <Icon src={icons.filter} />
              </div>
            )}
          </MoreFilter>
        </div>
      </div>
      <SearchButton />
    </div>
  );
}

function SecondarySearchBar({ setSearchBarToggle }) {
  const navigate = useNavigate();

  const { getValues } = useFormContext();

  const values = getValues();

  let {
    [getName(propertyForFilter)]: propertyForValue,
    [getName(propertyTypeValuesFilter)]: propertyTypeValue,
    [getName(locationFilter)]: locations,
    [getName(propertyTypeFiter)]: propertyType,
    [getName(priceRangeFilter)]: priceRange,
  } = values;

  const PropertyForLabel =
    propertyForValue ?
      propertyDetailData.propertyFor[propertyForValue]
    : getLabel(propertyDetailData.propertyTypeValues.commercial);

  propertyType = propertyType && propertyDetailData.propertyType[propertyType];

  const Prices = getPrices(propertyForValue);

  let min = priceRange && getPriceLabel(priceRange[0]);
  let max =
    priceRange &&
    Prices.find((p) => p.value === Number(priceRange[1]) && p.value > 0);

  max =
    max ?
      getPriceLabel(max?.label, { decorators: max.decorator })
    : priceRange && getPriceLabel(priceRange[1]);

  priceRange = priceRange && [min, max].join(' - ');

  const additionalFeatures = Object.keys(additionalFeaturesFilter).map((key) =>
    getName(additionalFeaturesFilter[key]),
  );

  const additionalFeaturesValueCount = additionalFeatures.filter(
    (feature) => values[feature],
  ).length;

  return (
    <div className={styles.secondary}>
      <div
        onClick={() => {
          setSearchBarToggle(false);
        }}
        className={classNames(styles.container)}
      >
        <div className={styles.fieldContainer}>
          <div className={styles.field}>
            <p data-placeholder={!PropertyForLabel}>
              {PropertyForLabel ?? 'Add Property For'}
            </p>
            <Icon src={icons.dropdown1} />
          </div>
          <div className={styles.field}>
            <div className={styles.locationLabels}>
              {locations &&
                locations.map((location, index) => (
                  <p className={styles.locationLabel} key={location}>
                    {location}
                  </p>
                ))}
              {(!locations || locations.length < 3) && (
                <p data-placeholder>
                  {locations?.length < 3 ?
                    'Add more...'
                  : 'Add locaiton and landmarks'}
                </p>
              )}
            </div>
          </div>
          <div className={styles.field}>
            <p data-placeholder={!propertyType}>
              {propertyType ?? 'Add Property Type'}
            </p>
            <Icon src={icons.dropdown1} />
          </div>
          <div className={styles.field}>
            <p data-placeholder={!priceRange}>
              {priceRange ?? 'Add Price Range'}
            </p>
            <Icon src={icons.dropdown1} />
          </div>
          <SearchButton />
        </div>
      </div>

      <MoreFilter className={styles.additionalButton}>
        <button type="button">
          {additionalFeaturesValueCount > 0 && (
            <div className={styles.badge}>{additionalFeaturesValueCount}</div>
          )}
          More Filters
          <Icon src={icons.filter} />
        </button>
      </MoreFilter>

      <div className={styles.additionalButton}>
        <SaveSearchButton />
      </div>

      <div className={styles.additionalButton}>
        <button
          onClick={() => navigate(PATHS.PROPERTIES)}
          type="button"
          style={{
            border: 'none',
          }}
        >
          Clear All Filters
        </button>
      </div>
    </div>
  );
}

function SaveSearchButton() {
  const [searchParams] = useSearchParams();

  const { user, userData, setUserData } = useUser();

  const { setToast } = useTost();

  const [saved, setSaved] = useState(false);

  // const values = getValues();
  const paramString = searlizeSearchParams(
    deserializeSearchParams(searchParams),
  ).toString();

  useEffect(() => {
    if (!userData) return;
    setSaved(alreadyExists(paramString).exists ?? false);
  }, [userData, searchParams]);

  function alreadyExists(searchParamsString) {
    const savedSearches = userData?.savedSearches;
    const alreadySaved =
      savedSearches && savedSearches.hasOwnProperty(searchParamsString);

    console.log(alreadySaved, searchParamsString);
    return {
      exists: alreadySaved,
      searchString: searchParamsString,
    };
  }

  async function handler() {
    const { saveSearch } = await import('@/lib/actions');

    const { exists, searchString } = alreadyExists(paramString);
    console.log(exists, searchString);
    if (exists) return;

    const userId = user.uid;

    try {
      console.log(userId, searchString);

      setUserData((prev) => {
        const newData = { ...prev };

        const _date = new Date();

        const date = {
          toDate() {
            return _date;
          },
        };
        newData.savedSearches[searchString] = {
          time: date,
        };

        return newData;
      });

      await saveSearch(userId, searchString);

      setToast({
        open: true,
        message: 'Saved',
        type: 'success',
      });
      setSaved(true);
    } catch (e) {
      console.error(e);

      setToast({
        open: true,
        message: e.message ?? 'Unable to save search',
        type: 'error',
      });
    }
  }

  return (
    <RequireSignInButton
      className={styles.saveSearchButton}
      disabled={saved}
      type="button"
      onClick={handler}
    >
      <Icon src={icons.bookmark} />
      {saved ? 'Saved' : 'Save Search'}
    </RequireSignInButton>
  );
}

function MoreFilter({ children, raw, ...rest }) {
  const { setValue } = useFormContext();

  return (
    <Filter
      data-more-filter
      raw={raw}
      dialog
      FooterSecondaryButton={(props) => (
        <button
          type="button"
          onClick={() => {
            const additionalFeatures = Object.keys(
              additionalFeaturesFilter,
            ).map((key) => getName(additionalFeaturesFilter[key]));

            additionalFeatures.forEach((feature) => {
              setValue(feature, null);
            });
          }}
          {...props}
        >
          Clear All
        </button>
      )}
      FooterCloseButton={({ setOpen, ...props }) => (
        <button
          form="filters"
          type="submit"
          onClick={() => setOpen && setOpen(false)}
          {...props}
        >
          View properties
        </button>
      )}
      type={FilterTypes.additionalFeatures}
      {...rest}
    >
      {children}
    </Filter>
  );
}

function SearchButton() {
  return (
    <div className={styles.searchButtonContainer}>
      <button type="submit" className={styles.searchButton}>
        <Icon src={icons.search} />
      </button>
    </div>
  );
}

function Tabs({ children }) {
  const navigate = useNavigate();

  const { setValue, getValues: getFormValues } = useFormContext();

  const activeTabValue = getFormValues([
    getName(propertyForFilter),
    getName(propertyTypeValuesFilter),
  ]);

  const [activeTab, setActiveTab] = useState(
    activeTabValue[0] ?? activeTabValue[1] ?? 'rent',
  );

  const buttons = [
    ...getValues(propertyForFilter).map((button) => ({
      ...button,
      name: getSymbolValue(propertyForFilter, Symbols.Name),
    })),
    ...getValues(propertyTypeValuesFilter).map((button) => ({
      ...button,
      name: getSymbolValue(propertyTypeValuesFilter, Symbols.Name),
    })),
    {
      id: 'sell_button',
      label: 'SELL',
      value: 'sell',
      onClick: (e) => {
        e.preventDefault();
        navigate(PATHS.PROPERTY_LISTING);
        setActiveTab('sell');
      },
    },
  ];

  return (
    <div className={styles.tabs}>
      {buttons.map((button, index) => (
        <button
          type="button"
          key={button.id}
          data-id={button.id}
          onClick={
            button.onClick ?
              (e) => button.onClick(e)
            : () => {
                console.log(button.name);
                // todo : use dynamic name values
                setValue('propertyTypeValues', undefined);
                setValue('propertyFor', undefined);

                setValue('priceRange', undefined);

                setValue(button.name, button.value);

                setActiveTab(button.value);
              }
          }
          className={classNames(styles.tab, {
            [styles.active]: activeTab === button.value,
          })}
        >
          {button.label}
        </button>
      ))}
      <div className={styles.tabsChildrenContainer}>{children}</div>
    </div>
  );
}

function LocationFilter() {
  const { setToast } = useTost();
  const { setValue, watch, setError } = useFormContext();

  const locations = watch(getSymbolValue(locationFilter, Symbols.Name));

  const handleLocationChange = (value) => {
    value = value.trim();

    if (value.length === 0) {
      return;
    }

    if (locations && locations.includes(value)) {
      setToast({
        open: true,
        message: 'Please enter a different location',
        type: 'error',
      });

      e.target.value = '';
      return;
    }

    setValue(getSymbolValue(locationFilter, Symbols.Name), [
      ...(locations ?? []),
      value,
    ]);
  };

  const InputValueInitialState = '';
  const [value, setInputValue] = useState(InputValueInitialState);

  return (
    <>
      <Filter data-location label="Location">
        <input
          data-location
          readOnly
          value="Mira Road"
          className={styles.fixed}
          type="text"
          placeholder="Add more..."
        />
        {locations &&
          locations.map((location, index) => (
            <div key={location} className={styles.location}>
              <span>{location}</span>
              <button
                type="button"
                onClick={() =>
                  setValue(
                    getSymbolValue(locationFilter, Symbols.Name),
                    locations.filter((_, i) => i !== index),
                  )
                }
              >
                <Icon src={icons.closeWhite} />
              </button>
            </div>
          ))}
        {(!locations || locations.length < 3) && (
          <CustomSearchBox
            value={value}
            onChange={(e) => setInputValue(e.target.value)}
            onSelect={(selectedItem) => {
              handleLocationChange(selectedItem.terms[0].value);
              setInputValue(InputValueInitialState);
            }}
            placeholder={
              locations && locations.length > 0 ?
                'Add more...'
              : 'Search up to three localities or land marks.'
            }
          />
        )}
      </Filter>
    </>
  );
}

export function searlizeSearchParams(data) {
  const searchParams = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined || value === null || value === false) return;

    if (Array.isArray(value)) {
      if (value.length === 0) return;

      if (value.filter(Boolean).length === 0) return;

      searchParams.append(key, encodeURIComponent(value.join(',')));
    } else {
      searchParams.append(key, encodeURIComponent(value));
    }
  });

  return searchParams;
}

export default SearchBar;
