import { algoliasearch } from 'algoliasearch';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocFromCache,
  getDocs,
  getDocsFromCache,
  getFirestore,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';

import {
  ALOGLIA_TYPE,
  convertFirebaseToAlgoliaFilter,
  DEFAULT_SORT,
  getFieldName,
  getFilter,
  sortFilter,
} from '@/data/filters';
import propertyDetailData, {
  PROPERTY_DETAIL_NAME,
} from '@/data/preporty-detail';
import propertyLocationData, {
  PROPERTY_LOCATION_NAME,
} from '@/data/property-location';
import { Symbols } from '@/data/symbols';
import { getName } from '@/data/utils';
import {
  getInqieryCollection,
  getReviewCollection,
  getScheduledVisitsCollection,
  getUserCollection,
} from '@/lib/actions';
import app from '@/lib/firebase';

export const db = getFirestore(app);

const client = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_KEY,
);

const INDEX_NAME = {
  properties_timestamp_desc: 'properties_index',
  properties_timestamp_asc: 'properties_index_timestamp_asc',
  properties_price_asc: 'properties_index_price_asc',
  properties_price_desc: 'properties_index_price_desc',
};

const DEFAULT_LIMIT_COUNT = 20;

// function buildAlgoliaFilters(filters) {
//   return Object.entries(filters)
//     .map(([key, value]) => {
//       if (Array.isArray(value)) {
//         return `${key}:${value.join(' OR ')}`;
//       } else if (typeof value === 'object' && value !== null) {
//         if ('min' in value && 'max' in value) {
//           return `${key}: ${value.min} TO ${value.max}`;
//         }
//       } else {
//         return `${key}:${value}`;
//       }
//     })
//     .join(' AND ');
// }

export async function addProperty(data, moreData) {
  // todo : validate date here
  console.log(data);

  // collection Reference
  const propertyCollection = getPropertyCollection();

  // add data to collection
  const docRef = await addDoc(propertyCollection, {
    ...data,
    ...moreData,
    timestamp: serverTimestamp(),
  });

  return docRef;
}

export async function updateProperty(id, data) {
  // collection Reference
  const propertyCollection = getPropertyCollection();
  console.log('updating property', id);
  // add data to collection
  const docRef = await updateDoc(doc(propertyCollection, id), {
    ...data,
  });

  return docRef;
}

function getSortIndex(sort) {
  switch (sort) {
    case 'timestamp_desc':
      return INDEX_NAME.properties_timestamp_desc;
    case 'timestamp_asc':
      return INDEX_NAME.properties_timestamp_asc;
    case 'price_desc':
      return INDEX_NAME.properties_price_desc;
    case 'price_asc':
      return INDEX_NAME.properties_price_asc;
    default:
      return INDEX_NAME.properties_timestamp_desc;
  }
}

function getOrderBy(sort) {
  if (sort === 'timestamp_asc') return [orderBy('timestamp', 'asc')];
  if (sort === 'timestamp_desc') return [orderBy('timestamp', 'desc')];
  if (sort === 'price_asc')
    return [
      orderBy('property_pricing.rent', 'asc'),
      orderBy('timestamp', 'desc'),
    ];
  if (sort === 'price_desc')
    return [
      orderBy('property_pricing.rent', 'desc'),
      orderBy('timestamp', 'desc'),
    ];
}

/**
 * Returns a list of properties that match the filters
 * @param {URLSearchParams} searchParams
 * @returns
 */
export async function getFilteredProperties(searchParams, opts = {}) {
  // try {
  //   const f = {
  //     'property_detail.propertyFor': 'rent',
  //     'property_pricing.rent': { min: 100, max: 500 },
  //   };
  //   const d = await client.search({
  //     requests: [
  //       {
  //         indexName: INDEX_NAME.properties_timestamp_asc,
  //         // ranking: [''],
  //         // query: 'rent',

  //         filters: buildAlgoliaFilters(f),
  //       },
  //     ],
  //   });

  //   console.log('ALGO SEARCH');
  //   console.log(d);
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
  const filters = searchParams;
  let sort = DEFAULT_SORT;

  const sortBy = searchParams.get(getName(sortFilter));
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const [afterTimestamp, afterPrice] =
    searchParams.get('startAfter') ?
      decodeURIComponent(searchParams.get('startAfter')).split(',')
    : [null, null];
  const [beforeTimestamp, beforePrice] =
    searchParams.get('endBefore') ?
      decodeURIComponent(searchParams.get('endBefore')).split(',')
    : [null, null];

  console.log('afterTimestamp', afterTimestamp, typeof afterTimestamp);
  console.log('afterPrice', afterPrice);
  console.log('beforeTimestamp', beforeTimestamp);
  console.log('beforePrice', beforePrice);

  if (sortBy) sort = sortBy;

  searchParams.delete(getName(sortFilter));
  searchParams.delete('startAfter');
  searchParams.delete('endBefore');

  if (!filters) {
    console.error('No filters provided');
    throw new Error('No filters provided');
  }

  console.log(filters);
  let queries = [];
  let useAloglia = false;

  try {
    for (const key of filters.keys()) {
      const filter = getFilter(key);

      if (!filter) {
        console.warn(`Filter \`${key}\` not found`);
        continue;
      }

      if (filter[Symbols.Query]) {
        const q = filter[Symbols.Query](
          String(decodeURIComponent(filters.get(key))),
        );
        if (q.type === ALOGLIA_TYPE) useAloglia = true;
        queries.push(q);
      }
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
  // sort the queries
  queries.sort((a, b) => a.order - b.order);

  // now get flat sorted queries
  const ServerSidequeries = queries
    .filter((q) => q && !q?.clientSide)
    .flatMap((q) => q.query);
  const clientSideQueries = queries
    .filter((q) => q?.clientSide)
    .flatMap((q) => q.query);
  // const queries = [where('propertyLocation.sublocality', 'in', subLocalities)];

  console.log('useAloglia', useAloglia);
  console.log('queries', ServerSidequeries);
  console.log('clientSideQueries', clientSideQueries);

  let q;
  try {
    const algoliaFilters = convertFirebaseToAlgoliaFilter(ServerSidequeries);
    console.log('algolia filters', algoliaFilters);

    const firebaseQuery = [getPropertyCollection(), ...ServerSidequeries];

    if (useAloglia) q = algoliaFilters;
    else q = firebaseQuery;
  } catch (e) {
    console.error(e);
    throw e;
  }

  const LIMIT_COUNT = opts.limit ?? DEFAULT_LIMIT_COUNT;

  return new Promise(async (res, rej) => {
    try {
      console.log('getting docs');

      if (useAloglia) {
        const {
          results: [result],
        } = await client.search({
          requests: [
            {
              indexName: getSortIndex(sort),
              filters: q,
              hitsPerPage: LIMIT_COUNT,
              page: Number(page) - 1,
            },
          ],
        });

        console.log('algolia result', result);

        return res({
          data: result.hits.map(({ objectID, ...rest }) => ({
            id: objectID,
            data: rest,
          })),
          page: Number(result.page) + 1,
          count: result.nbHits,
          totalPages: result.nbPages,
          type: 'algolia',
        });
      } else {
        const countSanpshot = await getCountFromServer(query(...q));

        const count = countSanpshot.data().count,
          noOfPages = Math.ceil(count / LIMIT_COUNT),
          isLastPage = noOfPages === page;

        console.log('parsed timestamp');

        const constraints =
          (
            afterTimestamp &&
            afterPrice &&
            (sort === 'price_asc' || sort === 'price_desc')
          ) ?
            [
              ...getOrderBy(sort),
              startAfter(
                Number(afterPrice),
                Timestamp.fromMillis(Number(afterTimestamp)),
              ),
              limit(LIMIT_COUNT),
            ]
          : (
            beforeTimestamp &&
            beforePrice &&
            (sort === 'price_asc' || sort === 'price_desc')
          ) ?
            [
              ...getOrderBy(sort),
              endBefore(
                Number(beforePrice),
                Timestamp.fromMillis(Number(beforeTimestamp)),
              ),
              limitToLast(LIMIT_COUNT),
            ]
          : afterTimestamp && !afterPrice ?
            [
              ...getOrderBy(sort),
              startAfter(Timestamp.fromMillis(Number(afterTimestamp))),
              limit(LIMIT_COUNT),
            ]
          : beforeTimestamp && !beforePrice ?
            [
              ...getOrderBy(sort),
              endBefore(Timestamp.fromMillis(Number(beforeTimestamp))),
              limitToLast(LIMIT_COUNT),
            ]
          : [...getOrderBy(sort), limit(LIMIT_COUNT)];

        console.warn('final query', query(...q, ...constraints));
        const docs = await getDocs(query(...q, ...constraints));

        console.log('countSanpshot', countSanpshot);
        console.log('docs', docs);

        const data = docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));

        let filteredData = data;

        for (const clientSideQuery of clientSideQueries) {
          filteredData = clientSideQuery(filteredData);
        }

        console.log('filteredData', filteredData);

        return res({
          data: filteredData,
          page: Number(page ?? 1),
          count: count,
          totalPages: noOfPages,
          type: 'firestore',
          isLastPage,
        });
      }
    } catch (e) {
      console.error(e);
      rej(e);
    }
  });
}

export async function getAllPropertiesWithLimit(searchParams, opts) {}
export async function getUserData(userId) {
  const userDocRef = doc(getUserCollection(), userId);
  try {
    // return await getDocFromFristCache(userDocRef);
    return await getDoc(userDocRef);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getProperty(id) {
  if (!id) throw new Error('No id provided');
  const propertyCollection = getPropertyCollection();
  const docRef = doc(propertyCollection, id);
  try {
    return await getDoc(docRef);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getSimilarProperties(
  id,
  searchAbleLocationTerms,
  propertyType,
  propertyFor,
) {
  const propertyCollection = getPropertyCollection();

  try {
    const q = query(
      propertyCollection,
      where(
        getFieldName(PROPERTY_LOCATION_NAME, propertyLocationData.terms),
        'array-contains-any',
        searchAbleLocationTerms,
      ),
      where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyType),
        '==',
        propertyType,
      ),
      where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyFor),
        '==',
        propertyFor,
      ),
      where('__name__', '!=', id),
      limit(4),
    );

    const docs = await getDocs(q, orderBy('timestamp', 'desc'));

    return docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getHomeForYouProperties(
  propertyFor,
  propertyType,
  propertyTypeValues,
  locationTerms,
) {
  const propertyCollection = getPropertyCollection();

  try {
    const q = query(
      propertyCollection,
      where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyFor),
        '==',
        propertyFor,
      ),
      where(
        getFieldName(PROPERTY_DETAIL_NAME, propertyDetailData.propertyType),
        '==',
        propertyType,
      ),
      where(
        getFieldName(
          PROPERTY_DETAIL_NAME,
          propertyDetailData.propertyTypeValues,
        ),
        '==',
        propertyTypeValues,
      ),
      where(
        getFieldName(PROPERTY_LOCATION_NAME, propertyLocationData.terms),
        'array-contains-any',
        locationTerms,
      ),

      limit(6),
    );
    const docs = await getDocs(q, orderBy('timestamp', 'desc'));

    return docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getScheduledVisits(userId, propertyId) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  // if (!propertyId) {
  //   console.error('No propertyId provided');
  //   throw new Error('No propertyId provided');
  // }

  const userDocRef = getScheduledVisitsCollection();

  const q = [
    where('createdBy', '==', userId),
    propertyId && where('context.propertyId', '==', propertyId),
  ].filter(Boolean);

  try {
    const scheduledVisits = await getDocs(query(userDocRef, ...q));
    return scheduledVisits.docs.map((doc) => ({
      ...doc.data(),

      id: doc.id,
    }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getUserPropertyListings(userId) {
  try {
    const propertiesCollection = getPropertyCollection();
    const q = query(propertiesCollection, where('created_by', '==', userId));

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),

      id: doc.id,
    }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getUserShortlistedProperties(ids) {
  try {
    const propertiesCollection = getPropertyCollection();
    const q = query(propertiesCollection, where('__name__', 'in', ids));

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),

      id: doc.id,
    }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getUserNotInterestedProperties(ids) {
  try {
    const propertiesCollection = getPropertyCollection();
    const q = query(propertiesCollection, where('__name__', 'in', ids));

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),

      id: doc.id,
    }));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function removePropertyListing(id) {
  const propertyCollection = getPropertyCollection();
  try {
    await deleteDoc(doc(propertyCollection, id));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getInqueries(searchParams, opts = {}) {
  const filters = searchParams;
  let sort = 'timestamp_asc';

  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const sortBy = searchParams.get(getName(sortFilter));

  if (!searchParams.has('type')) searchParams.set('type', 'quick');

  const afterTimestamp =
    searchParams.has('startAfter') &&
    decodeURIComponent(searchParams.get('startAfter'));

  const beforeTimestamp =
    searchParams.has('endBefore') &&
    decodeURIComponent(searchParams.get('endBefore'));

  console.log('afterTimestamp', afterTimestamp, typeof afterTimestamp);
  console.log('beforeTimestamp', beforeTimestamp);

  if (sortBy) sort = sortBy;

  console.log(filters);

  let queries = [];

  for (const key of filters.keys()) {
    const value = decodeURIComponent(filters.get(key));

    if (key === 'type') {
      queries.push(where('type', '==', value));
    }
  }

  let q = [getInqieryCollection(), ...queries];

  const LIMIT_COUNT = opts.limit ?? 5;

  return new Promise(async (res, rej) => {
    try {
      console.log('getting docs');

      const countSanpshot = await getCountFromServer(query(...q));

      const count = countSanpshot.data().count,
        noOfPages = Math.ceil(count / LIMIT_COUNT),
        isLastPage = noOfPages === page;

      console.log('parsed timestamp');

      const constraints =
        afterTimestamp ?
          [
            ...getOrderBy(sort),
            startAfter(Timestamp.fromMillis(Number(afterTimestamp))),
            limit(LIMIT_COUNT),
          ]
        : beforeTimestamp ?
          [
            ...getOrderBy(sort),
            endBefore(Timestamp.fromMillis(Number(beforeTimestamp))),
            limitToLast(LIMIT_COUNT),
          ]
        : [...getOrderBy(sort), limit(LIMIT_COUNT)];

      console.warn('final query', query(...q, ...constraints));
      const docs = await getDocs(query(...q, ...constraints));

      console.log('countSanpshot', countSanpshot);
      console.log('docs', docs);

      const data = docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));

      let filteredData = data;

      console.log('filteredData', filteredData);

      return res({
        data: filteredData,
        page: Number(page ?? 1),
        count: count,
        totalPages: noOfPages,
        type: 'firestore',
        isLastPage,
      });
    } catch (e) {
      console.error(e);
      rej(e);
    }
  });
}

export async function getAllReviews(searchParams, opts = {}) {
  const filters = searchParams;
  let sort = 'timestamp_desc';

  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const sortBy = searchParams.get(getName(sortFilter));

  const afterTimestamp =
    searchParams.has('startAfter') &&
    decodeURIComponent(searchParams.get('startAfter'));

  const beforeTimestamp =
    searchParams.has('endBefore') &&
    decodeURIComponent(searchParams.get('endBefore'));

  console.log('afterTimestamp', afterTimestamp, typeof afterTimestamp);
  console.log('beforeTimestamp', beforeTimestamp);

  if (sortBy) sort = sortBy;

  console.log(filters);

  let queries = [];

  for (const key of filters.keys()) {
    const value = decodeURIComponent(filters.get(key));

    if (key === 'propertyId') {
      queries.push(where('propertyId', '==', value));
    }
  }

  let q = [getReviewCollection(), ...queries];

  const LIMIT_COUNT = opts.limit ?? 5;

  return new Promise(async (res, rej) => {
    try {
      console.log('getting docs');

      const countSanpshot = await getCountFromServer(query(...q));

      const count = countSanpshot.data().count,
        noOfPages = Math.ceil(count / LIMIT_COUNT),
        isLastPage = noOfPages === page;

      console.log('parsed timestamp');

      const constraints =
        afterTimestamp ?
          [
            ...getOrderBy(sort),
            startAfter(Timestamp.fromMillis(Number(afterTimestamp))),
            limit(LIMIT_COUNT),
          ]
        : beforeTimestamp ?
          [
            ...getOrderBy(sort),
            endBefore(Timestamp.fromMillis(Number(beforeTimestamp))),
            limitToLast(LIMIT_COUNT),
          ]
        : [...getOrderBy(sort), limit(LIMIT_COUNT)];

      console.warn('final query', query(...q, ...constraints));
      const docs = await getDocs(query(...q, ...constraints));

      console.log('countSanpshot', countSanpshot);
      console.log('docs', docs);

      const data = docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));

      let filteredData = data;

      console.log('filteredData', filteredData);

      return res({
        data: filteredData,
        page: Number(page ?? 1),
        count: count,
        totalPages: noOfPages,
        type: 'firestore',
        isLastPage,
      });
    } catch (e) {
      console.error(e);
      rej(e);
    }
  });
}

export async function getAllScheduledVisits(searchParams, opts = {}) {
  const filters = searchParams;
  let sort = 'timestamp_asc';

  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const sortBy = searchParams.get(getName(sortFilter));

  if (!searchParams.has('type')) searchParams.set('type', 'inPerson');

  const afterTimestamp =
    searchParams.has('startAfter') &&
    decodeURIComponent(searchParams.get('startAfter'));

  const beforeTimestamp =
    searchParams.has('endBefore') &&
    decodeURIComponent(searchParams.get('endBefore'));

  console.log('afterTimestamp', afterTimestamp, typeof afterTimestamp);
  console.log('beforeTimestamp', beforeTimestamp);

  if (sortBy) sort = sortBy;

  console.log(filters);

  let queries = [];

  for (const key of filters.keys()) {
    const value = decodeURIComponent(filters.get(key));

    switch (key) {
      case 'type':
        queries.push(where('visitType', '==', value));
        break;
      case 'status':
        queries.push(where('status', '==', value));
        break;
      default:
        console.log('new queries found');
        break;
    }
  }

  let q = [getScheduledVisitsCollection(), ...queries];

  const LIMIT_COUNT = opts.limit ?? 5;

  return new Promise(async (res, rej) => {
    try {
      console.log('getting docs');

      const countSanpshot = await getCountFromServer(query(...q));

      const count = countSanpshot.data().count,
        noOfPages = Math.ceil(count / LIMIT_COUNT),
        isLastPage = noOfPages === page;

      console.log('parsed timestamp');

      const constraints =
        afterTimestamp ?
          [
            ...getOrderBy(sort),
            startAfter(Timestamp.fromMillis(Number(afterTimestamp))),
            limit(LIMIT_COUNT),
          ]
        : beforeTimestamp ?
          [
            ...getOrderBy(sort),
            endBefore(Timestamp.fromMillis(Number(beforeTimestamp))),
            limitToLast(LIMIT_COUNT),
          ]
        : [...getOrderBy(sort), limit(LIMIT_COUNT)];

      console.warn('final query', query(...q, ...constraints));
      const docs = await getDocs(query(...q, ...constraints));

      console.log('countSanpshot', countSanpshot);
      console.log('docs', docs);

      const data = docs.docs.map((doc) => ({ id: doc.id, data: doc.data() }));

      let filteredData = data;

      console.log('filteredData', filteredData);

      return res({
        data: filteredData,
        page: Number(page ?? 1),
        count: count,
        totalPages: noOfPages,
        type: 'firestore',
        isLastPage,
      });
    } catch (e) {
      console.error(e);
      rej(e);
    }
  });
}

// ---- UTILS ----
function getPropertyCollection() {
  return collection(db, 'properties');
}

function getDocSnap(docId) {
  return doc(getPropertyCollection(), docId);
}

function encode(value) {
  return value.toLowerCase().replace(/ /g, '-');
}

async function getDocFromFristCache(docRef) {
  try {
    const cachedDoc = await getDocFromCache(docRef);
    console.log('cached doc found');
    return cachedDoc;
  } catch (e) {
    console.log('cached doc not found');
    return await getDoc(docRef);
  }
}

async function getDocsFromFirstCache(q) {
  const cachedDocs = await getDocsFromCache(q);

  if (cachedDocs.empty) {
    return await getDocs(q);
  }
  console.log('cached');
  return cachedDocs;
}

export async function getProfileData() {
  const ref = profileDocRef();
  const doc = await getDoc(ref);
  return doc.data();
}

export async function updateProfileData(data) {
  const ref = profileDocRef();
  await setDoc(ref, data, { merge: true });
}

export function profileDocRef() {
  const globalCollection = collection(db, 'global');
  return doc(globalCollection, 'profile');
}
