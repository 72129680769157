import { createContext, useContext, useEffect, useState } from 'react';

import { produce } from 'immer';

import { useUser } from '@/contexts/auth-context';
import { PROPERTY_DETAIL_NAME } from '@/data/preporty-detail';
import { PROPERTY_FEATURES_NAME } from '@/data/property-features';
import { PROPERTY_LOCATION_NAME } from '@/data/property-location';
import { PROPERTY_PRICING_NAME } from '@/data/property-pricing';

const stateContext = createContext();

export const useGlobalState = () => useContext(stateContext);

const INITIAL_STATE = {
  [PROPERTY_DETAIL_NAME]: {},
  [PROPERTY_LOCATION_NAME]: {
    societyName: '',
    locality: '',
    landmark: '',
    city: 'Mumbai',
    // marker: {
    //   lat: 19.286686560914287,
    //   lng: 72.86837847552772,
    // },
  },
  [PROPERTY_FEATURES_NAME]: {},
  [PROPERTY_PRICING_NAME]: {},
  property_images: {},
  mode: 'create',
  status: {
    submitted: false,
    id: null,
  },
};

export const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [images, setImages] = useState({});

  function updateState(updater) {
    const updatedState = produce(state, updater);

    setState(updatedState);

    // localStorage.setItem('state', JSON.stringify(updatedState));
  }

  function getSavedDate(key) {
    if (state[key]) return state[key];
    // const savedState = localStorage.getItem('state');
    // if (savedState) {
    //   const parsedState = JSON.parse(savedState);
    //   return parsedState[key];
    // }
  }

  function clearState() {
    console.log('Clearing State');
    setState(INITIAL_STATE);
    localStorage.removeItem('state');
  }

  useEffect(() => {
    // const localState = localStorage.getItem('state');
    // if (localState) {
    //   const parsedState = JSON.parse(localState);
    //   setState(parsedState);
    // }
  }, []);

  return (
    <stateContext.Provider
      value={{
        state,
        setState,
        updateState,
        getSavedDate,
        images,
        setImages,
        clearState,
      }}
    >
      {children}
    </stateContext.Provider>
  );
};
