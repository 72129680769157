import { useMemo, useState } from 'react';

import styles from '@/styles/modules/register-form.module.css';

import * as Tabs from '@radix-ui/react-tabs';
import axios from 'axios';
import classNames from 'classnames';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

import { FormBody, FormFooter, FormHeader } from '@/components/form';
import FormGroup from '@/components/form/group';
import FormInput from '@/components/form/input';
import FormProvider, { useFormContext } from '@/components/form/provider';
import RadioInput from '@/components/form/radio';
import FormSelect from '@/components/form/select';
import Spinner from '@/components/spinner';
import { useGlobalState } from '@/store/global-state';

let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const RegisterForm = () => {
  return (
    <FormProvider
      options={{
        defaultValues: {
          role: '',
          name: '',
          country: 'IN',
          phone: { code: '91', number: '' },
          email: '',
        },
      }}
    >
      <Form />
    </FormProvider>
  );
};

const ENDPOINTs = {
  register: '/api/v1/register',
};

async function register(data) {
  try {
    const res = await axios.post(ENDPOINTs.register, data);

    return res.data;
  } catch (error) {
    window.alert('Something went wrong');

    return errorBuilder(error.message);
  }
}

function errorBuilder(message) {
  return {
    error: true,
    data: {
      message,
    },
  };
}

const Form = () => {
  const { updateState } = useGlobalState();

  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();

  const { handleSubmit } = useFormContext();

  const onSubmit = async (data) => {
    if (!data.phone.number && !data.email) {
      window.alert('* Either phone or email is required');
      return;
    }

    if (tabIndex === 0) {
      setTabIndex(1);
    } else {
      setLoading(true);
      const res = await register(data);

      if (res.error) {
        window.alert(res.data.message);
      }

      if (res.success) {
        updateState((state) => {
          state.user = res.data.result;
          state.userRegisterd = true;
        });

        navigate('/form');
      }
      setLoading(false);
    }
  };

  return (
    <Tabs.Root value={tabIndex} asChild>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Header />
        {loading ?
          <FormBody>
            <Spinner />
          </FormBody>
        : <>
            <Tabs.Content value={0} className={styles.TabContent} asChild>
              <Body />
            </Tabs.Content>
            <Tabs.Content value={1} className={styles.TabContent} asChild>
              <OtpBody />
            </Tabs.Content>
          </>
        }
      </form>
    </Tabs.Root>
  );
};

const Header = () => (
  <FormHeader>
    <h2>LET'S GET YOU STARTED!</h2>
  </FormHeader>
);

const Body = () => {
  const { errors } = useFormContext();

  return (
    <>
      <FormBody>
        <FormGroup label="I am" required error={errors.role}>
          <RadioInput
            cols={2}
            name="role"
            validation={{
              required: 'This field is required',
            }}
            options={['Owner', 'Builder']}
          />
        </FormGroup>

        <FormGroup label="Your Name" required error={errors.name}>
          <FormInput
            placeholder="Name"
            name="name"
            validation={{
              required: 'Name is required',
            }}
          />
        </FormGroup>

        <FormGroup label="Country" required error={errors.country}>
          <CountrySelect />
        </FormGroup>

        <FormGroup label="Phone" error={errors.phone?.number}>
          <PhoneInput />
        </FormGroup>
        <span className={styles.or}>OR</span>
        <FormGroup label="Email" error={errors.email}>
          <FormInput
            name="email"
            placeholder="Email"
            validation={{
              //todo : move this validation to data file
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
          />
        </FormGroup>

        {
          //todo: use Error Message component
        }
        {errors.contact && (
          <p className={styles.error}>{errors.contact.message}</p>
        )}
      </FormBody>
      <FormFooter>
        <button className={styles.submitButton} type="submit">
          NEXT
        </button>
      </FormFooter>
    </>
  );
};

const OtpBody = () => {
  const { errors } = useFormContext();

  return (
    <>
      <FormBody>
        <FormGroup
          label="Enter OTP"
          end={<a className={styles.changeOtp}>Change OTP</a>}
          bottom={<a className={styles.resendOtp}>Resend OTP</a>}
          required
          error={errors.otp}
        >
          <FormInput
            style={{ letterSpacing: '0.5em' }}
            maxLength={6}
            name="otp"
            placeholder="000000"
            validation={{
              required: 'OTP is required',
              pattern: {
                value: /^\d{6}$/,
                message: 'Invalid OTP',
              },
            }}
          />
        </FormGroup>
      </FormBody>
      <FormFooter>
        <button className={styles.submitButton} type="submit">
          NEXT
        </button>
      </FormFooter>
    </>
  );
};

const CountrySelect = () => {
  return (
    <FormSelect
      autoComplete={true}
      defaultValue={'IN'}
      name="country"
      validation={{
        required: 'Country is required',
      }}
      options={getCountries().map((country) => ({
        value: country,
        label: regionNames.of(country),
      }))}
    />
  );
};

export const PhoneInput = (props) => {
  return (
    <div className={styles.phoneInput}>
      {props.staticCode ?
        <div className={classNames(styles.staticContainer, props.className)}>
          <span className={styles.static}>+91</span>
        </div>
      : <CountryCodeSelect className={props.className} />}
      <FormInput
        name="phone.number"
        maxLength={10}
        placeholder={props.placeholder ?? '000-000-0000'}
        type="tel"
        validation={{
          required: props.required ?? false,
          pattern: {
            value: /^\d{10}$/,
            message: 'Invalid phone number',
          },
        }}
        {...props}
      />
    </div>
  );
};

const CountryCodeSelect = ({ className }) => {
  const countriesCode = useMemo(
    () => [
      ...new Set(
        getCountries()
          .map((country) => getCountryCallingCode(country))
          .sort((a, b) => a - b),
      ),
    ],
    [],
  );

  return (
    <FormSelect
      className={className}
      name="phone.code"
      parentStyles={{ width: '10ch' }}
      validation={{
        required: false,
      }}
      options={countriesCode.map((code) => ({
        value: code,
        label: code,
      }))}
    />
  );
};

export default RegisterForm;
