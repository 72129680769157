import React, { useEffect } from 'react';

import ReactDOM from 'react-dom/client';

import App from '@/App';

// localStorage.setItem('state', undefined);
// localStorage.clear();

async function getGlobalProfile() {}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
