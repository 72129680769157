import styles from '@/styles/modules/checkbox.module.css';

import { useAccessor } from '@/components/accessor';
import FormInput from '@/components/form/input';
import { Symbols } from '@/data/symbols';
import { getSymbolValue, getValues } from '@/data/utils';

function getInputStyles(type) {
  if (type === 'button') {
    return styles.button;
  } else if (type === 'square') {
    return styles.square;
  }
  return styles.round;
}

function getGroupStyles(type) {
  if (type === 'button') {
    return styles.group;
  }
  return styles.groupGrid;
}

const CheckboxGroup = ({
  omitGrid = false,
  useIcons = true,
  type = 'button',
  cols = 3,
  name,
  options,
  validation,
  spacing,
  ...rest
}) => {
  const accessor = useAccessor();

  name = name || getSymbolValue(accessor, Symbols.Name);
  validation = validation || getSymbolValue(accessor, Symbols.Validation);
  options = options || getValues(accessor);

  console.log(options);

  return (
    <Grid spacing={spacing} type={type} cols={cols} omitGrid={omitGrid}>
      {options.map((option) => {
        let icon, label, value;

        if (typeof option === 'object') {
          label = option.label;
          value = option.value;
          icon = useIcons && option.icon;
        } else {
          label = option;
          value = option;
        }

        return (
          <div className={styles.option} key={name + option.id}>
            <FormInput
              className={getInputStyles(type)}
              name={option.name ?? name}
              type="checkbox"
              value={option.value}
              id={name + option.id}
              validation={validation}
              {...rest}
            />

            <label
              className={icon ? styles.withIcon : styles.label}
              htmlFor={name + option.id}
            >
              {icon && (
                <div className={styles.iconContainer}>
                  <img src={icon} alt={'icon for ' + label} />
                </div>
              )}
              {label}
            </label>
          </div>
        );
      })}
    </Grid>
  );
};

function Grid({ type, cols, spacing, omitGrid, children }) {
  if (omitGrid) return <>{children}</>;

  return (
    <div
      style={{
        '--grid-cols': cols,
        ...(spacing === false ?
          {
            margin: 0,
            padding: 0,
          }
        : {}),
      }}
      className={getGroupStyles(type)}
    >
      {children}
    </div>
  );
}

export default CheckboxGroup;
