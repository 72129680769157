import { Symbols } from '@/data/symbols';

function getKey(obj, value) {
  return Object.keys(obj).find((key) => obj[key].ValueOf() === value);
}

/**
 * Get label from object
 * @example getLabel(data.propertyFacing, 'north')
 * @returns {string | undefined}  `North`
 */
export function getLableByValue(obj, value) {
  if (!obj) return;

  return obj[getKey(obj, value)]?.[Symbols.Label];
}

export function getIconByValue(obj, value) {
  if (!obj) return;

  return obj[getKey(obj, value)]?.[Symbols.Icon];
}

/**
 * Get Values from object
 * @example getValues(data.propertyFacing)
 * @returns {Array<{ id: string, value: string, label: string, icon: string , valueLabel: string }>}
 */
export function getValues(obj) {
  return Object.entries(obj).map(([key, value]) => ({
    id: key,
    name: value[Symbols.Name],
    value: value[Symbols.Value] ?? key,
    label: value[Symbols.Label] ?? value,
    icon: value[Symbols.Icon],
    valueLabel: value[Symbols.ValueLabel],
  }));
}

export function getFilterValues(obj) {
  return Object.entries(obj).map(([key, value]) => ({
    id: key,
    value: value[Symbols.Value] ?? key,
    label: value[Symbols.FilterLabel] ?? value[Symbols.Label] ?? value,
    icon: value[Symbols.Icon],
  }));
}

/**
 * Get Icon from object
 * @example getIcon(data.propertyFacing)
 * @returns {string | undefined}  `Compass`
 */
export function getIcon(obj) {
  if (!obj) return;
  const Icon = obj[Symbols.Icon];
  return Icon;
}

/**
 * Get label from object
 * @example getLabel(data.propertyFacing)
 * @returns {string | undefined}  `Property Facing`
 */
export function getLabel(obj) {
  if (!obj) return;
  const label = obj[Symbols.Label];
  return label;
}

export function getName(obj) {
  if (!obj) return;
  const Name = obj[Symbols.Name];
  return Name;
}

export function getSymbolValue(obj, symbol) {
  if (!obj) return;
  const symbolValue = obj[symbol];
  return symbolValue;
}

/**
 * Create a primitive with symbol
 * @param {*} Interface - `String|Number|Boolean`
 * @param {String|Number|Boolean} primitive - `Value` or the `label` of the primitive
 * @example primitiveWithSymbol(Boolean, true, { label: 'Allowed' })
 * @returns {Boolean | undefined}  `true`
 */
export function primitiveWithSymbol(
  Interface,
  primitive,
  { icon, label, value, filterLabel, valueLabel, name } = {},
) {
  if (Array.isArray(primitive)) primitive = new Interface(...primitive);
  else primitive = new Interface(primitive);

  if (icon) primitive[Symbols.Icon] = icon;
  if (label) primitive[Symbols.Label] = label;
  if (value) primitive[Symbols.Value] = value;
  if (filterLabel) primitive[Symbols.FilterLabel] = filterLabel;
  if (valueLabel) primitive[Symbols.ValueLabel] = valueLabel;
  if (name) primitive[Symbols.Name] = name;

  return primitive;
}

export function isRequired(obj) {
  if (!obj) return;
  const required = obj[Symbols.Validation]?.required;
  return required;
}
