import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { db } from '@/lib/db';

export async function addPropertyToUserWishList(userId, propertyId) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!propertyId) {
    console.error('No propertyId provided');
    throw new Error('No propertyId provided');
  }

  const userDocRef = doc(getUserCollection(), userId);
  try {
    await setDoc(
      userDocRef,
      {
        wishlist: arrayUnion(propertyId),
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function removePropertyFromUserWishList(userId, propertyId) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!propertyId) {
    console.error('No propertyId provided');
    throw new Error('No propertyId provided');
  }

  const userDocRef = doc(getUserCollection(), userId);
  try {
    await setDoc(
      userDocRef,
      {
        wishlist: arrayRemove(propertyId),
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function addPropertyToUserNotInterestedList(userId, propertyId) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!propertyId) {
    console.error('No propertyId provided');
    throw new Error('No propertyId provided');
  }

  const userDocRef = doc(getUserCollection(), userId);
  try {
    await setDoc(
      userDocRef,
      {
        notInterested: arrayUnion(propertyId),
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function removePropertyFromUserNotInterestedList(
  userId,
  propertyId,
) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!propertyId) {
    console.error('No propertyId provided');
    throw new Error('No propertyId provided');
  }

  const userDocRef = doc(getUserCollection(), userId);
  try {
    await setDoc(
      userDocRef,
      {
        notInterested: arrayRemove(propertyId),
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateUserVisitedProperty(userId, properties) {
  if (!userId) {
    console.error('No user id provided');
    throw new Error('No user id provided');
  }

  console.log('updating user visited property', properties);
  const userDocRef = doc(getUserCollection(), userId);

  try {
    await setDoc(
      userDocRef,
      {
        visitedProperties: properties,
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Saves search query to user's collection
 * @param {String} userId
 * @param {String} search - search query
 */
export async function saveSearch(userId, search) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!search) {
    console.error('No search query provided');
    throw new Error('No search query provided');
  }

  function validateSearchString(string) {
    const length = string.trim().length;
    const splitedLength = string.split(',').length;
    return length > 0 && splitedLength > 0;
  }

  if (!validateSearchString(search)) {
    console.worning('Unable to save search query');
    return null;
  }

  const userDocRef = doc(getUserCollection(), userId);

  // append search query to user's search array
  try {
    await updateDoc(
      userDocRef,
      {
        [`savedSearches.${search}`]: {
          time: serverTimestamp(),
        },
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

class debouncedQueue {
  constructor(callback, delay) {
    this.callback = callback;
    this.delay = delay;
    this.queue = [];
    this.timer = null;
  }

  add(data) {
    this.queue.push(data);
    this.flush();
  }

  flush() {
    if (this.timer) return;

    this.timer = setTimeout(() => {
      this.timer = null;
      this.callback(this.queue);
      this.queue = [];
    }, this.delay);
  }
}

const DELETE_DELAY = 2000;

const removeSearchQueue = new debouncedQueue(async (searches) => {
  const userId = searches[0].userId;

  // search is also its id
  const searchIds = searches.map((search) => search.search);

  const userDocRef = doc(getUserCollection(), userId);

  const deleteObj = {};

  searchIds.forEach((searchId) => {
    deleteObj[searchId] = deleteField();
  });

  console.log('actual delete obj', deleteObj);

  try {
    await setDoc(
      userDocRef,
      {
        savedSearches: deleteObj,
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}, DELETE_DELAY);

export async function removeSearch(userId, search) {
  if (!userId) {
    console.error('No userId provided');
    throw new Error('No userId provided');
  }

  if (!search) {
    console.error('No search query provided');
    throw new Error('No search query provided');
  }

  console.log('delete func called');

  removeSearchQueue.add({ userId, search });

  // try {
  //   await setDoc(
  //     userDocRef,
  //     {
  //       savedSearches: {
  //         [search]: deleteField(),
  //       },
  //     },
  //     { merge: true },
  //   );
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
}

export async function scheduleVisit(data) {
  const visitDocRef = doc(getScheduledVisitsCollection());
  const finaldata = {
    ...data,
    status: 'pending',
    timestamp: serverTimestamp(),
  };
  try {
    await setDoc(visitDocRef, finaldata);

    return {
      id: visitDocRef.id,
      ...finaldata,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateScheduledVisit(docId, data) {
  if (!docId) {
    console.error('No docId provided');
    throw new Error('No docId provided');
  }

  const docRef = doc(getScheduledVisitsCollection(), docId);

  try {
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteScheduledVisit(docId) {
  if (!docId) {
    console.error('No docId provided');
    throw new Error('No docId provided');
  }

  const docRef = doc(getScheduledVisitsCollection(), docId);

  try {
    await deleteDoc(docRef);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function removeScheduledVisit(docId) {
  if (!docId) {
    console.error('No docId provided');
    throw new Error('No docId provided');
  }

  const docRef = doc(getScheduledVisitsCollection(), docId);

  try {
    await deleteDoc(docRef);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function sendInquiry(data, type = 'quick') {
  const inquiryCollection = getInqieryCollection();

  try {
    await addDoc(inquiryCollection, {
      ...data,
      timestamp: serverTimestamp(),
      type,
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteInquiry(id) {
  const inquiryCollection = getInqieryCollection();
  try {
    await deleteDoc(doc(inquiryCollection, id));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function postReview(data) {
  const reviewCollection = getReviewCollection();

  try {
    const res = await addDoc(reviewCollection, {
      ...data,
      timestamp: serverTimestamp(),
    });
    return {
      data: {
        ...data,
        timestamp: {
          toDate: () => new Date(),
        },
      },
      id: res.id,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

// ____ ADMIN ____

export async function updatePropertyFeatured(docIds, featured, createdBy) {
  if (!docIds) {
    console.error('No docId provided');
    throw new Error('No docId provided');
  }

  if (!createdBy) {
    console.error('No createdBy provided');
    throw new Error('No createdBy provided');
  }

  // if (Array.isArray(docIds)) {
  //   const batch = writeBatch(db);

  //   for (const docId of docIds) {
  //     const docRef = doc(getPropertyCollection(), docId);

  //     batch.update(docRef, {
  //       featured,
  //       featuredOn: serverTimestamp(),
  //     });
  //   }

  //   try {
  //     await batch.commit();
  //   } catch (e) {
  //     console.error(e);
  //     throw e;
  //   }

  //   return;
  // }

  const docRef = doc(getPropertyCollection(), docIds);

  try {
    await updateDoc(docRef, {
      featured,
      featuredOn: serverTimestamp(),
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updatePropertyVerified(docId, verified) {
  if (!docId) {
    console.error('No docId provided');
    throw new Error('No docId provided');
  }

  const docRef = doc(getPropertyCollection(), docId);

  try {
    await updateDoc(docRef, {
      verified,
      verifiedOn: serverTimestamp(),
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function sendNotification({ to, message }) {
  const userDocRef = doc(getUserCollection(), to);

  try {
    await setDoc(
      userDocRef,
      {
        notifications: arrayUnion({
          id: uuidv4(),
          createdOn: new Date().toString(),
          message,
        }),
      },
      { merge: true },
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function getInqieryCollection() {
  return collection(db, 'inquiries');
}

export function getPropertyCollection() {
  return collection(db, 'properties');
}

export function getScheduledVisitsCollection() {
  return collection(db, 'scheduled_visits');
}

export function getUserCollection() {
  return collection(db, 'users');
}

export function getReviewCollection() {
  return collection(db, 'reviews');
}
