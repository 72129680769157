import React, { useState } from 'react';

import styles from '@/styles/modules/description.module.css';

const Description = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  if (text.length < 350) {
    return (
      <div>
        <br />
        <pre className={styles.text}> {text.trim()}</pre>
      </div>
    );
  }
  return (
    <div>
      <pre className={styles.text}>
        {expanded ? text : `${text.slice(0, 350)}...`}
      </pre>
      <a
        className={styles.showMoreButton}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'Show less' : 'Show more'}
      </a>
    </div>
  );
};

export default Description;
