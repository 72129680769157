import styles from '@/styles/modules/filters/property-type-filter.module.css';

import { FomrDivider } from '@/components/form';
import CheckboxGroup from '@/components/form/checkbox';
import FormGroup, { FormGroupConfig } from '@/components/form/group';
import RadioInput, { RadioGroup, radioSizes } from '@/components/form/radio';
import { additionalFeaturesFilter } from '@/data/filters';
import { getFilterValues, getLabel, getName } from '@/data/utils';

const petsAllowedOptions = getFilterValues(
  additionalFeaturesFilter.petsAllowed,
);
const allowsNonVegOptions = getFilterValues(
  additionalFeaturesFilter.allowsNonVeg,
);

console.log('filter Values');
console.log(getFilterValues(additionalFeaturesFilter.bhkType));

function AdditionalFeatures() {
  return (
    <FormGroupConfig useConfigGap={true} gap={2}>
      <div
        style={{
          width: '100%',
        }}
        className={styles.container}
      >
        <FormGroup label="BHK">
          <RadioInput
            size={radioSizes.small}
            type="button"
            name={getName(additionalFeaturesFilter.bhkType)}
            options={getFilterValues(additionalFeaturesFilter.bhkType)}
          />
        </FormGroup>

        <FormGroup label={getLabel(additionalFeaturesFilter.bathrooms)}>
          <RadioInput
            size={radioSizes.small}
            type="button"
            name={getName(additionalFeaturesFilter.bathrooms)}
            options={getFilterValues(additionalFeaturesFilter.bathrooms)}
          />
        </FormGroup>

        <FomrDivider />

        <FormGroup label="Tenet Type">
          <RadioInput
            size={radioSizes.small}
            type="button"
            name={getName(additionalFeaturesFilter.tenetPreference)}
            options={getFilterValues(additionalFeaturesFilter.tenetPreference)}
          />
        </FormGroup>

        <FomrDivider />

        <FormGroup label={getLabel(additionalFeaturesFilter.propertyAge)}>
          <RadioInput
            size={radioSizes.small}
            type="button"
            name={getName(additionalFeaturesFilter.propertyAge)}
            options={getFilterValues(additionalFeaturesFilter.propertyAge)}
          />
        </FormGroup>

        <FomrDivider />

        <FormGroup label={getLabel(additionalFeaturesFilter.availability)}>
          <RadioInput
            size={radioSizes.small}
            type="button"
            name={getName(additionalFeaturesFilter.availability)}
            options={getFilterValues(additionalFeaturesFilter.availability)}
          />
        </FormGroup>

        <FomrDivider />

        <FormGroup label="Property Features">
          <RadioGroup cols={1} type="square">
            <CheckboxGroup
              spacing={false}
              type="square"
              name={getName(additionalFeaturesFilter.allowsNonVeg)}
              options={allowsNonVegOptions}
            />
            <CheckboxGroup
              spacing={false}
              type="square"
              name={getName(additionalFeaturesFilter.petsAllowed)}
              options={petsAllowedOptions}
            />
          </RadioGroup>
        </FormGroup>

        <FormGroup label={getLabel(additionalFeaturesFilter.furnshing)}>
          <RadioInput
            cols={1}
            type="square"
            name={getName(additionalFeaturesFilter.furnshing)}
            options={getFilterValues(additionalFeaturesFilter.furnshing)}
          />
        </FormGroup>

        <FormGroup label={getLabel(additionalFeaturesFilter.safety)}>
          <RadioInput
            cols={1}
            type="square"
            name={getName(additionalFeaturesFilter.safety)}
            options={getFilterValues(additionalFeaturesFilter.safety)}
          />
        </FormGroup>

        <FormGroup label={getLabel(additionalFeaturesFilter.accessabilty)}>
          <RadioInput
            cols={1}
            type="square"
            name={getName(additionalFeaturesFilter.accessabilty)}
            options={getFilterValues(additionalFeaturesFilter.accessabilty)}
          />
        </FormGroup>

        <FormGroup label={getLabel(additionalFeaturesFilter.amenities)}>
          <RadioGroup cols={3} type="square">
            <CheckboxGroup
              omitGrid={true}
              useIcons={false}
              type="square"
              name={getName(additionalFeaturesFilter.amenities)}
              options={getFilterValues(additionalFeaturesFilter.amenities)}
            />
            <CheckboxGroup
              omitGrid={true}
              useIcons={false}
              type="square"
              name={getName(additionalFeaturesFilter.additionalFeatures)}
              options={getFilterValues(
                additionalFeaturesFilter.additionalFeatures,
              )}
            />
          </RadioGroup>
        </FormGroup>
      </div>
    </FormGroupConfig>
  );
}

export default AdditionalFeatures;
