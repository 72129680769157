import noImage from '@/assets/images/no_image.png';

export function NoImage({ size = 100, message = 'No Image' }) {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        pointerEvents: 'none',
        userSelect: 'none ',
      }}
    >
      <img
        style={{
          opacity: 0.1,
          height: size,
          width: size,
        }}
        height={size}
        width={size}
        src={noImage}
        alt=""
        loading="lazy"
      />
      {/* <p style={{ fontSize: size / 3, fontWeight: 'bold' }}>{message}</p> */}
    </div>
  );
}
