import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from '@/styles/modules/maps.module.css';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import locationIcon from '@/assets/icons/locationIcon.svg';
import markerIcon from '@/assets/icons/marker.svg';
import { useFormContext } from '@/components/form/provider';
import { StandAloneSearchBox } from '@/components/property-forms/location';
import propertyLocationData from '@/data/property-location';
import { getName } from '@/data/utils';
import { PlaceDetails } from '@/services/ola-maps';

const containerStyle = { width: '100%', height: '400px' };

// const libs = ['places'];

const libs = [];

export const MIRA_ROAD_COORDINATES = {
  lat: 19.286686560914287,
  lng: 72.86837847552772,
};

const mapContext = createContext();

export const useMap = () => useContext(mapContext);

export function MapProvider({ children }) {
  const [mapCenter, setMapCenter] = useState(null);
  const [marker, setMarker] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);

  const { getValues } = useFormContext();

  function setMapRef(ref) {
    console.log(ref);
    mapRef.current = ref;

    if (mapRef.current) {
      const values = getValues();

      const mapCenter = values.marker ?? MIRA_ROAD_COORDINATES;

      setMapCenter(mapCenter);

      values.marker && setMarker(values.marker);

      mapRef.current.panTo(mapCenter);
    }
  }

  console.log(marker);

  const values = {
    mapCenter,
    setMapCenter,
    marker,
    setMarker,
    searchBox,
    searchBoxRef,
    setSearchBox,
    mapRef,
    setMapRef,
  };

  return <mapContext.Provider value={values}>{children}</mapContext.Provider>;
}

const Map = () => {
  const {
    mapCenter,
    setMapCenter,
    marker,
    setMarker,
    searchBox,
    setSearchBox,
    mapRef,
    setMapRef,
    searchBoxRef,
  } = useMap();

  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    marker && setValue('marker', marker);
  }, [marker]);

  // useEffect(() => {
  //   console.log(mapRef);
  // }, [mapRef]);

  // const onPlacesChanged = () => {
  //   const places = searchBox.getPlaces();
  //   console.log(places);
  //   if (places.length > 0) {
  //     const place = places[0];
  //     const newCenter = {
  //       lat: place.geometry.location.lat(),
  //       lng: place.geometry.location.lng(),
  //     };
  //     setMapCenter(newCenter);
  //     setMarker(newCenter);
  //     mapRef.current.panTo(newCenter);
  //     mapRef.current.setZoom(15);
  //   }
  // };

  const onPlacesChanged = async (place) => {
    console.log(place);

    const newCenter = {
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng,
    };

    setValue('marker.lat', newCenter.lat);
    setValue('marker.lng', newCenter.lng);

    setMapCenter(newCenter);
    setMarker(newCenter);

    mapRef.current.panTo(newCenter);
    mapRef.current.setZoom(15);

    const placesDetails = new PlaceDetails(
      import.meta.env.VITE_OLA_MAPS_API_KEY,
    );
    const detials = await placesDetails.details(place.place_id);
    console.log('Place Details', detials);

    const address_components = detials.result.address_components;

    const parsed = getAddressObject(address_components);
    console.log('parsed', parsed);

    const locality = [parsed.sublocality, parsed.locality]
      .filter(Boolean)
      .join(', ');
    const streetAddress = parsed.street;
    const building = parsed.home;

    const values = getValues();
    if (!values.societyName)
      setValue(getName(propertyLocationData.societyName), building);
    if (!values.locality)
      setValue(getName(propertyLocationData.locality), locality);
    if (!values.landmark)
      setValue(getName(propertyLocationData.landmark), streetAddress);
  };

  const onMapClick = useCallback((event) => {
    const newMarker = { lat: event.latLng.lat(), lng: event.latLng.lng() };

    setMarker(newMarker);
    // setMapCenter(newMarker);
    // getAddressFromLatLng(newMarker);
  }, []);

  const getAddressFromLatLng = useCallback((latLng) => {
    // if (!window.geocoder) {
    //   window.geocoder = new window.google.maps.Geocoder();
    // }
    // const geocoder = window.geocoder;
    // geocoder.geocode({ location: latLng }, (results, status) => {
    //   if (status === 'OK' && results[0]) {
    //     const addressComponents = getAddressObject(
    //       results[0].address_components,
    //     );
    //     setValue('societyName', addressComponents.home);
    //     setValue('locality', addressComponents.locality);
    //     setValue('landmark', addressComponents.street);
    //     setValue(
    //       'city',
    //       `${addressComponents.city}, ${addressComponents.region}`,
    //     );
    //   } else {
    //     console.error('Geocoder failed due to:', status);
    //   }
    // });
  }, []);

  const onMapLoad = useCallback((map) => {
    // if (searchBoxRef.current) {
    setMapRef(map);
    // setSearchBox(searchBoxRef.current.state.searchBox);
    // }
  }, []);

  const getAddressObject = (addressComponents) => {
    let address = {
      home: '',
      street: '',
      sublocality: '',
      locality: '',
      city: '',
      region: '',
      country: '',
    };

    addressComponents.forEach((component) => {
      if (
        component.types.includes('subpremise') ||
        component.types.includes('premise')
      ) {
        address.home += `${component.long_name} `;
      } else if (
        component.types.includes('route') ||
        component.types.includes('street_address')
      ) {
        address.street = component.long_name;
      } else if (component.types.includes('sublocality')) {
        address.sublocality = component.long_name;
      } else if (
        component.types.includes('postal_town') ||
        component.types.includes('locality')
      ) {
        address.locality = component.long_name;
      } else if (component.types.includes('locality')) {
        address.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        address.region = component.long_name;
      } else if (component.types.includes('country')) {
        address.country = component.long_name;
      }
    });

    return address;
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  return (
    isLoaded && (
      <div className={styles.container}>
        {/* <div className={styles.searchBox}>
          <label htmlFor="search-box">
            <img src={locationIcon} alt="search-icon" />
          </label>
          <GoogleMapsAutoComplete placheholder="Search your society or nearest landmark" />
        </div> */}
        {/* <StandaloneSearchBox
          options={{
            bounds: {
              north: 19.2967,
              south: 19.2867,
              east: 72.8702,
              west: 72.8602,
            },
          }}
          ref={searchBoxRef}
          onPlacesChanged={onPlacesChanged}
        >
          <div className={styles.searchBox}>
            <label htmlFor="search-box">
              <img src={locationIcon} alt="search-icon" />
            </label>

            <input
              id="search-box"
              type="text"
              placeholder="Search your society or nearest landmark"
            />
          </div>
        </StandaloneSearchBox> */}

        <div className={styles.searchBox}>
          <label htmlFor="search-box">
            <img src={locationIcon} alt="search-icon" />
          </label>

          <StandAloneSearchBox
            showFullLocation={true}
            anchorWidth={true}
            onSelect={onPlacesChanged}
            placeholder="Search your society or nearest landmark"
          />

          {/* <GoogleMapsAutoComplete
            onSelect={onPlacesChanged}
            simple={true}
            placeholder="Search your society or nearest landmark"
          /> */}
        </div>
        {console.log(mapCenter)}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter ?? MIRA_ROAD_COORDINATES}
          zoom={13}
          onClick={onMapClick}
          onLoad={onMapLoad}
          options={{
            disableDefaultUI: true,
          }}
        >
          {marker && <Marker icon={markerIcon} position={marker} />}
        </GoogleMap>
      </div>
    )
  );
};

export default Map;
