// const $VALUE_LABELS = Symbol('$LABELS');

const $ICON = Symbol('$ICON');

const $LABLE = Symbol('$LABEL');

const $NAME = Symbol('$NAME');

const $VALIDATION = Symbol('$VALIDATION');

const $VALUE = Symbol('$VALUE');

const $FILTER_LABEL = Symbol('$FILTER_LABEL');
const $OVERVIEW_LABEL = Symbol('$OVERVIEW_LABEL');

const $QUERY = Symbol('$QUERY');

const $VALUE_LABEL = Symbol('$VALUE_LABEL');
export const Symbols = {
  // ValueLabels: $VALUE_LABELS,
  Icon: $ICON,
  Label: $LABLE,
  Name: $NAME,
  Validation: $VALIDATION,
  Value: $VALUE,
  FilterLabel: $FILTER_LABEL,
  Query: $QUERY,
  ValueLabel: $VALUE_LABEL,
  OverviewLabel: $OVERVIEW_LABEL,
};
