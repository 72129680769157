import { Symbols } from '@/data/symbols';

const propertyLocationData = {
  terms: {
    [Symbols.Name]: 'terms',
  },

  types: {
    [Symbols.Name]: 'types',
  },

  societyName: {
    [Symbols.Label]: 'Building / Society Name',
    [Symbols.Name]: 'societyName',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  marker: {
    [Symbols.Name]: 'marker',
    [Symbols.Label]: 'Mark the location on the map',
    [Symbols.Validation]: {
      required: 'Please add a marker to the location',
    },
  },

  // sublocality: {
  //   [Symbols.Name]: 'sublocality',
  // },

  // locality: {
  //   [Symbols.Name]: 'locality',
  // },

  locality: {
    [Symbols.Label]: 'Locality / Area',
    [Symbols.Name]: 'locality',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },

  landmark: {
    [Symbols.Label]: 'Landmark / Street Name',
    [Symbols.Name]: 'landmark',
    // [Symbols.Validation]: {
    //   required: 'This field is required',
    // },
  },
  city: {
    [Symbols.Label]: 'City',
    [Symbols.Name]: 'city',
    [Symbols.Validation]: {
      required: 'This field is required',
    },
  },
};

export const PROPERTY_LOCATION_NAME = 'property_location';

export default propertyLocationData;
