import React, { Suspense, useEffect, useState } from 'react';

import styles from '@/styles/modules/user-reviews.module.css';

import { Await, useLoaderData } from 'react-router-dom';

import { Modal } from '@/components/Modal';
import Review from '@/components/preview/review';
import PropertyReviewForm from '@/components/review-form';
import { runBackgroundTask } from '@/pages/property-preview';

const RatingsAndReviews = ({ propertyDetails }) => {
  const { reviews } = useLoaderData();

  const [reviewsState, setReviewsState] = useState([]);

  useEffect(() => {
    runBackgroundTask(async () => {
      const revies = await reviews;
      setReviewsState(revies.data);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.reviewsContainer}>
        <Suspense fallback={<div>Loading...</div>}>
          <Await resolve={reviews}>
            {({ data }) =>
              data && data.length > 0 ?
                reviewsState.map(
                  (review) =>
                    console.log(review) || (
                      <Review key={review.id} {...review.data} />
                    ),
                )
              : <h1>No Reviews Yet</h1>
            }
          </Await>
        </Suspense>
      </div>
      <Modal
        title="Rate and Review this Property"
        centerTitle={true}
        trigger={
          <button className={styles.writeReviewButton}>Write a Review</button>
        }
      >
        <PropertyReviewForm
          onSubmit={(data) => {
            setReviewsState([data, reviewsState[0]].filter(Boolean));
          }}
          propertyDetails={propertyDetails}
        />
      </Modal>
    </div>
  );
};

export default RatingsAndReviews;
